export class ProcedureComplicationDto {
    constructor(obj) {
        this.id = obj.id;
        this.myoCardialInfarctionId = obj.myoCardialInfarctionId;
        this.inHospitalCardiogenicShockId = obj.inHospitalCardiogenicShockId;
        this.cardiogenicShockStageId = obj.cardiogenicShockStageId;
        this.cardiacInterventionsId = obj.cardiacInterventionsId;
        this.varc3MajorBleedingId = obj.varc3MajorBleedingId;
        this.newRequirementForRenalReplacementTherapyId =
            obj.newRequirementForRenalReplacementTherapyId;
        this.deathReasonId = obj.deathReasonId;
        this.cardiacInterventions = obj.cardiacInterventions;
        this.heartValves = obj.heartValves;
        this.inHospitalDeathId = obj.inHospitalDeathId;
        this.implantableCardiacDevices = obj.implantableCardiacDevices;
        this.reinterventionIndications = obj.reinterventionIndications;
        this.strokes = obj.strokes;
    }
}
