<template>
    <Dialog
        v-model:visible="showDialog"
        modal
        :header="t('changePassword')"
        :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
        :style="{ width: '50vw' }"
        @hide="emit('update:modelValue', showDialog)"
    >
        <strong>{{ selectedUser.firstName }} {{ selectedUser.lastName }}</strong>
        <WInputText
            v-model="newPassword"
            :label="t('newPassword')"
            :is-required="true"
            :min-length="6"
        ></WInputText>
        <template #footer>
            <WButton :label="t('buttons.save')" @click="changeUserPassword"></WButton>
        </template>
    </Dialog>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';
import { useToast } from 'primevue/usetoast';
import { ref, watch } from 'vue';
import WInputText from '@/components/wrappers/form/WInputText.vue';
import WButton from '@/components/wrappers/button/WButton.vue';
import Dialog from 'primevue/dialog';
import { authService } from '@/services/auth/auth.service';

const { t } = useI18n();
const v$ = useVuelidate();
const toast = useToast();

const emit = defineEmits(['update:modelValue']);

const showDialog = ref(props.modelValue);
const loading = ref(false);
const newPassword = ref('');

const props = defineProps({
    modelValue: Boolean,
    selectedUser: Object,
});

const changeUserPassword = async () => {
    await v$.value.$validate();
    if (v$.value.$errors.length > 0) {
        return;
    }
    loading.value = true;
    try {
        await authService.adminChangeUserPassword({
            email: props.selectedUser.email,
            newPassword: newPassword.value,
        });
        newPassword.value = '';
        toast.add({
            severity: 'success',
            summary: t('notifications.success'),
            detail: t('notifications.successfulSave'),
            life: 3000,
        });
        showDialog.value = false;
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: t('notifications.error'),
            detail: t('notifications.errorEdit'),
            life: 3000,
        });
    } finally {
        loading.value = false;
    }
};

watch(
    () => props.modelValue,
    () => {
        showDialog.value = props.modelValue;
    }
);
</script>
