<template>
    <div class="grid">
        <WInputText
            v-model="dto.firstName"
            class="col-12 md:col-4"
            :max-length="250"
            :isRequired="true"
            :label="t('patient.name')"
        >
        </WInputText>
        <WInputText
            v-model="dto.middleName"
            class="col-12 md:col-4"
            :is-required="false"
            :max-length="250"
            :label="t('patient.middleName')"
        >
        </WInputText>
        <WInputText
            v-model="dto.lastName"
            :isRequired="true"
            class="col-12 md:col-4"
            :max-length="250"
            :label="t('patient.lastName')"
        >
        </WInputText>
        <PersonIdentity
            v-model="dto"
            class="col-12 md:col-4"
            :isInvalidPersonalCivilNumber="isInvalidPersonalCivilNumber"
            @setValidityCivilNumber="onSetCivilNumber"
        >
        </PersonIdentity>
        <div class="col-12 md:col-4 my-2">
            <WDateTimePicker
                v-model="dto.birthDate"
                :label="t('patient.dateOfBirth')"
            ></WDateTimePicker>
        </div>
        <WDropdown
            v-model="dto.genderId"
            class="my-2 col-12 md:col-4"
            :items="genders"
            :label="t('patient.gender')"
            :editable="false"
        >
        </WDropdown>
        <WInputText
            v-model="dto.email"
            class="col-12 md:col-4"
            :max-length="250"
            :label="t('patient.email')"
            inputmode="email"
        >
        </WInputText>
        <WInputText
            v-model="dto.phoneNumber"
            class="col-12 md:col-4"
            type="tel"
            inputmode="tel"
            :max-length="20"
            :label="t('patient.phoneContact')"
        >
        </WInputText>
    </div>
    <WButton :label="t('buttons.create')" icon="pi pi-save" @click="create"></WButton>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import WInputText from '@/components/wrappers/form/WInputText.vue';
import WButton from '@/components/wrappers/button/WButton.vue';
import WDropdown from '@/components/wrappers/form/WDropdown.vue';
import WDateTimePicker from '@/components/wrappers/dateTime/WDateTimePicker.vue';
import PersonIdentity from '@/components/personIdentity/PersonIdentity.vue';
import { PatientCreateDto } from '@/dto/patient/patientCreateDto';
import { patientService } from '@/services/patient/patient.service';
import { useVuelidate } from '@vuelidate/core';
import { useToast } from 'primevue/usetoast';
import { useRouter } from 'vue-router';
import { dropdownService } from '@/services/dropdown/dropdown.service';
import { useI18n } from 'vue-i18n';
import { UniquePersonIdentifierTypes } from '@/enums/uniquePersonIdentifierTypes';

const dto = ref(new PatientCreateDto());
dto.value.personIdentityTypeId = UniquePersonIdentifierTypes.CivilNumber;

const router = useRouter();
const v$ = useVuelidate();
const { t } = useI18n();
const toast = useToast();
const loading = ref(false);
const isInvalidPersonalCivilNumber = ref(false);

const create = async () => {
    await v$.value.$validate();
    if (v$.value.$errors.length > 0) {
        return;
    }

    loading.value = true;
    try {
        await patientService.create(dto.value);
        toast.add({
            severity: 'success',
            summary: t('notifications.success'),
            detail: t('notifications.successfulSave'),
            life: 2000,
        });
        router.push({ path: '/patient/list' });
    } catch (error) {
        if(error.response.data === "Existing personalCivilNumber"){
            isInvalidPersonalCivilNumber.value = true;
        }
        
        toast.add({
            severity: 'error',
            summary: t('notifications.error'),
            detail: isInvalidPersonalCivilNumber.value ? t('validations.uniquePersonIdentifierExists') : t('notifications.errorCreate'),
            life: 2000,
        });
    }
    loading.value = false;
};

const genders = ref([]);
const loadGenders = async () => {
    genders.value = (await dropdownService.getGender()).data;
};

const onSetCivilNumber = (val) => {
    isInvalidPersonalCivilNumber.value = val;
};

onMounted(async () => {
    loadGenders();
});
</script>
