<template>
    <div class="bg-primary">
        <Toolbar class="bg-primary">
            <template #start>
                <span v-if="userStore().isAuthenticated">
                    <WButton
                        v-if="menuStore().isMobileResolution"
                        icon="pi pi-align-justify"
                        class="mr-2"
                        @click="menuStore().expandMobileMenu = !menuStore().expandMobileMenu"
                    />
                </span>
            </template>
            <template #end>
                <WButton
                    icon="pi pi-user"
                    class="mr-2"
                    :class="'bg-primary'"
                    @click="toggleUserOP"
                />
                <OverlayPanel ref="userOP">
                    <!--                    <img
                        src="https://primefaces.org/cdn/primevue/images/product/bamboo-watch.jpg"
                        alt="Bamboo Watch"
                    />-->
                    <div class="cursor-pointer" @click="changeLocale">
                        <i class="pi pi-flag"></i>
                        {{ localeStore().isBg ? 'English' : 'Български' }}
                    </div>
                    <!-- <div class="cursor-pointer mt-2">
                        <i class="pi pi-moon text-primary"></i> Тъмна тема
                        &lt;!&ndash; pi-sun &ndash;&gt;
                    </div>-->
                    <div @click="showChangePassModal" class="cursor-pointer mt-2">
                        <i class="pi pi-key text-primary"></i> {{ t('changePassword') }}
                    </div>
                    <hr />
                    <WButton
                        css-class="w-full"
                        text
                        :label="t('buttons.logout')"
                        @click="logout"
                    ></WButton>
                </OverlayPanel>
            </template>
        </Toolbar>
        <Dialog
            v-model:visible="showModal"
            modal
            :header="t('changePassword')"
            :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ width: '25vw' }"
            :draggable="false"
        >
            <WPassword
                v-model="model.oldPassword"
                :is-required="true"
                :label="t('password')"
                type="password"
            ></WPassword>
            <WPassword
                v-model="model.newPassword"
                :is-required="true"
                :min-length="6"
                :feedback="true"
                :label="t('newPassword')"
                type="password"
            ></WPassword>
            <WPassword
                v-model="model.confirmPassword"
                :is-required="true"
                :is-same-as="model.newPassword"
                :label="t('confirmNewPassword')"
                type="password"
            ></WPassword>
            <WButton
                @click="changePassword"
                :label="t('buttons.save')"
                icon="pi pi-save"
                class="w-full mt-4"
                :disabled="disableBtn"
            ></WButton>
        </Dialog>
        <WLoader :is-loading="loading"></WLoader>
    </div>
</template>

<script setup>
import Dialog from 'primevue/dialog';
import WButton from '@/components/wrappers/button/WButton.vue';
import Toolbar from 'primevue/toolbar';
import OverlayPanel from 'primevue/overlaypanel';
import { userStore } from '@/store/user.store';
import { menuStore } from '@/store/menu.store';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { localeStore } from '@/store/locale.store';
import { Locales } from '@/enums/locales';
import i18n from '@/i18n';
import { usePrimeVue } from 'primevue/config';
import WPassword from '@/components/wrappers/form/WPassword.vue';
import WLoader from '@/components/wrappers/loaderSpinner/WLoader.vue';
import { ChangePasswordDto } from '@/dto/auth/changePasswordDto';
import { bg, en } from '@/locales/PrimeVue/locales';
import { authService } from '@/services/auth/auth.service';
import { useToast } from 'primevue/usetoast';
import { useVuelidate } from '@vuelidate/core';

const { t } = useI18n();
const router = useRouter();
const userOP = ref(null);
const primeVue = usePrimeVue();
const showModal = ref(false);
const v$ = useVuelidate();
const toast = useToast();
const loading = ref(false);
const disableBtn = ref(false);
const toggleUserOP = (event) => {
    userOP.value.toggle(event);
};
const model = ref(new ChangePasswordDto());

const showChangePassModal = () => {
    showModal.value = true;
};

const changeLocale = () => {
    const locale = localeStore().isBg ? Locales.en : Locales.bg;
    i18n.global.locale.value = locale;

    if (locale === Locales.bg) {
        primeVue.config.locale = bg;
    } else {
        primeVue.config.locale = en;
    }

    localeStore().setLocale(locale);
    userOP.value.hide();
};

const logout = () => {
    userStore().logout();
    router.push('/login');
};

const changePassword = async () => {
    loading.value = true;
    disableBtn.value = true;
    await v$.value.$validate();
    if (v$.value.$errors.length > 0) {
        loading.value = false;
        disableBtn.value = false;
        return;
    }
    try {
        await authService.changePassword(model.value);
        toast.add({
            severity: 'success',
            summary: t('notifications.success'),
            detail: t('notifications.successfulEdit'),
            life: 3000,
        });
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: t('notifications.error'),
            detail: t('notifications.somethingGoneWrong'),
            life: 3000,
        });
    } finally {
        loading.value = false;
        disableBtn.value = false;
        model.value = new ChangePasswordDto();
        showModal.value = false;
    }
};
</script>
