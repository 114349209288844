export class EditRegisteredUserDto {
    constructor(id, isConfirmed, roleName, reason, roles, email) {
        this.id = id;
        this.isConfirmed = isConfirmed;
        this.roleName = roleName;
        this.reason = reason;
        this.roles = roles;
        this.email = email;
    }
}
