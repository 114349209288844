<template>
    <div>
        <h1>{{ $t('registeredUsers.waitingUsersToApprove') }}</h1>
        <w-data-table
            :columns="columns"
            :action-buttons="actionButtons"
            ref="dataTable"
            api-url="/RegisteredUsers/GetFiltered"
        ></w-data-table>
        <Dialog v-model:visible="showConfirmDialog" modal header="Одобряване на потребител">
            <div class="mb-3">
                <div>
                    <strong>{{ chosenUser.firstName }} {{ chosenUser.lastName }}</strong>
                </div>
                <div>
                    <strong>{{ chosenUser.email }}</strong>
                </div>
                <div>
                    <strong>Роля: {{ chosenUser.roleName }}</strong>
                </div>
            </div>
            <w-toggle-button
                v-model="editDto.isConfirmed"
                on-label="Одобрен"
                off-label="Отказан"
                on-color="bg-green-600 text-white"
            ></w-toggle-button>
            <w-text-area
                v-model="editDto.reason"
                v-if="!editDto.isConfirmed"
                label="Причина"
            ></w-text-area>
            <template #footer>
                <w-button label="Запис" @click="editRegisteredUser"></w-button>
            </template>
        </Dialog>
        <WLoader :is-loading="loading"></WLoader>
    </div>
</template>

<script setup>
import WDataTable from '@/components/wrappers/data/data-table/WDataTable.vue';
import WButton from '@/components/wrappers/button/WButton.vue';
import WTextArea from '@/components/wrappers/form/WTextArea.vue';
import WToggleButton from '@/components/wrappers/button/WToggleButton.vue';
import WLoader from '@/components/wrappers/loaderSpinner/WLoader.vue';
import Dialog from 'primevue/dialog';
import { ActionButtonModel } from '@/components/wrappers/data/data-table/models/actionButtonModel';
import { DataTableColumnType } from '@/components/wrappers/data/data-table/models/dataTableColumnType';
import { ref, computed } from 'vue';
import { registeredUsersService } from '@/services/admin/registeredUsers.service';
import { useToast } from 'primevue/usetoast';
import { EditRegisteredUserDto } from '@/dto/admin/registeredUsers/editRegisteredUserDto';
import { useI18n } from 'vue-i18n';

const columns = computed(() => [
    {
        field: 'firstName',
        header: t('person.firstName'),
        dataType: DataTableColumnType.text,
        sortable: true,
        filterable: true,
    },
    {
        field: 'lastName',
        header: t('person.lastName'),
        dataType: DataTableColumnType.text,
        sortable: true,
        filterable: true,
    },
    { field: 'email', header: t('email'), dataType: DataTableColumnType.text, filterable: true },
    {
        field: 'roleName',
        header: t('roles.role'),
        sortable: true,
        dataType: DataTableColumnType.text,
        filterable: true,
    },
    {
        field: 'createdDate',
        header: t('registeredUsers.date'),
        dataType: DataTableColumnType.date,
        sortable: true,
        filterable: true,
    },
    {
        field: 'isRejected',
        header: t('registeredUsers.rejected'),
        dataType: DataTableColumnType.boolean,
    }
]);

const { t } = useI18n();

const loading = ref(false);

const toast = useToast();

const showConfirmDialog = ref(false);

const chosenUser = ref(null);
const editDto = ref(new EditRegisteredUserDto());

const edit = (item) => {
    chosenUser.value = item;
    editDto.value.id = item.regsteredUserId;
    editDto.value.roleName = item.roleName;
    editDto.value.isConfirmed = true;
    showConfirmDialog.value = true;
};

const actionButtons = computed(() => [
    new ActionButtonModel('pi pi-pencil', t('buttons.edit'), edit),
]);

const dataTable = ref(null);

const editRegisteredUser = async () => {
    showConfirmDialog.value = false;
    loading.value = true;
    try {
        await registeredUsersService.edit(editDto.value);
        const successMessage = editDto.value.isConfirmed
            ? t('registeredUsers.successfulConfirmation')
            : t('registeredUsers.successfulRejection');
        toast.add({
            severity: 'success',
            summary: t('notifications.success'),
            detail: successMessage,
            life: 2000,
        });
    } catch (err) {
        toast.add({
            severity: 'error',
            summary: t('notifications.error'),
            detail: t('notifications.somethingGoneWrong'),
            life: 2000,
        });
    } finally {
        loading.value = false;
        dataTable.value.refresh();
    }
};
</script>
