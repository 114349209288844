<template>
    <div>
        <WDataTable
            :columns="columns"
            :action-buttons="actionButtons"
            ref="dataTable"
            api-url="/TaviDraft/getFiltered"
        ></WDataTable>
        <WLoader :is-loading="loading"></WLoader>
    </div>
</template>

<script setup>
import WDataTable from '@/components/wrappers/data/data-table/WDataTable.vue';
import { ActionButtonModel } from '@/components/wrappers/data/data-table/models/actionButtonModel';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';
import WLoader from '@/components/wrappers/loaderSpinner/WLoader.vue';
import { DataTableColumnType } from '@/components/wrappers/data/data-table/models/dataTableColumnType';
import { router } from '@/router/router';
import { taviDraftService } from "@/services/tavi/taviDraft.service";

const { t } = useI18n();
const toast = useToast();
const dataTable = ref(null);
const loading = ref(false);

const columns = computed(() => [
    {
        field: 'date',
        header: t('taviProcedure.date'),
        sortable: true,
        dataType: DataTableColumnType.date,
        filterable: true,
    },
    {
        field: 'patientFirstName',
        header: t('patient.name'),
        sortable: true,
        dataType: DataTableColumnType.text,
        filterable: true,
    },
    {
        field: 'patientLastName',
        header: t('patient.lastName'),
        sortable: true,
        dataType: DataTableColumnType.text,
        filterable: true,
    },
    {
        field: 'patientPersonIdentityNumber',
        header: t('patientSearch.personIdentityNumber'),
        sortable: true,
        dataType: DataTableColumnType.text,
        filterable: true,
    },
]);

const deleteProcedureDraft = async (item) => {
    loading.value = true;
    try {
        await taviDraftService.delete(item.id);
        toast.add({
            severity: 'success',
            summary: t('notifications.success'),
            detail: t('taviProcedure.successfulDeletedProcedure'),
            life: 2000,
        });
    } catch (err) {
        toast.add({
            severity: 'error',
            summary: t('notifications.error'),
            detail: t('notifications.somethingGoneWrong'),
            life: 2000,
        });
    } finally {
        loading.value = false;
        dataTable.value.refresh();
    }
};

const editProcedureDraft = async (item) => {
    try {
        router.push({ path: `/procedure/edit/${item.id}/${true}` });
    } catch (err) {
        toast.add({
            severity: 'error',
            summary: t('notifications.error'),
            detail: t('notifications.somethingGoneWrong'),
            life: 2000,
        });
    }
};

const actionButtons = computed(() => [
    new ActionButtonModel('pi pi-pencil', t('buttons.edit'), editProcedureDraft),
    new ActionButtonModel(
        'pi pi-trash',
        t('buttons.delete'),
        deleteProcedureDraft,
        'danger'
    ),
]);
</script>
