<template>
    <div>
        <AdminDashboard v-if="isAdmin"></AdminDashboard>
        <HospitalAdminDashboard v-if="isHospitalAdmin"></HospitalAdminDashboard>
        <DoctorDashboard v-if="isDoctor"></DoctorDashboard>
    </div>
</template>

<script setup>
import AdminDashboard from '@/components/dashboard/AdminDashboard.vue';
import { computed } from 'vue';
import { userStore } from '@/store/user.store';
import { Roles } from '@/enums/roles';
import HospitalAdminDashboard from '@/components/dashboard/HospitalAdminDashboard.vue';
import DoctorDashboard from '@/components/dashboard/DoctorDashboard.vue';

const isAdmin = computed(() => {
    return userStore().isUserInRole(Roles.Admin);
});

const isHospitalAdmin = computed(() => {
    return userStore().isUserInRole(Roles.HospitalAdmin);
});

const isDoctor = computed(() => {
    return userStore().isUserInRole(Roles.Doctor);
});
</script>
