<template>
    <div class="registerForm surface-card p-4 shadow-2 border-round w-full lg:w-6 mt-8">
        <div>
            <WInputText
                v-model="registerDto.email"
                :is-required="true"
                type="email"
                :label="$t('email')"
            ></WInputText>

            <WInputText
                v-model="registerDto.userName"
                :is-required="true"
                :label="$t('userName')"
            ></WInputText>

            <WInputText
                v-model="registerDto.firstName"
                :is-required="true"
                :label="$t('person.firstName')"
            ></WInputText>

            <WInputText
                v-model="registerDto.lastName"
                :is-required="true"
                :label="$t('person.lastName')"
            ></WInputText>

            <WDropdown
                v-model="registerDto.roleName"
                :items="roles"
                :is-required="true"
                :label="$t('roles.role')"
                option-value="roleName"
            ></WDropdown>

            <WDropdown
                v-model="registerDto.hospitalId"
                :is-required="true"
                :items="hospitals"
                :label="$t('hospital.hospital')"
            ></WDropdown>

            <WPassword
                v-model="registerDto.password"
                :is-required="true"
                :min-length="6"
                :feedback="true"
                :label="$t('password')"
                type="password"
            ></WPassword>

            <WPassword
                v-model="registerDto.confirmPassword"
                :is-required="true"
                :is-same-as="registerDto.password"
                :label="$t('confirmPassword')"
                type="password"
                @keydown.enter="register"
            ></WPassword>

            <WButton
                @click="register"
                :label="$t('register')"
                icon="pi pi-user"
                class="w-full"
            ></WButton>
        </div>
        <WLoader :is-loading="loading"></WLoader>
    </div>
</template>

<script setup>
import WInputText from '@/components/wrappers/form/WInputText.vue';
import WPassword from '@/components/wrappers/form/WPassword.vue';
import WButton from '@/components/wrappers/button/WButton.vue';
import { computed, onMounted, reactive, ref } from 'vue';
import { RegisterUserDto } from '@/dto/auth/registerUserDto';
import { authService } from '@/services/auth/auth.service';
import { userStore } from '@/store/user.store';
import { useVuelidate } from '@vuelidate/core';
import { useToast } from 'primevue/usetoast';
import WDropdown from '@/components/wrappers/form/WDropdown.vue';
import { useI18n } from 'vue-i18n';
import WLoader from '@/components/wrappers/loaderSpinner/WLoader.vue';
import { useRouter } from 'vue-router';
import { hospitalService } from '@/services/hospital/hospital.service';

const registerDto = reactive(new RegisterUserDto());

const { t } = useI18n();
const router = useRouter();

const roles = computed(() => [
    {
        roleName: 'HospitalAdmin',
        nameBg: t('roles.hospitalAdmin'),
        nameEn: t('roles.hospitalAdmin'),
    },
    {
        roleName: 'Doctor',
        nameBg: t('roles.doctor'),
        nameEn: t('roles.doctor'),
    },
]);

const hospitals = ref([]);

const v$ = useVuelidate();
const toast = useToast();

const loading = ref(false);

const register = async () => {
    await v$.value.$validate();
    if (v$.value.$errors.length > 0) {
        return;
    }
    loading.value = true;
    try {
        const tokenData = await authService.register(registerDto);
        userStore().authenticateUser(tokenData.data);
        router.push({ path: '/auth/waiting' });
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: t('notifications.error'),
            detail: t('notifications.registrationError'),
            life: 3000,
        });
    } finally {
        loading.value = false;
    }
};

const loadHospitals = async () => {
    hospitals.value = (await hospitalService.getAll()).data;
};

onMounted(() => {
    loadHospitals();
});
</script>

<style scoped>
.registerForm {
    max-width: 600px;
}
</style>
