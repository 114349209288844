<template>
    <app-toolbar v-if="isApprovedUser"></app-toolbar>
    <div class="container">
        <div v-if="isAuthenticated && isApprovedUser && !isMobileResolution" class="sidebar">
            <side-menu class="sidebar"></side-menu>
        </div>
        <!-- TODO: трябва ли .sidebar при мобилна резолюция? -->
        <side-menu
            class="sidebar"
            v-if="isAuthenticated && isApprovedUser && isMobileResolution"
        ></side-menu>
        <div
            class="main col"
            :class="{
                'col ml-2': isMobileResolution,
                'col-12 flex align-items-center justify-content-center':
                    !isAuthenticated || !isApprovedUser,
            }"
        >
            <router-view></router-view>
        </div>
    </div>
    <toast />
</template>

<script setup>
import AppToolbar from '@/components/toolbar/AppToolbar.vue';
import SideMenu from '@/components/menu/SideMenu.vue';
import Toast from 'primevue/toast';
import { computed, onMounted } from 'vue';
import { userStore } from '@/store/user.store';
import { menuStore } from '@/store/menu.store';
import { Locales } from '@/enums/locales';
import { usePrimeVue } from 'primevue/config';
import { localeStore } from "@/store/locale.store";
import { bg, en } from '@/locales/PrimeVue/locales';

const isAuthenticated = computed(() => userStore().isAuthenticated);
const isApprovedUser = computed(() => userStore().approvedUser);
const isMobileResolution = computed(() => menuStore().isMobileResolution);

const primeVue = usePrimeVue();

const setPrimeVueLocale = () => {
    if (localeStore().currentLocale === Locales.bg) {
        primeVue.config.locale = bg;
    } else {
        primeVue.config.locale = en;
    }
};

onMounted(() => {
    menuStore().setResolution(window.innerWidth);
    window.addEventListener('resize', () => {
        menuStore().setResolution(window.innerWidth);
    });

    setPrimeVueLocale();
});
</script>

<style>
:root {
    --primary-color: #140f4b !important;
}

.p-checkbox .p-checkbox-box.p-highlight {
    background-color: var(--primary-color) !important;
    border-color: var(--primary-900) !important;
}

/*TODO: Май е много грозен SelectButton с primary color*/
.p-selectbutton .p-button.p-highlight,
.p-selectbutton .p-button.p-highlight:hover,
.p-selectbutton .p-button.p-highlight:focus {
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
    color: #fff !important;
}

body {
    padding: 0;
    margin: 0;
}

.container {
    display: flex;
    /* 62px is the AppToolbar component height */
    height: calc(100vh - 62px); /*100vh*/ /* Set container height to viewport height */
}

.sidebar {
    width: 250px;
    /* background-color: #f2f2f2;*/
    border-right: 1px solid #ccc;
}

.main {
    flex: 1;
    overflow: auto;
}

.required::after {
    content: ' *';
    color: #f00;
    font-weight: bold;
}
</style>
