import { TaviProcedureCreateDraftDto } from './taviProcedureCreateDraftDto';

export class TaviProcedureEditDraftDto extends TaviProcedureCreateDraftDto {
    constructor(
        id,
        date,
        patientId,
        objectContent,
        createdBy,
        historyOfDeseaseNumber,
        doctorId
    ) {
        super(date, patientId, objectContent, createdBy, historyOfDeseaseNumber, doctorId);
        this.id = id;
    }
}
