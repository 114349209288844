<template>
    <div>
        <w-data-table
            :columns="columns"
            :action-buttons="actionButtons"
            ref="dataTable"
            api-url="/tavi/getFiltered"
        ></w-data-table>
        <w-loader :is-loading="loading"></w-loader>
    </div>
</template>

<script setup>
import WDataTable from '@/components/wrappers/data/data-table/WDataTable.vue';
import { ActionButtonModel } from '@/components/wrappers/data/data-table/models/actionButtonModel';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';
import WLoader from '@/components/wrappers/loaderSpinner/WLoader.vue';
import { DataTableColumnType } from '@/components/wrappers/data/data-table/models/dataTableColumnType';
import { taviService } from '@/services/tavi/tavi.service';
import { router } from "@/router/router";

const { t } = useI18n();
const toast = useToast();
const dataTable = ref(null);
const loading = ref(false);

const columns = computed(() => [
    {
        field: 'date',
        header: t('taviProcedure.date'),
        sortable: true,
        dataType: DataTableColumnType.date,
        filterable: true,
    },
    {
        field: 'patientFirstName',
        header: t('patient.name'),
        sortable: true,
        dataType: DataTableColumnType.text,
        filterable: true,
    },
    {
        field: 'patientLastName',
        header: t('patient.lastName'),
        sortable: true,
        dataType: DataTableColumnType.text,
        filterable: true,
    },
    {
        field: 'patientPersonIdentityNumber',
        header: t('patientSearch.personIdentityNumber'),
        sortable: true,
        dataType: DataTableColumnType.text,
        filterable: true,
    },
]);

const editProcedureDraft = async (item) => {
    loading.value = true;
    try {
        //await taviService.getById(item.id);
        router.push({ path: `/procedure/edit/${item.id}/${false}` });
    } catch (err) {
        toast.add({
            severity: 'error',
            summary: t('notifications.error'),
            detail: t('notifications.somethingGoneWrong'), // TODO:
            life: 2000,
        });
    } finally {
        loading.value = false;
        dataTable.value.refresh();
    }
};

const deleteProcedure = async (item) => {
    loading.value = true;
    try {
        await taviService.delete(item.id);
        toast.add({
            severity: 'success',
            summary: t('notifications.success'),
            detail: t('taviProcedure.successfulDeletedProcedure'),
            life: 2000,
        });
    } catch (err) {
        toast.add({
            severity: 'error',
            summary: t('notifications.error'),
            detail: t('notifications.somethingGoneWrong'), // TODO:
            life: 2000,
        });
    } finally {
        loading.value = false;
        dataTable.value.refresh();
    }
};

const actionButtons = computed (() =>[
    new ActionButtonModel('pi pi-pencil', t('buttons.edit'), editProcedureDraft),
    new ActionButtonModel('pi pi-trash', t('buttons.delete'), deleteProcedure, 'danger'),
]);
</script>
