export class ActionButtonModel {
    constructor(icon, tooltip, callback, color ='primary', isVisible = true, isDisabled = false) {
        this.icon = icon;
        this.tooltip = tooltip;
        this.callback = callback;
        this.color = color;
        this.isVisible = isVisible;
        this.isDisabled = isDisabled;
    }
}
