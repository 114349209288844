<template>
    <Card v-if="model.ekg" style="width: 100%; margin-bottom: 2em">
        <template #title>{{ t('dischargeData.ecg') }}</template>
        <template #content>
            <Ekg
                :disabled="disabled"
                v-model="model.ekg"
                :selectButtonItems="selectButtonItems"
                :atrialRhythm="atrialRhythm"
                :ventricularConduction="ventricularConduction"
                :atrioventricularBlock="atrioventricularBlock"
            />
        </template>
    </Card>
    <Card style="width: 100%; margin-bottom: 2em">
        <template #title>{{ t('followUp.echocardiography') }}</template>
        <template #content>
            <Echocardiography
                :disabled="disabled"
                v-model="model.proceduralDiagnosticEchocardiography"
                :diseaseSeverities="diseaseSeverities"
                :leftVentricularEjectionFraction="leftVentricularEjectionFraction"
            />
        </template>
    </Card>
    <Card style="width: 100%; margin-bottom: 2em">
        <template #title>{{ t('proceduralDiagnostics.laboratory') }}</template>
        <template #content>
            <div class="grid">
                <div class="col-12 md:col-4 field">
                    <WInputNumberCheckbox
                        :disabled="disabled"
                        v-model="model.proceduralDiagnosticLaboratory.haemoglobin"
                        :label="t('proceduralDiagnostics.haemoglobin')"
                    />
                </div>
                <div class="col-12 md:col-4 field">
                    <WInputNumberCheckbox
                        :disabled="disabled"
                        v-model="model.proceduralDiagnosticLaboratory.bnp"
                        :label="t('proceduralDiagnostics.bNP')"
                    />
                </div>
                <div class="col-12 md:col-4 field">
                    <WInputNumberCheckbox
                        :disabled="disabled"
                        v-model="model.proceduralDiagnosticLaboratory.ntProBnp"
                        :label="t('proceduralDiagnostics.nTProBNP')"
                    />
                </div>
            </div>
            <div class="grid">
                <div class="col-12 md:col-4 field">
                    <WInputNumberCheckbox
                        :disabled="disabled"
                        v-model="model.proceduralDiagnosticLaboratory.creatinine"
                        :label="t('proceduralDiagnostics.creatinine')"
                    />
                </div>
            </div>
        </template>
    </Card>
    <Card style="width: 100%; margin-bottom: 2em">
        <template #title>
            {{ t('proceduralDiagnostics.coronaryAngiographyOrCTCoronaryAngiography') }}</template
        >
        <template #content>
            <div class="grid">
                <div class="col-12 md:col-6 field" v-if="!hasCabg">
                    <WMultiSelect
                        :items="coronaryArteryStenoses"
                        v-model="model.proceduralDiagnosticCoronaryAng.coronaryArteryStenoses"
                        :disabled="disabled"
                        optionLabel="nameBg"
                        :max-selected-labels="8"
                        :label="t('proceduralDiagnostics.coronaryArteryStenosis')"
                    />
                </div>
            </div>
            <div class="grid">
                <div class="col-12 md:col-4 field">
                    <WInputNumberCheckbox
                        :disabled="disabled"
                        v-model="model.proceduralDiagnosticCoronaryAng.lvotPerimeter"
                        :label="t('proceduralDiagnostics.lVOTDiameter')"
                    />
                </div>
                <div class="col-12 md:col-4 field">
                    <WInputNumberCheckbox
                        :disabled="disabled"
                        v-model="model.proceduralDiagnosticCoronaryAng.sinusesValsalvaMeanDiameter"
                        :label="t('proceduralDiagnostics.sinusesOfValsalvaMeanDiameter')"
                    />
                </div>
                <div class="col-12 md:col-4 field">
                    <WInputNumberCheckbox
                        :disabled="disabled"
                        v-model="
                            model.proceduralDiagnosticCoronaryAng.sinotubularJunctionMeanDiameter
                        "
                        :label="t('proceduralDiagnostics.sinotubularJunctionMeanDiameter')"
                    />
                </div>
            </div>
            <div class="grid">
                <div class="col-12 md:col-4 field">
                    <WInputNumberCheckbox
                        :disabled="disabled"
                        v-model="
                            model.proceduralDiagnosticCoronaryAng
                                .sinotubularJunctionHeightFromAnnulus
                        "
                        :label="t('proceduralDiagnostics.sinotubularJunctionHeightFromAnnulus')"
                    />
                </div>
                <div class="col-12 md:col-4 field">
                    <WInputNumberCheckbox
                        :disabled="disabled"
                        v-model="model.proceduralDiagnosticCoronaryAng.rcaOstiumHeigh"
                        :label="t('proceduralDiagnostics.rcaOstiumHeight')"
                    />
                </div>
                <div class="col-12 md:col-4 field">
                    <WInputNumberCheckbox
                        :disabled="disabled"
                        v-model="model.proceduralDiagnosticCoronaryAng.lmsOstiumHeigh"
                        :label="t('proceduralDiagnostics.lmsOstiumHeight')"
                    />
                </div>
            </div>
        </template>
    </Card>
</template>

<script setup>
import Card from 'primevue/card';
import WInputNumberCheckbox from '@/components/wrappers/form/WInputNumberCheckbox.vue';
import { onMounted, ref, watch } from 'vue';
import { dropdownService } from '@/services/dropdown/dropdown.service';
import { useVuelidate } from '@vuelidate/core';
import { useI18n } from 'vue-i18n';
import WMultiSelect from '@/components/wrappers/form/WMultiSelect.vue';
import { ProceduralDiagnosticsDto } from '@/dto/proceduralDiagnostics/proceduralDiagnosticsDto';
import Ekg from '@/components/ekg/Ekg.vue';
import Echocardiography from '@/components/echocardiography/Echocardiography.vue';

const props = defineProps({
    modelValue: ProceduralDiagnosticsDto,
    disabled: {
        type: Boolean,
        default: false,
    },
    hasCabg: {
        type: Boolean,
        default: false,
    },
    selectButtonItems: Array,
    diseaseSeverities: Array,
    leftVentricularEjectionFraction: Array,
    atrialRhythm: Array,
    ventricularConduction: Array,
    atrioventricularBlock: Array,
});

/* const emit = defineEmits(['update:modelValue']); */
const v$ = useVuelidate();
const { t } = useI18n();
const model = ref(props.modelValue);
const coronaryArteryStenoses = ref([]);

const getCoronaryArteryStenosis = async () => {
    coronaryArteryStenoses.value = (await dropdownService.getCoronaryArteryStenosis()).data;
};

/* watch(
    () => model.value,
    (val) => {
        emit('update:modelValue', val);
    }
);

watch(
    () => props.modelValue,
    (val) => {
        model.value = val;
    }
); */

watch(
    () => props.hasCabg,
    (val) => {
        if (val) {
            model.value.proceduralDiagnosticCoronaryAng.coronaryArteryStenoses = null;
        }
    }
);

onMounted(async () => {
    getCoronaryArteryStenosis();
});

defineExpose({ v$ });
</script>
