<template>
    <div>
        <div class="grid formgrid">
            <div class="col-12 md:col-4 field">
                <WMultiSelect
                    :disabled="disabled"
                    :items="cardiacInterventions"
                    v-model="model.cardiacInterventions"
                    optionLabel="nameBg"
                    :label="t('procedureComplication.cardiacInterventions')"
                    :is-required="true"
                ></WMultiSelect>
            </div>
            <div v-if="containsRedoTAVI" class="col-12 md:col-4 field">
                <WMultiSelect
                    :disabled="disabled"
                    :items="reinterventionIndications"
                    v-model="model.reinterventionIndications"
                    optionLabel="nameBg"
                    :label="t('procedureComplication.redoTaviReason')"
                    :is-required="false"
                ></WMultiSelect>
            </div>
            <div v-if="containsOtherHeartValveSurgery" class="col-12 md:col-4 field">
                <WMultiSelect
                    :disabled="disabled"
                    :items="heartValves"
                    v-model="model.heartValves"
                    optionLabel="nameBg"
                    :label="t('procedureComplication.otherHeartValveSurgery')"
                    :is-required="false"
                ></WMultiSelect>
            </div>
        </div>
        <div class="grid">
            <div class="col-12 md:col-4 field">
                <WSelectButton
                    :disabled="disabled"
                    :items="selectButtonItems"
                    v-model="model.myoCardialInfarctionId"
                    :label="t('procedureComplication.myocardialInfarction')"
                    :is-required="false"
                />
            </div>
            <div class="col-12 md:col-4 field">
                <WMultiSelect
                    :disabled="disabled"
                    :items="stroke"
                    v-model="model.strokes"
                    optionLabel="nameBg"
                    :label="t('procedureComplication.brainStroke')"
                    :is-required="false"
                ></WMultiSelect>
            </div>
            <div class="col-12 md:col-4 field">
                <WSelectButton
                    :disabled="disabled"
                    :items="selectButtonItems"
                    v-model="model.inHospitalCardiogenicShockId"
                    :label="t('procedureComplication.inHospitalCardiogenicShock')"
                    :is-required="false"
                />
            </div>
        </div>
        <div class="grid">
            <div class="col-12 md:col-4 field">
                <WDropdown
                    :disabled="disabled"
                    :items="cardiogenicShockStage"
                    :is-required="false"
                    v-model="model.cardiogenicShockStageId"
                    optionLabel="nameBg"
                    :label="t('procedureComplication.ifCardiogenicShockStage')"
                ></WDropdown>
            </div>
            <div class="col-12 md:col-4 field">
                <WMultiSelect
                    :disabled="disabled"
                    :items="implantableCardiacDevices"
                    v-model="model.implantableCardiacDevices"
                    optionLabel="nameBg"
                    :label="t('procedureComplication.implantableCardiacDevice')"
                    :is-required="false"
                ></WMultiSelect>
            </div>
            <div class="col-12 md:col-4 field"></div>
        </div>
        <div class="grid">
            <div class="col-12 md:col-4 field">
                <WSelectButton
                    :disabled="disabled"
                    :items="selectButtonItems"
                    v-model="model.cardiacInterventionsId"
                    :label="t('procedureComplication.cardiacArrest')"
                    :is-required="false"
                />
            </div>
            <div class="col-12 md:col-4 field">
                <WSelectButton
                    :disabled="disabled"
                    :items="selectButtonItems"
                    v-model="model.varc3MajorBleedingId"
                    :label="t('procedureComplication.vARC3MajorBleeding')"
                    :is-required="false"
                />
            </div>
        </div>
        <div class="grid">
            <div class="col-12 md:col-4 field">
                <WSelectButton
                    :disabled="disabled"
                    :items="selectButtonItems"
                    v-model="model.inHospitalDeathId"
                    :label="t('procedureComplication.inHospitalDeath')"
                    :is-required="true"
                />
            </div>
            <div class="col-12 md:col-4 field">
                <WSelectButton
                    :disabled="disabled"
                    :items="selectButtonItems"
                    v-model="model.newRequirementForRenalReplacementTherapyId"
                    :label="t('procedureComplication.newRequirementForRenalReplacementTherapy')"
                    :is-required="false"
                />
            </div>
        </div>
        <div v-if="model.inHospitalDeathId == SelectButtonValues.Yes" class="grid">
            <div class="col-12 md:col-4 field">
                <WDropdown
                    :disabled="disabled"
                    :items="deathReason"
                    v-model="model.deathReasonId"
                    :is-required="false"
                    optionLabel="nameBg"
                    :label="t('procedureComplication.deathReason')"
                ></WDropdown>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { SelectButtonValues } from '@/enums/selectButtonValues';
import WMultiSelect from '@/components/wrappers/form/WMultiSelect.vue';
import WSelectButton from '@/components/wrappers/button/WSelectButton.vue';
import WDropdown from '@/components/wrappers/form/WDropdown.vue';
import { dropdownService } from '@/services/dropdown/dropdown.service';
import { onMounted, ref, watch, computed } from 'vue';
import { CardiacInterventions } from '@/enums/cardiacInterventions';
import { ProcedureComplicationDto } from '@/dto/procedureComplication/procedureComplicationDto';
import { useVuelidate } from '@vuelidate/core';

/* const emit = defineEmits(['update:modelValue']); */

const props = defineProps({
    modelValue: ProcedureComplicationDto,
    disabled: {
        type: Boolean,
        default: false,
    },
    selectButtonItems: Array,
    cardiacInterventions: Array,
    reinterventionIndications: Array,
    implantableCardiacDevices: Array,
    heartValves: Array,
});

const { t } = useI18n();
const v$ = useVuelidate();
const model = ref(props.modelValue);
const stroke = ref([]);
const cardiogenicShockStage = ref([]);
const deathReason = ref([]);

const getStroke = async () => {
    stroke.value = (await dropdownService.getStroke()).data;
};

const getCardiogenicShockStage = async () => {
    cardiogenicShockStage.value = (await dropdownService.getCardiogenicShockStage()).data;
};

const getDeathReason = async () => {
    deathReason.value = (await dropdownService.getDeathReason()).data;
};

const containsOtherHeartValveSurgery = computed({
    get() {
        return props.modelValue.cardiacInterventions?.some(
            (x) => x === CardiacInterventions.OtherHeartValveSurgery
        );
    },
    set(val) {
        return val;
    },
});

const containsRedoTAVI = computed({
    get() {
        return props.modelValue.cardiacInterventions?.some(
            (x) => x === CardiacInterventions.RedoTAVI
        );
    },
    set(val) {
        return val;
    },
});

/* watch(
    () => model.value,
    (val) => {
        emit('update:modelValue', val);
    }
);

watch(
    () => props.modelValue,
    (val) => {
        model.value = val;
    }
); */

watch(
    () => model.value.inHospitalDeathId,
    (val) => {
        if (val != SelectButtonValues.Yes) model.value.deathReasonId = null;
    }
);

watch(
    () => model.value.cardiacInterventions,
    () => {
        if (!containsRedoTAVI.value) {
            model.value.reinterventionIndications = null;
        }
        if (!containsOtherHeartValveSurgery.value) {
            model.value.heartValves = null;
        }
    }
);

onMounted(async () => {
    getStroke();
    getCardiogenicShockStage();
    getDeathReason();
});

defineExpose({ v$ });
</script>
