import { TaviDeviceCreateDto } from '@/dto/taviDevice/taviDeviceCreateDto';

export class TaviDto {
    constructor(obj) {
        this.id = obj.id;
        this.date = obj.date;
        this.procedureStatusId = obj.procedureStatusId || null;
        this.anaesthesiaTypeId = obj.anaesthesiaTypeId || null;
        this.generalAnaesthesiaPlanTypeId = obj.generalAnaesthesiaPlanTypeId;
        this.vascularAccessTechniqueId = obj.vascularAccessTechniqueId;
        this.vascularAccessSiteId = obj.vascularAccessSiteId;
        this.prostheticValveFractureAttemptedId = obj.prostheticValveFractureAttemptedId;
        this.aorticBalloonValvuloplastyBeforeValveId = obj.aorticBalloonValvuloplastyBeforeValveId;
        this.postImplantationBalloonDilatationOfImplantedValveId =
            obj.postImplantationBalloonDilatationOfImplantedValveId;
        this.balloonSize = obj.balloonSize;
        this.preImplantationPeakToPeakInvasiveAvgradient =
            obj.preImplantationPeakToPeakInvasiveAvgradient;
        this.postImplantationPeakToPeakInvasiveAvgradient =
            obj.postImplantationPeakToPeakInvasiveAvgradient;
        this.postImplantationPvl = obj.postImplantationPvl;
        this.rapidPacingId = obj.rapidPacingId;
        this.proceduralCirculatoryPlanTypeId = obj.proceduralCirculatoryPlanTypeId;
        this.vascularClosureTechniqueId = obj.vascularClosureTechniqueId;
        this.conversionToSternotomyDate = obj.conversionToSternotomyDate;
        this.vascularComplicationsId = obj.vascularComplicationsId;
        this.sternotomies = obj.sternotomies;
        this.proceduresDuringTavi = obj.proceduresDuringTavi;
        this.proceduralEvents = obj.proceduralEvents;
        this.circulatorySupportTypes = obj.circulatorySupportTypes;
        this.otherArterialAccessSites = obj.otherArterialAccessSites;
        this.sternotomyReasons = obj.sternotomyReasons;

        this.taviDevices = [];
        if (obj.taviDevices) {
            obj.taviDevices.forEach((element) => {
                this.taviDevices.push(new TaviDeviceCreateDto(element));
            });
        }
    }
}
