import PatientList from '@/views/patient/PatientList.vue';
import PatientCreate from '@/views/patient/PatientCreate.vue';
import PatientEdit from '@/views/patient/PatientEdit.vue';
import PatientView from '@/views/patient/PatientView.vue';
import PatientProcedureList from '@/views/patient/PatientProcedureList.vue';

export const patientRoutes = [
    {
        path: '/patient/list',
        component: PatientList,
    },
    {
        path: '/patient/create',
        component: PatientCreate,
    },
    {
        path: '/patient/edit/:id?',
        component: PatientEdit,
    },
    {
        path: '/patient/display/:id?',
        component: PatientView,
    },
    {
        path: '/patient/procedureList/:id?',
        component: PatientProcedureList,
    },
];
