import { EkgDto } from '@/dto/ekg/EkgDto';
import { ProceduralDiagnosticLaboratoryDto } from '@/dto/proceduralDiagnostics/proceduralDiagnosticLaboratoryDto';
import { ProceduralDiagnosticCoronaryAngDto } from '@/dto/proceduralDiagnostics/proceduralDiagnosticCoronaryAngDto';
import { ProceduralDiagnosticEchocardiographyDto } from '@/dto/proceduralDiagnostics/proceduralDiagnosticEchocardiographyDto';

export class ProceduralDiagnosticsDto {
    constructor(obj) {
        this.id = obj.id;
        this.ekg = new EkgDto(obj.ekg || {});
        this.proceduralDiagnosticEchocardiography = new ProceduralDiagnosticEchocardiographyDto(
            obj.proceduralDiagnosticEchocardiography || {}
        );
        this.proceduralDiagnosticLaboratory = new ProceduralDiagnosticLaboratoryDto(
            obj.proceduralDiagnosticLaboratory || {}
        );
        this.proceduralDiagnosticCoronaryAng = new ProceduralDiagnosticCoronaryAngDto(
            obj.proceduralDiagnosticCoronaryAng || {}
        );
    }
}
