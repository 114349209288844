import { httpService } from '@/services/http.service';

export const nomenclatureManagementService = {
    async getNomenclatureTables() {
        return httpService.get('/NomenclatureManagement/GetNomenclatureTablesList');
    },
    async getNomenclatureItems(tableName) {
        return httpService.get(`/NomenclatureManagement/GetNomenclatureItems/${tableName}`);
    },
    async editNomenclatureItem(nomenclatureItem, entityTypeName) {
        return httpService.put(`/NomenclatureManagement/${entityTypeName}`, nomenclatureItem);
    },
    async addNomenclatureItem(nomenclatureItem, entityTypeName) {
        return httpService.post(`/NomenclatureManagement/${entityTypeName}`, nomenclatureItem);
    },
};
