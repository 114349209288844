<template>
    <div class="grid">
        <!-- Област -->
        <div class="col-4">
            <WDropdown
                v-model="addressValue.areaId"
                :disabled="disabled"
                :items="areas"
                :is-required="isRequired"
                :label="$t('address.area')"
            ></WDropdown>
        </div>
        <!-- Община -->
        <div class="col-4">
            <WDropdown
                v-model="addressValue.municipalityId"
                :disabled="disabled"
                :items="municipalities"
                :is-required="isRequired"
                :label="$t('address.municipality')"
            ></WDropdown>
        </div>
        <!-- Населено място -->
        <div class="col-4">
            <WDropdown
                v-model="addressValue.cityId"
                :disabled="disabled"
                :items="cities"
                :label="$t('address.city')"
                :is-required="isRequired"
                @update:modelValue="$emit('update:modelValue', addressValue)"
            ></WDropdown>
        </div>
    </div>
    <!-- Адрес -->
    <WInputText
        v-model="addressValue.address"
        :disabled="disabled"
        :label="$t('address.address')"
        :is-required="isRequired"
        @update:modelValue="$emit('update:modelValue', addressValue)"
    ></WInputText>
</template>

<script setup>
import WDropdown from '@/components/wrappers/form/WDropdown.vue';
import WInputText from '@/components/wrappers/form/WInputText.vue';
import { onMounted, ref, watch } from 'vue';
import { addressService } from '@/services/address/address.service';
import { AddressDto } from '@/components/wrappers/address/dto/addressDto';

const areas = ref([]);
const municipalities = ref([]);
const cities = ref([]);

const props = defineProps({
    modelValue: AddressDto,
    isRequired: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});

const addressValue = ref(new AddressDto(props.modelValue || {}));

const loadAreas = async () => {
    areas.value = (await addressService.getAreas()).data;
};

const loadMunicipalities = async () => {
    if (addressValue.value.areaId) {
        municipalities.value = (
            await addressService.getMunicipalitiesForArea(addressValue.value.areaId)
        ).data;
    } else {
        municipalities.value = [];
    }
};

const loadCities = async () => {
    if (addressValue.value.municipalityId) {
        cities.value = (
            await addressService.getCitiesForMunicipality(addressValue.value.municipalityId)
        ).data;
    } else {
        cities.value = [];
    }
};

watch(
    () => addressValue.value.areaId,
    () => {
        loadMunicipalities();
        // Не се зачистваше списъка с градове след смяна на AreaId
        cities.value = [];
    }
);

watch(
    () => addressValue.value.municipalityId,
    () => {
        loadCities();
    }
);

const loadSavedData = async () => {
    await loadAreas();
    await loadMunicipalities();
    await loadCities();
};

onMounted(() => {
    if (props.modelValue.cityId) {
        loadSavedData();
    } else {
        loadAreas();
    }
});
</script>
