<template>
    <div>
        <label
            class="block font-medium mb-2"
            :class="{ 'p-error': v$.selectedValue.$invalid && v$.$dirty, required: isRequired }"
        >
            {{ label }}
        </label>
        <dropdown
            v-model="selectedValue"
            :options="items"
            :option-label="optionLabel"
            :option-value="optionValue"
            :class="v$.selectedValue.$invalid && v$.$dirty ? `p-invalid` : ''"
            class="w-full"
            input-class="w-full"
            :editable="editable"
            :disabled="disabled"
            @update:modelValue="$emit('update:modelValue', selectedValue)"
        ></dropdown>
        <small v-if="v$.selectedValue.$errors.length > 0" class="p-error">
            {{ v$.selectedValue.$errors[0].$message }}
        </small>
    </div>
</template>

<script setup>
import Dropdown from 'primevue/dropdown';
import { computed, ref, watch } from 'vue';
import { required } from '@/validation/validators';
import { useVuelidate } from '@vuelidate/core';
import { localeStore } from '@/store/locale.store';

const props = defineProps({
    modelValue: Number,
    items: Array,
    optionValue: {
        type: String,
        default: 'id',
    },
    label: String,
    isRequired: {
        type: Boolean,
        default: false,
    },
    editable: {
        type: Boolean,
        default: true, // TODO: Да се направи така, че да не се въвежда нещо различно от предоставените опции.
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});

const optionLabel = computed(() => (localeStore().isBg ? 'nameBg' : 'nameEn'));

const selectedValue = ref(props.modelValue);

const rules = {
    selectedValue: {},
};

if (props.isRequired) {
    Object.assign(rules.selectedValue, { required });
}
watch(
    () => props.modelValue,
    (val) => {
        selectedValue.value = val;
    }
);
const v$ = useVuelidate(rules, { selectedValue });
</script>
