<template>
    <div class="grid">
        <div class="col-12 md:col-4 field">
            <WDropdown
                :disabled="disabled"
                v-model="model.atrialRhythmId"
                :items="atrialRhythm"
                :is-required="true"
                optionLabel="nameBg"
                :label="t('dischargeData.atrialRhythm')"
            ></WDropdown>
        </div>
        <div class="col-12 md:col-4 field">
            <WDropdown
                :disabled="disabled"
                v-model="model.ventricularConductionId"
                :items="ventricularConduction"
                :is-required="isRequired"
                optionLabel="nameBg"
                :label="t('dischargeData.ventricularConduction')"
            ></WDropdown>
        </div>
    </div>
    <div class="grid">
        <div class="col-12 md:col-4 field">
            <WSelectButton
                :disabled="disableHaveSinusRhythm"
                v-model="model.hasAtrioventricularBlockId"
                :items="selectButtonItems"
                :label="t('dischargeData.atrioventricularBlock')"
                :is-Required="isRequired"
            />
        </div>
        <div
            v-if="model.hasAtrioventricularBlockId === SelectButtonValues.Yes"
            class="col-12 md:col-4 field"
        >
            <WDropdown
                :disabled="disabled"
                v-model="model.atrioventricularBlockId"
                :items="atrioventricularBlock"
                :is-required="true"
                optionLabel="nameBg"
                :label="t('dischargeData.atrioventricularBlock')"
            ></WDropdown>
        </div>
    </div>
</template>
<script setup>
import { EkgDto } from '@/dto/ekg/EkgDto';
import { useI18n } from 'vue-i18n';
import WDropdown from '@/components/wrappers/form/WDropdown.vue';
import WSelectButton from '@/components/wrappers/button/WSelectButton.vue';
import { SelectButtonValues } from '@/enums/selectButtonValues';
import { ref, watch, computed } from 'vue';
import { AtrialRhythm } from '@/enums/atrialRhythm';

const { t } = useI18n();
const emit = defineEmits(['update:modelValue']);

const props = defineProps({
    modelValue: EkgDto,
    disabled: {
        type: Boolean,
        default: false,
    },
    isRequired: {
        type: Boolean,
        default: false,
    },
    selectButtonItems: Array,
    atrialRhythm: Array,
    ventricularConduction: Array,
    atrioventricularBlock: Array,
});

const model = ref(props.modelValue);

const disableHaveSinusRhythm = computed(
    () => props.disabled || model.value.atrialRhythmId !== AtrialRhythm.SinusRhythm
);

watch(
    () => model.value,
    (val) => {
        emit('update:modelValue', val);
    }
);

watch(
    () => props.modelValue,
    (val) => {
        model.value = val;
    }
);

watch(
    () => model.value.hasAtrioventricularBlockId,
    (val) => {
        if (val !== SelectButtonValues.Yes) {
            model.value.atrioventricularBlockId = null;
        }
    }
);

watch(
    () => props.modelValue.atrialRhythmId,
    (val) => {
        if (val !== AtrialRhythm.SinusRhythm) {
            model.value.hasAtrioventricularBlockId = SelectButtonValues.No;
        }
    }
);
</script>
