<template>
    <div class="my-2">
        <label
            :for="`inputText${_uid}`"
            class="block font-medium mb-2"
            :class="{ 'p-error': v$.inputValue.$invalid && v$.$dirty }"
            >{{ label }}</label
        >
        <Textarea
            v-model="inputValue"
            :disabled="disabled"
            class="block text-900 font-medium w-full"
            :class="v$.inputValue.$invalid && v$.$dirty ? `p-invalid ${cssClass}` : cssClass"
            :id="`inputText${_uid}`"
            :type="type"
            @update:modelValue="$emit('update:modelValue', inputValue)"
        ></Textarea>
        <small v-if="v$.inputValue.$errors.length > 0" class="p-error">
            {{ v$.inputValue.$errors[0].$message }}
        </small>
    </div>
</template>

<script setup>
import Textarea from 'primevue/textarea';
import { getCurrentInstance, ref, watch } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { email, required, exactLength, minLength } from '@/validation/validators';

const props = defineProps({
    modelValue: String,
    type: String,
    label: String,
    cssClass: String,
    isRequired: {
        type: Boolean,
        default: false,
    },
    exactLength: Number,
    disabled: {
        type: Boolean,
        default: false,
    },
});

const inputValue = ref(props.modelValue);
const _uid = getCurrentInstance().uid;

const rules = {
    inputValue: {},
};

watch(
    () => props.modelValue,
    (val) => {
        if (val != inputValue.value) inputValue.value = val;
    }
);

if (props.type === 'email') {
    Object.assign(rules.inputValue, { email });
}
if (props.exactLength) {
    Object.assign(rules.inputValue, { exactLength: exactLength(props.exactLength) });
}
if (props.isRequired) {
    Object.assign(rules.inputValue, { required });
}

const v$ = useVuelidate(rules, { inputValue });
</script>
