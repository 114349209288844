export class TaviDeviceCreateDto {
    constructor(obj) {
        this.id = obj.id;
        this.deviceModelId = obj.deviceModelId;
        this.diameter = obj.diameter;
        this.successfullImplantedId = obj.successfullImplantedId;
        this.unsuccessfulImplantationReasonId = obj.unsuccessfulImplantationReasonId;
        this.valveSerialNumber = obj.valveSerialNumber;
    }
}
