<template>
    <h1>Admin dashboard...</h1>
    <Chart type="bar" :data="chartData" :options="chartOptions" />
</template>

<script setup>
import Chart from 'primevue/chart';
import { onMounted, ref } from "vue";
import { dashboardService } from "@/services/dashboard/dashboard.service";

const chartData = ref({
    labels: [],
    datasets: [
    ]
});

const getTaviProceduresCountByHospital = (async () => {
    let dataSet = {
        label: 'Брой TAVI процедури',
        data: [],
        backgroundColor: ['rgba(255, 159, 64, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(153, 102, 255, 0.2)'],
        borderColor: ['rgb(255, 159, 64)', 'rgb(75, 192, 192)', 'rgb(54, 162, 235)', 'rgb(153, 102, 255)'],
        borderWidth: 1
    };

    const data = (await dashboardService.getTaviProceduresCountByHospital()).data;

    for (let d of data) {
        chartData.value.labels.push(d.hospital);
        dataSet.data.push(d.count);
    }

    chartData.value.datasets.push(dataSet);
});

const chartOptions = ref({
    scales: {
        y: {
            beginAtZero: true
        }
    }
});

onMounted(() => {
    getTaviProceduresCountByHospital();
})
</script>
