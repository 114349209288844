<template>
    <Card style="width: 100%; margin-bottom: 2em">
        <template #title>{{ t('dischargeData.dischargeDetails') }} </template>
        <template #content>
            <div class="grid formgrid">
                <div class="col-12 md:col-4 field">
                    <WDateTimePicker
                        :disabled="disabled"
                        v-model="model.dischargeDateOrDeathDate"
                        :label="t('dischargeData.dischargedateDeathDate')"
                        :is-required="true"
                    />
                </div>
                <div class="col-12 md:col-4 field">
                    <WDropdown
                        :disabled="disabled"
                        v-model="model.oralAnticoagulantsId"
                        :items="oralAnticoagulants"
                        :is-required="true"
                        optionLabel="nameBg"
                        :label="t('dischargeData.oralAnticoagulants')"
                    ></WDropdown>
                </div>
                <div class="col-12 md:col-4 field">
                    <WDropdown
                        :disabled="disabled"
                        v-model="model.p2y12inhibitorsId"
                        :items="p2Y12Inhibitors"
                        :is-required="true"
                        optionLabel="nameBg"
                        :label="t('dischargeData.p2Y12Inhibitors')"
                    ></WDropdown>
                </div>
            </div>
            <div class="grid">
                <div class="col-12 md:col-4 field">
                    <WSelectButton
                        :disabled="disabled"
                        v-model="model.myocardialInfarctionId"
                        :items="selectButtonItems"
                        :label="t('dischargeData.myocardialInfarction')"
                        :is-Required="false"
                    />
                </div>
                <div class="col-12 md:col-4 field">
                    <WSelectButton
                        :disabled="disabled"
                        v-model="model.aspirinId"
                        :items="selectButtonItems"
                        :label="t('dischargeData.aspirin')"
                        :is-Required="true"
                    />
                </div>
            </div>
        </template>
    </Card>
    <Card style="width: 100%; margin-bottom: 2em">
        <template #title>{{ t('dischargeData.laboratory') }}</template>
        <template #content>
            <div class="grid">
                <div class="col-12 md:col-4 field">
                    <WInputNumberCheckbox
                        :disabled="disabled"
                        :is-required="true"
                        v-model="model.creatinineAtDischarge"
                        :label="t('dischargeData.creatinineAtDischarge')"
                    />
                </div>
                <div class="col-12 md:col-4 field">
                    <WInputNumberCheckbox
                        :disabled="disabled"
                        :is-required="true"
                        v-model="model.haemoglobinAtDischarge"
                        :label="t('dischargeData.haemoglobinAtDischarge')"
                    />
                </div>
            </div>
        </template>
    </Card>
    <Card v-if="model.ekg" style="width: 100%; margin-bottom: 2em">
        <template #title>{{ t('dischargeData.ecg') }}</template>
        <template #content>
            <Ekg
                :disabled="disabled"
                v-model="model.ekg"
                :selectButtonItems="selectButtonItems"
                :atrialRhythm="atrialRhythm"
                :ventricularConduction="ventricularConduction"
                :atrioventricularBlock="atrioventricularBlock"
                :is-required="true"
            />
        </template>
    </Card>
    <Card style="width: 100%; margin-bottom: 2em">
        <template #title>{{ t('dischargeData.preDischargeEchocardiogram') }}</template>
        <template #content>
            <div class="grid">
                <div class="col-12 md:col-4 field">
                    <WInputNumberCheckbox
                        :disabled="disabled"
                        :is-required="true"
                        v-model="model.postDeploymentAorticValvePeakVelocity"
                        :label="t('dischargeData.postDeploymentAorticValveMeanGradient')"
                    />
                </div>
                <div class="col-12 md:col-4 field">
                    <WDropdown
                        :disabled="disabled"
                        v-model="model.diseaseSeverityId"
                        :items="diseaseSeverities"
                        :is-required="false"
                        optionLabel="nameBg"
                        :label="t('dischargeData.postDeploymentMitralRegurgitation')"
                    ></WDropdown>
                </div>
                <div class="col-12 md:col-4 field">
                    <WDropdown
                        :disabled="disabled"
                        v-model="model.pvlId"
                        :items="PVLs"
                        :is-required="true"
                        optionLabel="nameBg"
                        :label="t('dischargeData.pvl')"
                    ></WDropdown>
                </div>
            </div>
        </template>
    </Card>
</template>

<script setup>
import Card from 'primevue/card';
import WInputNumberCheckbox from '@/components/wrappers/form/WInputNumberCheckbox.vue';
import WDropdown from '@/components/wrappers/form/WDropdown.vue';
import WDateTimePicker from '@/components/wrappers/dateTime/WDateTimePicker.vue';
import { onMounted, ref, watch } from 'vue';
import { dropdownService } from '@/services/dropdown/dropdown.service';
import { useVuelidate } from '@vuelidate/core';
import { useToast } from 'primevue/usetoast';
import WSelectButton from '@/components/wrappers/button/WSelectButton.vue';
import { useI18n } from 'vue-i18n';
import Ekg from '@/components/ekg/Ekg.vue';
import { DischargeDetailsDto } from '@/dto/dischargeDetails/dischargeDetailsDto';
import { PVLs } from '@/enums/dischargeDetailsPvl.js';

const props = defineProps({
    modelValue: DischargeDetailsDto,
    disabled: {
        type: Boolean,
        default: false,
    },
    selectButtonItems: Array,
    diseaseSeverities: Array,
    atrialRhythm: Array,
    ventricularConduction: Array,
    atrioventricularBlock: Array,
});

/* const emit = defineEmits(['update:modelValue']); */
const v$ = useVuelidate();
const toast = useToast();
const { t } = useI18n();

const model = ref(props.modelValue);

if (model.value.dischargeDateOrDeathDate === '') {
    model.value.dischargeDateOrDeathDate = null;
}

const oralAnticoagulants = ref([]);
const p2Y12Inhibitors = ref([]);

const getP2Y12Inhibitors = async () => {
    p2Y12Inhibitors.value = (await dropdownService.getP2Y12Inhibitors()).data;
};

const getOralAnticoagulants = async () => {
    oralAnticoagulants.value = (await dropdownService.getOralAnticoagulants()).data;
};

/*watch(
    () => model.value,
    (val) => {
        emit('update:modelValue', val);
    }
);

/* watch(
    () => props.modelValue,
    (val) => {
        model.value = val;
    },
); */

onMounted(async () => {
    getP2Y12Inhibitors();
    getOralAnticoagulants();
});

defineExpose({ v$ });
</script>
