export class PatientViewDto {
    constructor(
        firstName,
        middleName,
        lastName,
        personIdentityNumber,
        personIdentityTypeName,
        birthDate,
        genderName,
        email,
        phoneNumber
    ) {
        this.firstName = firstName;
        this.middleName = middleName;
        this.lastName = lastName;
        this.personIdentityNumber = personIdentityNumber;
        this.personIdentityTypeName = personIdentityTypeName;
        this.birthDate = birthDate;
        this.genderName = genderName;
        this.email = email;
        this.phoneNumber = phoneNumber;
    }
}
