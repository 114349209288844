import { httpService } from '@/services/http.service';

export const taviDraftService = {
    async getById(id) {
        return httpService.get(`/TaviDraft/${id}`);
    },
    async create(dto) {
        return httpService.post('/TaviDraft', dto);
    },
    async edit(dto) {
        return httpService.put('/TaviDraft', dto);
    },
    async delete(id) {
        return httpService.delete(`/TaviDraft/${id}`);
    },
    // TODO:
    async getAllByPatientId(patientId) {
        return httpService.get(`/tavi/getAllByPatientId/${patientId}`);
    },
};
