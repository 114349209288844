<template>
    <div v-if="dto">
        <WInputText
            v-model="dto.name"
            :is-required="true"
            :label="$t('hospital.name')"
        ></WInputText>

        <WInputText
            v-model="dto.rhiNumber"
            :is-required="true"
            :exact-length="10"
            :label="$t('hospital.rhiNumber')"
        ></WInputText>

        <WAddress v-model="dto.address" :is-required="true"></WAddress>

        <ContactPerson v-model="dto.contactPerson"></ContactPerson>

        <WButton :label="$t('buttons.save')" icon="pi pi-save" @click="edit"></WButton>
        <WLoader :is-loading="loading"></WLoader>
    </div>
</template>

<script setup>
import WInputText from '@/components/wrappers/form/WInputText.vue';
import WButton from '@/components/wrappers/button/WButton.vue';
import WAddress from '@/components/wrappers/address/WAddress.vue';
import WLoader from '@/components/wrappers/loaderSpinner/WLoader.vue';
import ContactPerson from '@/components/person/ContactPerson.vue';
import { useRoute } from 'vue-router';
import { onMounted, ref } from 'vue';
import { hospitalService } from '@/services/hospital/hospital.service';
import { useVuelidate } from '@vuelidate/core';
import { useToast } from 'primevue/usetoast';
import { HospitalDto } from '@/dto/hospital/hospitalDto';
import { useI18n } from 'vue-i18n';
import { router } from '@/router/router';

const route = useRoute();
const dto = ref();

onMounted(async () => {
    const hospitalId = route.params.id;
    const hospital = (await hospitalService.getById(hospitalId)).data;
    dto.value = new HospitalDto(hospital);
});

const { t } = useI18n();
const v$ = useVuelidate();
const toast = useToast();
const loading = ref(false);

const edit = async () => {
    await v$.value.$validate();
    if (v$.value.$errors.length > 0) {
        return;
    }

    loading.value = true;
    try {
        await hospitalService.edit(dto.value);
        toast.add({
            severity: 'success',
            summary: t('notifications.success'),
            detail: t('notifications.successfulEdit'),
            life: 3000,
        });
        router.push('/hospital/list');
    } catch {
        toast.add({
            severity: 'error',
            summary: t('notifications.error'),
            detail: t('notifications.errorEdit'),
            life: 3000,
        });
    } finally {
        loading.value = false;
    }
};
</script>
