import { httpService } from '@/services/http.service';

export const authService = {
    async register(registerDto) {
        return httpService.post('/auth/register', registerDto);
    },
    async login(loginDto) {
        return httpService.post('/auth/login', loginDto);
    },
    async changePassword(changePassDto) {
        return httpService.put('/auth/changePassword', changePassDto);
    },
    async logout() {
        // TODO:
    },
    async createUser(registerDto) {
        return httpService.post('/auth/CreateUser', registerDto);
    },
    async adminChangeUserPassword(dto) {
        return httpService.post('/auth/AdminChangeUserPassword', dto);
    }
};
