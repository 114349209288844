<template>
    <div class="col-12 mt-2">
        <WInputText
            v-model="model.personIdentityNumber"
            :label="t('personIdentity.identifier')"
            :is-required="true"
            :exact-length="10"
            inputmode="numeric"
            :on-key-press="civilNumberKeys"
            :invalid-personal-civil-number = "invalidCivilNumber"
        >
        </WInputText>
        <small v-if="invalidCivilNumber" class="p-error">
            {{ $t('validations.uniquePersonIdentifierExists') }}
        </small>
    </div>
</template>

<script setup>
import WInputText from '@/components/wrappers/form/WInputText.vue';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { PatientCreateDto } from '@/dto/patient/patientCreateDto';
import { patientService } from '@/services/patient/patient.service';

const props = defineProps({
    modelValue: PatientCreateDto,
    isInvalidPersonalCivilNumber: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['update:modelValue', 'setValidityCivilNumber']);

const { t } = useI18n();
const model = ref(props.modelValue);
const invalidCivilNumber = ref(props.isInvalidPersonalCivilNumber);

const onIdentityChange = (val) => {
    if (val) {
        model.value.birthDate = patientService.getDateFromIdentityNumber(val);
        model.value.genderId = patientService.getGenderIdFromIdentityNumber(val);
    }
};

const civilNumberKeys = (event) => {
    return (event.charCode >= 48 && event.charCode <= 57) || event.charCode === 46;
};

watch(
    () => props.modelValue,
    (val) => {
        model.value = val;
        onIdentityChange(val.personIdentityNumber);
    }
);

watch(
    () => model.value.personIdentityNumber,
    (newValue) => {
        onIdentityChange(newValue);
        emit('update:modelValue', model.value);
        emit('setValidityCivilNumber', false);
    }
);

watch(
    () => props.isInvalidPersonalCivilNumber,
    (val) => {
        invalidCivilNumber.value = val;
    }
);
</script>
