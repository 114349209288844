import { EkgDto } from '@/dto/ekg/EkgDto';

export class DischargeDetailsDto {
    constructor(obj) {
        this.id = obj.id;
        this.dischargeDateOrDeathDate = obj.dischargeDateOrDeathDate || '';
        this.oralAnticoagulantsId = obj.oralAnticoagulantsId || '';
        this.aspirinId = obj.aspirinId || '';
        this.myocardialInfarctionId = obj.myocardialInfarctionId || '';
        this.p2y12inhibitorsId = obj.p2y12inhibitorsId || '';
        this.creatinineAtDischarge = obj.creatinineAtDischarge || '';
        this.haemoglobinAtDischarge = obj.haemoglobinAtDischarge || '';
        this.postDeploymentAorticValvePeakVelocity = obj.postDeploymentAorticValvePeakVelocity || '';
        this.diseaseSeverityId = obj.diseaseSeverityId || '';
        this.ekg = new EkgDto(obj.ekg || {});
        this.pvlId = obj.pvlId || '';
    }
}
