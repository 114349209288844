<template>
    <Card style="width: 100%; margin-bottom: 2em">
        <template #title>{{ t('taviProcedure.tavi') }} </template>
        <template #content>
            <div class="grid formgrid">
                <div class="col-12 md:col-4 field">
                    <WDatePicker
                        :disabled="disabled"
                        :label="t('taviProcedure.date')"
                        v-model="model.date"
                        :is-required="true"
                    />
                </div>
                <div class="col-12 md:col-4 field">
                    <WDropdown
                        :disabled="disabled"
                        :items="procedureStatus"
                        v-model="model.procedureStatusId"
                        :is-required="true"
                        optionLabel="nameBg"
                        :label="t('taviProcedure.procedureStatus')"
                    ></WDropdown>
                </div>
                <div class="col-12 md:col-4 field">
                    <WDropdown
                        :disabled="disabled"
                        :items="planTypes"
                        v-model="model.generalAnaesthesiaPlanTypeId"
                        :is-required="true"
                        optionLabel="nameBg"
                        :label="t('taviProcedure.generalAnaesthesia')"
                    ></WDropdown>
                </div>
            </div>
            <div class="grid">
                <div class="col-12 md:col-4 field">
                    <WDropdown
                        :disabled="disabled"
                        :items="vascularTechniques"
                        v-model="model.vascularAccessTechniqueId"
                        :is-required="true"
                        optionLabel="nameBg"
                        :label="t('taviProcedure.vascularAccessTechnique')"
                    ></WDropdown>
                </div>
                <div class="col-12 md:col-4 field">
                    <WDropdown
                        :disabled="disabled"
                        :items="vascularAccessSite"
                        v-model="model.vascularAccessSiteId"
                        :is-required="true"
                        optionLabel="nameBg"
                        :label="t('taviProcedure.vascularAccessSite')"
                    ></WDropdown>
                </div>
                <div class="col-12 md:col-4 field">
                    <WMultiSelect
                        :disabled="disabled"
                        :items="otherArterialAccessSites"
                        v-model="model.otherArterialAccessSites"
                        optionLabel="nameBg"
                        :label="t('taviProcedure.otherArterialAccessSites')"
                    ></WMultiSelect>
                </div>
            </div>
            <div class="grid">
                <div class="col-12 md:col-4 field">
                    <WMultiSelect
                        :disabled="disabled"
                        :items="proceduresDuringTAVI"
                        v-model="model.proceduresDuringTavi"
                        optionLabel="nameBg"
                        :label="t('taviProcedure.proceduresDuringTAVI')"
                    ></WMultiSelect>
                </div>
                <div class="col-12 md:col-4 field">
                    <WDropdown
                        :disabled="disabled"
                        optionLabel="nameBg"
                        :items="rapidPacing"
                        v-model="model.rapidPacingId"
                        :label="t('taviProcedure.rapidPacing')"
                    ></WDropdown>
                </div>
                <div class="col-12 md:col-4 field">
                    <WDropdown
                        :disabled="disabled"
                        :items="vascularClosureTechniques"
                        v-model="model.vascularClosureTechniqueId"
                        optionLabel="nameBg"
                        :label="t('taviProcedure.vascularClosureTechnique')"
                        :is-required="true"
                    ></WDropdown>
                </div>
            </div>
            <div class="grid">
                <div class="col-12 md:col-4 field">
                    <WSelectButton
                        :disabled="disabled"
                        :items="selectButtonItems"
                        v-model="model.prostheticValveFractureAttemptedId"
                        :label="t('taviProcedure.prostheticValveFractureAttempted')"
                    />
                </div>
                <div class="col-12 md:col-4 field">
                    <WSelectButton
                        :disabled="disabled"
                        :items="selectButtonItems"
                        v-model="model.aorticBalloonValvuloplastyBeforeValveId"
                        :label="t('taviProcedure.aorticBalloonValvuloplastyBeforeValveDeployment')"
                        :is-required="true"
                    />
                </div>
                <div class="col-12 md:col-4 field">
                    <WSelectButton
                        :disabled="disabled"
                        :items="selectButtonItems"
                        v-model="model.postImplantationBalloonDilatationOfImplantedValveId"
                        :label="
                            t('taviProcedure.postImplantationBalloonDilatationOfImplantedValve')
                        "
                        :is-required="true"
                    />
                </div>
            </div>
            <div class="grid">
                <div
                    v-if="
                        model.postImplantationBalloonDilatationOfImplantedValveId ===
                        SelectButtonValues.Yes
                    "
                    class="col-12 md:col-4 field"
                >
                    <WInputNumberCheckbox
                        :disabled="disabled"
                        v-model="model.balloonSize"
                        :label="t('taviProcedure.balloonSize')"
                    />
                </div>
                <div class="col-12 md:col-4 field">
                    <WInputNumberCheckbox
                        :disabled="disabled"
                        v-model="model.preImplantationPeakToPeakInvasiveAvgradient"
                        :label="
                            t('taviProcedure.pre-implantationPeak-to-peakInvasiveAVGradient(mmHg)')
                        "
                    />
                </div>
                <div class="col-12 md:col-4 field">
                    <WInputNumberCheckbox
                        :disabled="disabled"
                        v-model="model.postImplantationPeakToPeakInvasiveAvgradient"
                        :label="
                            t('taviProcedure.post-implantationPeak-to-peakInvasiveAVGradient(mmHg)')
                        "
                    />
                </div>
                <div class="col-12 md:col-4 field">
                    <WInputNumberCheckbox
                        :disabled="disabled"
                        v-model="model.postImplantationPvl"
                        :label="t('taviProcedure.postImplantationPVL')"
                        :is-required="true"
                    />
                </div>
            </div>
            <div class="grid">
                <div class="col-12 md:col-4 field">
                    <WDropdown
                        :disabled="disabled"
                        :items="anaesthesiaType"
                        v-model="model.anaesthesiaTypeId"
                        optionLabel="nameBg"
                        :label="t('taviProcedure.anaesthesiaType')"
                        :is-required="true"
                    ></WDropdown>
                </div>
                <div class="col-12 md:col-4 field">
                    <WDropdown
                        :disabled="disabled"
                        :items="planTypes"
                        v-model="model.proceduralCirculatoryPlanTypeId"
                        optionLabel="nameBg"
                        :label="t('taviProcedure.proceduralCirculatorySupport')"
                    ></WDropdown>
                </div>
                <div class="col-12 md:col-4 field">
                    <WMultiSelect
                        :disabled="disabled"
                        :items="circulatorySupportTypes"
                        v-model="model.circulatorySupportTypes"
                        optionLabel="nameBg"
                        :label="t('taviProcedure.proceduralCirculatorySupportType')"
                    ></WMultiSelect>
                </div>
            </div>
        </template>
    </Card>
    <Card style="width: 100%; margin-bottom: 2em">
        <template #title>{{ t('taviProcedure.proceduralEvents') }}</template>
        <template #content>
            <div class="grid">
                <div class="col-12 md:col-4 field">
                    <WMultiSelect
                        :disabled="disabled"
                        :items="proceduralEvents"
                        v-model="model.proceduralEvents"
                        :is-required="true"
                        optionLabel="nameBg"
                        :label="t('taviProcedure.proceduralEvent')"
                    ></WMultiSelect>
                </div>
                <div class="col-12 md:col-4 field" v-if="showProceduralEventsExt">
                    <WDatePicker
                        :disabled="disabled"
                        v-model="model.conversionToSternotomyDate"
                        :label="t('taviProcedure.conversionToSternotomyDate')"
                    />
                </div>
                <div class="col-12 md:col-4 field">
                    <WDropdown
                        :disabled="disabled"
                        :items="vascularComplications"
                        v-model="model.vascularComplicationsId"
                        :is-required="true"
                        optionLabel="nameBg"
                        :label="t('taviProcedure.vascularComplications')"
                    ></WDropdown>
                </div>
            </div>
            <div class="grid">
                <div v-if="showProceduralEventsExt" class="col-12 md:col-6 field">
                    <WMultiSelect
                        :disabled="disabled"
                        :items="sternotomyReasons"
                        v-model="model.sternotomyReasons"
                        optionLabel="nameBg"
                        :label="t('taviProcedure.conversionToSternotomyReason')"
                    ></WMultiSelect>
                </div>
                <div v-if="showProceduralEventsExt" class="col-12 md:col-6 field">
                    <WMultiSelect
                        :disabled="disabled"
                        v-model="model.sternotomies"
                        :items="sternotomyTypes"
                        optionLabel="nameBg"
                        :label="t('taviProcedure.conversionToSternotomyCardiopulmonaryBypass')"
                    ></WMultiSelect>
                </div>
            </div>
        </template>
    </Card>
    <TaviDevices
        v-model="model.taviDevices"
        :disabled="disabled"
        :aorticValvePathologies="aorticValvePathologies"
        :selectButtonItems="selectButtonItems"
        @add-new-device="addNewDevice"
        @remove-device="removeDevice"
    />
</template>

<script setup>
import WMultiSelect from '@/components/wrappers/form/WMultiSelect.vue';
import Card from 'primevue/card';
import WDropdown from '@/components/wrappers/form/WDropdown.vue';
import WDatePicker from '@/components/wrappers/dateTime/WDateTimePicker.vue';
import { onMounted, ref, watch, computed } from 'vue';
import { dropdownService } from '@/services/dropdown/dropdown.service';
import { useVuelidate } from '@vuelidate/core';
import { useToast } from 'primevue/usetoast';
import WSelectButton from '@/components/wrappers/button/WSelectButton.vue';
import { useI18n } from 'vue-i18n';
import { SelectButtonValues } from '@/enums/selectButtonValues';
import WInputNumberCheckbox from '@/components/wrappers/form/WInputNumberCheckbox.vue';
import { TaviDto } from '@/dto/tavi/taviDto';
import { ProceduralEvents } from '@/enums/proceduralEvents';
import TaviDevices from '@/components/tavi/TaviDevices.vue';

/* const emit = defineEmits(['update:modelValue']); */

const props = defineProps({
    modelValue: TaviDto,
    disabled: {
        type: Boolean,
        default: false,
    },
    selectButtonItems: Array,
    aorticValvePathologies: Array,
    circulatorySupportTypes: Array,
});

const v$ = useVuelidate();
const toast = useToast();
const { t } = useI18n();
const procedureStatus = ref([]);
const rapidPacing = ref([]);
const planTypes = ref([]);
const vascularTechniques = ref([]);
const vascularClosureTechniques = ref([]);
const vascularAccessSite = ref([]);
const otherArterialAccessSites = ref([]);
const proceduresDuringTAVI = ref([]);
const anaesthesiaType = ref([]);
const proceduralEvents = ref([]);
const sternotomyTypes = ref([]);
const sternotomyReasons = ref([]);
const vascularComplications = ref([]);
const model = ref(props.modelValue);

const addNewDevice = (val) => {
    model.value.taviDevices.push(val); 
};

const removeDevice = (index) => {
    model.value.taviDevices.splice(index, 1);
};

const getProcedureStatus = async () => {
    procedureStatus.value = (await dropdownService.getProcedureStatus()).data;
};

const getPlanTypes = async () => {
    planTypes.value = (await dropdownService.getPlanTypes()).data;
};

const getVascularTechniques = async () => {
    vascularTechniques.value = (await dropdownService.getVascularTechniques()).data;
};

const getVascularAccessSite = async () => {
    vascularAccessSite.value = (await dropdownService.getVascularAccessSite()).data;
};

const getOtherArterialAccessSites = async () => {
    otherArterialAccessSites.value = (await dropdownService.getOtherArterialAccessSites()).data;
};

const getProceduresDuringTAVI = async () => {
    proceduresDuringTAVI.value = (await dropdownService.getProceduresDuringTAVI()).data;
};

const getRapidPacing = async () => {
    rapidPacing.value = (await dropdownService.getRapidPacing()).data;
};

const getProceduralEvents = async () => {
    proceduralEvents.value = (await dropdownService.getProceduralEvents()).data;
};

const getSternotomyTypes = async () => {
    sternotomyTypes.value = (await dropdownService.getSternotomyTypes()).data;
};

const getSternotomyReasons = async () => {
    sternotomyReasons.value = (await dropdownService.getSternotomyReasons()).data;
};

const getVascularComplications = async () => {
    vascularComplications.value = (await dropdownService.getVascularComplications()).data;
};

const getAnaesthesiaType = async () => {
    anaesthesiaType.value = (await dropdownService.getAnaesthesiaType()).data;
};

const getVascularClosureTechniques = async () => {
    vascularClosureTechniques.value = (await dropdownService.getVascularClosureTechniques()).data;
};

const showProceduralEventsExt = computed({
    get() {
        return props.modelValue.proceduralEvents?.some(
            (x) => x === ProceduralEvents.ConversionToSternotomy
        );
    },
    set(val) {
        return val;
    },
});

/* watch(
    () => model.value,
    (val) => {
        emit('update:modelValue', val);
    }
);

watch(
    () => props.modelValue,
    (val) => {
        model.value = val;
    }
); */

watch(
    () => model.value.postImplantationBalloonDilatationOfImplantedValveId,
    (val) => {
        if (val === SelectButtonValues.No) model.value.balloonSize = null;
    }
);

watch(
    () => model.value.proceduralEvents,
    () => {
        if (!showProceduralEventsExt.value) {
            model.value.sternotomyReasons = null;
            model.value.sternotomies = null;
            model.value.conversionToSternotomyDate = null;
        }
    }
);

onMounted(async () => {
    getProcedureStatus();
    getPlanTypes();
    getVascularTechniques();
    getVascularAccessSite();
    getOtherArterialAccessSites();
    getProceduresDuringTAVI();
    getRapidPacing();
    getProceduralEvents();
    getSternotomyTypes();
    getSternotomyReasons();
    getVascularComplications();
    getAnaesthesiaType();
    getVascularClosureTechniques();
});

defineExpose({ v$ });
</script>
