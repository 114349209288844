<template>
    <sidebar
        v-if="menuStore().isMobileResolution"
        v-model:visible="menuStore().expandMobileMenu"
        class="py-0"
    >
        <panel-menu
            class="sidebar-panel-menu"
            :model="items"
            v-model:expanded-keys="expandedKeys"
            @panelOpen="panelOpen"
            @panel-close="panelClose"
        >
        </panel-menu>
    </sidebar>
    <panel-menu v-else :model="items" v-model:expanded-keys="expandedKeys"></panel-menu>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import Sidebar from 'primevue/sidebar';
import PanelMenu from 'primevue/panelmenu';
import { menuStore } from '@/store/menu.store';
import { useI18n } from 'vue-i18n';
import { userStore } from '@/store/user.store';
import { Roles } from '@/enums/roles';
import { UrlConfig } from '@/config/urlConfig';
import { useRouter } from 'vue-router';

const { t } = useI18n();
const router = useRouter();

const items = computed(() => [
    {
        key: '0',
        label: t('menu.dashboard'),
        icon: 'pi pi-home text-primary font-bold',
        to: '/',
        command: (event) => {
            onMenuItemClicked(event);
        },
    },
    {
        key: '1',
        label: t('menu.hospitals'),
        icon: 'pi pi-building text-primary font-bold',
        to: '/hospital/list',
        visible: () =>
            userStore().isUserInRole(Roles.HospitalAdmin) ||
            userStore().isUserInRole(Roles.Admin) ||
            userStore().isUserInRole(Roles.SysAdmin),
        command: (event) => {
            onMenuItemClicked(event);
        },
    },
    {
        key: '2',
        label: t('patient.patients'),
        icon: 'pi pi-users text-primary font-bold',
        to: '/patient/list',
        command: (event) => {
            onMenuItemClicked(event);
        },
    },
    {
        key: '3',
        label: t('menu.administration'),
        icon: 'pi pi-shield text-primary font-bold',
        visible: () =>
            userStore().isUserInRole(Roles.SysAdmin) || userStore().isUserInRole(Roles.Admin),
        items: [
            {
                key: '3_1',
                label: t('menu.usersWaitingApprove'),
                icon: 'pi pi-fw pi-user-plus text-primary font-bold',
                to: '/admin/registeredUsers',
                command: (event) => {
                    onMenuItemClicked(event);
                },
            },
            {
                key: '3_2',
                label: t('menu.users'),
                icon: 'pi pi-user text-primary font-bold',
                to: '/admin/users/list',
                command: (event) => {
                    onMenuItemClicked(event);
                },
            },
            {
                key: '3_3',
                label: t('menu.nomenclaturesManagement'),
                icon: 'pi pi-wrench text-primary font-bold',
                to: '/admin/nomenclaturesManagement',
                visible: () => userStore().isUserInRole(Roles.SysAdmin),
                command: (event) => {
                    onMenuItemClicked(event);
                },
            },
        ],
    },
    {
        key: '4',
        label: t('menu.procedures'),
        icon: 'pi pi-heart text-primary font-bold',
        items: [
            {
                key: '4_1',
                label: t('menu.createNewProcedure'),
                icon: 'pi pi-plus-circle text-primary font-bold',
                to: '/procedure/create',
                command: (event) => {
                    onMenuItemClicked(event);
                },
            },
            {
                key: '4_2',
                label: t('menu.procedureDraftList'),
                icon: 'pi pi-pencil text-primary font-bold',
                to: '/procedure/draftList',
                command: (event) => {
                    onMenuItemClicked(event);
                },
            },
            {
                key: '4_3',
                label: t('menu.procedureList'),
                icon: 'pi pi-list text-primary font-bold',
                to: '/procedure/list',
                command: (event) => {
                    onMenuItemClicked(event);
                },
            },
        ],
    },
    {
        key: '5',
        label: t('reports'),
        icon: 'pi pi-chart-line text-primary font-bold',
        urlMetabase: UrlConfig.metabaseUrl, // We using this unsupported prop because for some reasons target: '_blank' option not applying for this item.
        command: (event) => {
            onMenuItemClicked(event);
        },
    },
]);

onMounted(() => {
    // TODO: Get if is in mobile resolution
    // TODO: Add event for resize
    expandAll();
});

//In this function we use urlMetabase prop for item with key: '5' and add target='_blank' to open metabase in new tab on browser.
const onMenuItemClicked = (event) => {
    menuStore().expandMobileMenu = false;
    if(event.item.key ==='5'){
        window.open(event.item.urlMetabase, '_blank', 'noopener');
    }
};

const panelOpen = (event) => {};

const panelClose = (event) => {};

const expandedKeys = ref(null);

const expandAll = () => {
    for (let node of items.value) {
        expandNode(node);
    }

    expandedKeys.value = { ...expandedKeys.value };
};

const collapseAll = () => {
    expandedKeys.value = {};
};

const expandNode = (node) => {
    if (node.children && node.children.length) {
        expandedKeys.value[node.key] = true;

        for (let child of node.children) {
            expandNode(child);
        }
    }
};

router.beforeEach((to, _, next)=>{

    if(to.path.includes('admin')) {
        expandedKeys.value['3'] = true;
    }
    
    if(to.path.includes('procedure')) {
        expandedKeys.value['4'] = true;
    }

    next();
});

</script>

<style>
.p-sidebar-content {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.p-panelmenu-content {
    /*    height: 56px !important;*/
    padding-left: 20px !important;
    /*   padding-top: 10px!important;*/
}

.p-panelmenu-panel {
    box-shadow: none !important;
}

/*.router-link-active {
    color: red!*var(--primary-900)*! !important;
}*/

.router-link-active {
    color: var(--primary-color) !important;
    font-weight: bold !important;
}
/* Това е е необходимо защото в подменю трябва да се override-не .p-menuitem-text */
.p-menuitem-link.router-link-active.router-link-active-exact .p-menuitem-text {
    color: var(--primary-color) !important;
}
</style>
