import * as validators from '@vuelidate/validators';
import i18n from '@/i18n';
import { helpers } from '@vuelidate/validators';
import { UniquePersonIdentifierTypes } from '@/enums/uniquePersonIdentifierTypes';

const { createI18nMessage } = validators;
const withI18nMessage = createI18nMessage({ t: i18n.global.t.bind(i18n) });

export const required = withI18nMessage(validators.required);
export const minLength = withI18nMessage(validators.minLength, { withArguments: true });
export const maxLength = withI18nMessage(validators.maxLength, { withArguments: true });
export const minValue = withI18nMessage(validators.minValue, { withArguments: true });
export const maxValue = withI18nMessage(validators.maxValue, { withArguments: true });
export const email = withI18nMessage(validators.email);
export const requireIf = withI18nMessage(validators.requiredIf);
export const requireUnless = withI18nMessage(validators.requiredUnless);
export const requiredUnless = withI18nMessage(validators.requiredUnless);
export const between = withI18nMessage(validators.between, { withArguments: true });
export const alpha = withI18nMessage(validators.alpha);
export const alphaNum = withI18nMessage(validators.alphaNum);
export const numeric = withI18nMessage(validators.numeric);
export const integer = withI18nMessage(validators.integer);
export const decimal = withI18nMessage(validators.decimal);
export const sameAs = withI18nMessage(validators.sameAs, { withArguments: true });
export const url = withI18nMessage(validators.url);
export const or = withI18nMessage(validators.or, { withArguments: true });
export const and = withI18nMessage(validators.and, { withArguments: true });
export const not = withI18nMessage(validators.not, { withArguments: true });

// Custom validators
//const exactLengthValidator = (length) => (value) => value.length === length
const exactLengthValidator = (length) =>
    helpers.withParams({ value: length }, (value) => value.length === length);
export const exactLength = withI18nMessage(exactLengthValidator, { withArguments: true });

export const validPassword = withI18nMessage(helpers.regex(/^(?=.*[a-z])(?=.*\d)[A-Za-z\d@$!#%*?&-+<>`~()^=/\\]{6,}$/g), { withArguments: true })

const uniquePersonIdentifierValidator = (type) =>
    helpers.withParams({ value: type }, (value) => {
        const regex = /^\d{10}$/;
        if (!regex.test(value) && type !== UniquePersonIdentifierTypes.OtherNumber) {
            return false;
        }
        if (type === UniquePersonIdentifierTypes.CivilNumber) {
            let year = parseInt(value.substring(0, 2));
            let month = parseInt(value.substring(2, 4));
            if (month > 40) {
                month -= 40;
                year += 2000;
            } else if (month > 20) {
                month -= 20;
                year += 1800;
            } else {
                year += 1900;
            }
            const day = parseInt(value.substring(4, 6));
            let dayStr = `${day}`;
            if (day < 10) {
                dayStr = `0` + dayStr;
            }
            let monthStr = `${month}`;
            if (month < 10) {
                monthStr = `0` + monthStr;
            }
            // todo: chack if valid date

            const egnDigits = value.match(/[0-9]/g);
            const controlDigit = calculateControlDigitForEGN(egnDigits);
            const result = controlDigit == parseInt(egnDigits[9], 10);

            return result;
        } else if (type === UniquePersonIdentifierTypes.PersonalNumberOfForeigner) {
            const checkDigits = [21, 19, 17, 13, 11, 9, 7, 3, 1];
            const egnDigits = value.match(/[0-9]/g);
            const controlDigit = calculateChecksum(checkDigits, egnDigits) % 10;

            return controlDigit == parseInt(egnDigits[9], 10);
        } else if (type === UniquePersonIdentifierTypes.OtherNumber) {
            return true;
        }

        return false;
    });

function calculateChecksum(checkDigits, egnDigits) {
    const checkDigitsCount = checkDigits.length;
    let sum = 0;
    for (let k = 0; k < checkDigitsCount; k++) {
        sum += checkDigits[k] * parseInt(egnDigits[k], 10);
    }

    return sum;
}

function calculateControlDigitForEGN(egnDigits) {
    const checkDigits = [2, 4, 8, 5, 10, 9, 7, 3, 6];

    let controlDigit = calculateChecksum(checkDigits, egnDigits) % 11;
    if (controlDigit == 10) {
        controlDigit = 0;
    }

    return controlDigit;
}

export const uniquePersonIdentifier = withI18nMessage(uniquePersonIdentifierValidator, {
    withArguments: true,
});
