import { defineStore } from 'pinia';
import { Roles } from '@/enums/roles';

export const userStore = defineStore('user', {
    state: () => ({
        token: JSON.parse(localStorage.getItem('user') || '{}')?.token || '',
        tokenExpiration: JSON.parse(localStorage.getItem('user') || '{}')?.tokenExpiration || '',
        roles: JSON.parse(localStorage.getItem('user') || '[]')?.roles,
    }),
    getters: {
        isAuthenticated: (state) => state.token && new Date(state.tokenExpiration) > new Date(),
        authToken: (state) => state.token,
        approvedUser: (state) => state.roles && state.roles.includes(Roles.ApprovedUser),
        isUserInRole: (state) => {
            return (roleName) => state.roles && state.roles.includes(roleName)
        },
    },
    actions: {
        authenticateUser(tokenData) {
            this.token = tokenData.token;
            this.tokenExpiration = tokenData.tokenExpiration;
            this.roles = tokenData.roles;

            if (tokenData.roles && tokenData.roles.includes(Roles.ApprovedUser)) {
                let userSettings = JSON.parse(localStorage.getItem('user') || '{}');
                if (!userSettings) {
                    userSettings = {};
                }
                userSettings.token = this.token;
                userSettings.tokenExpiration = tokenData.tokenExpiration;
                // TODO: userName?
                userSettings.roles = tokenData.roles;
                localStorage.setItem('user', JSON.stringify(userSettings));
            }
        },
        logout() {
            this.token = '';
            this.tokenExpiration = '';
            this.roles = [];

            localStorage.setItem('user', '{}');
        },
    },
});
