<template>
    <div>
        <label
            :for="`inputMask${_uid}`"
            class="block font-medium mb-2"
            :class="{ 'p-error': v$.inputValue.$invalid && v$.$dirty, required: isRequired }"
        >
            {{ label }}
        </label>
        <InputMask
            v-model="inputValue"
            :mask="mask"
            class="block text-900 font-medium w-full"
            :class="v$.inputValue.$invalid && v$.$dirty ? `p-invalid ${cssClass}` : cssClass"
            :disabled="disabled"
            :id="`inputMask${_uid}`"
            @update:modelValue="$emit('update:modelValue', inputValue)"
        ></InputMask>
        <small v-if="v$.inputValue.$errors.length > 0" class="p-error">
            {{ v$.inputValue.$errors[0].$message }}
        </small>
    </div>
</template>

<script setup>
import InputMask from 'primevue/inputmask';
import { getCurrentInstance, ref, watch } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@/validation/validators';

const props = defineProps({
    modelValue: String,
    label: String,
    mask: String,
    cssClass: String,
    isRequired: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});

const inputValue = ref(props.modelValue);
const _uid = getCurrentInstance().uid;

const rules = {
    inputValue: {},
};

if (props.isRequired) {
    Object.assign(rules.inputValue, { required });
}

const v$ = useVuelidate(rules, { inputValue });

watch(
    () => props.modelValue,
    (val) => {
        inputValue.value = val;
    }
);
</script>
