<template>
    <div>
        <div class="grid formgrid">
            <div class="col-12 md:col-6 field">
                <WInputNumberCheckbox
                    :disabled="disabled"
                    v-model="model.height"
                    :label="t('patientCharacteristics.height')"
                />
            </div>
            <div class="col-12 md:col-6 field">
                <WInputNumberCheckbox
                    :disabled="disabled"
                    v-model="model.weight"
                    :label="t('patientCharacteristics.weight')"
                />
            </div>
        </div>
        <div class="grid">
            <div class="col-12 md:col-6 field">
                <WDropdown
                    :disabled="disabled"
                    :items="frailty"
                    v-model="model.frailtyId"
                    :label="t('patientCharacteristics.frailty')"
                ></WDropdown>
            </div>
            <div class="col-12 md:col-6 field">
                <WDropdown
                    :disabled="disabled"
                    :items="diabetesMellitus"
                    v-model="model.diabetesMellitusId"
                    :label="t('patientCharacteristics.diabetesMellitus')"
                ></WDropdown>
            </div>
        </div>
        <div class="grid">
            <div class="col-12 md:col-4 field">
                <WSelectButton
                    :disabled="disabled"
                    :items="selectButtonItems"
                    v-model="model.hypertensionId"
                    :label="t('patientCharacteristics.hypertension')"
                />
            </div>
            <div class="col-12 md:col-4 field">
                <WSelectButton
                    :disabled="disabled"
                    :items="selectButtonItems"
                    v-model="model.chronicObstructivePulmonaryDiseaseId"
                    :label="t('patientCharacteristics.chronicObstructivePulmonaryDisease')"
                />
            </div>
            <div class="col-12 md:col-4 field">
                <WSelectButton
                    :disabled="disabled"
                    :items="selectButtonItems"
                    v-model="model.activeMalignancyId"
                    :label="t('patientCharacteristics.activeMalignancy')"
                />
            </div>
            <div class="col-12 md:col-4 field">
                <WSelectButton
                    :disabled="disabled"
                    v-model="model.currentRenalDialysisId"
                    :items="selectButtonItems"
                    :label="t('patientCharacteristics.currentRenalDialysis')"
                />
            </div>
        </div>
        <div class="grid">
            <div class="col-12 md:col-4 field">
                <WSelectButton
                    :disabled="disabled"
                    :items="selectButtonItems"
                    :label="t('patientCharacteristics.atrialFibrillationFlutter')"
                    v-model="model.atrialFibrillationFlutterId"
                />
            </div>
            <div class="col-12 md:col-4 field">
                <WSelectButton
                    :disabled="disabled"
                    :items="selectButtonItems"
                    :label="t('patientCharacteristics.peripheralArterialDisease')"
                    v-model="model.peripheralArterialDiseaseId"
                />
            </div>
            <div class="col-12 md:col-4 field">
                <WSelectButton
                    :disabled="disabled"
                    :items="selectButtonItems"
                    :label="t('patientCharacteristics.porcelainAorta')"
                    v-model="model.porcelainAortaId"
                />
            </div>
            <div class="col-12 md:col-4 field">
                <WSelectButton
                    :disabled="disabled"
                    :items="selectButtonItems"
                    :label="t('patientCharacteristics.priorStroke')"
                    v-model="model.priorStrokeId"
                />
            </div>
        </div>
        <div class="grid">
            <div class="col-12 md:col-4 field">
                <WSelectButton
                    :disabled="disabled"
                    :items="selectButtonItems"
                    :label="t('patientCharacteristics.coronaryHeartDisease')"
                    v-model="model.coronaryHeartDiseaseId"
                />
            </div>
            <div class="col-12 md:col-4 field">
                <WSelectButton
                    :disabled="disabled"
                    :items="selectButtonItems"
                    :label="t('patientCharacteristics.priorMyocardialInfarction')"
                    v-model="model.priorMyocardialInfarctionId"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import WSelectButton from '@/components/wrappers/button/WSelectButton.vue';
import WDropdown from '@/components/wrappers/form/WDropdown.vue';
import WInputNumberCheckbox from '@/components/wrappers/form/WInputNumberCheckbox.vue';
import { dropdownService } from '@/services/dropdown/dropdown.service';
import { ref, onMounted, watch } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { useI18n } from 'vue-i18n';
import { GeneralPatientClinicalDataDto } from '@/dto/generalPatientClinicalData/generalPatientClinicalDataDto';

/* const emit = defineEmits(['update:modelValue']); */
const props = defineProps({
    modelValue: GeneralPatientClinicalDataDto,
    disabled: {
        type: Boolean,
        default: false,
    },
    selectButtonItems: Array,
});

const { t } = useI18n();
const frailty = ref([]);
const diabetesMellitus = ref([]);
const model = ref(props.modelValue);

const v$ = useVuelidate({}, { model });

/* watch(
    () => model.value,
    async (val) => {
        emit('update:modelValue', val);
    }
);

watch(
    () => props.modelValue,
    (val) => {
        model.value = val;
    }
); */

const getFrailty = async () => {
    frailty.value = (await dropdownService.getFrailty()).data;
};
const getdiabetesMellitus = async () => {
    diabetesMellitus.value = (await dropdownService.getDiabetesMellitus()).data;
};

onMounted(async () => {
    getFrailty();
    getdiabetesMellitus();
});

defineExpose({ v$ });
</script>
