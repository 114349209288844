import Login from '@/views/auth/Login.vue';
import Register from '@/views/auth/Register.vue';
import HospitalCreate from '@/views/hospital/HospitalCreate.vue';
import Home from '@/views/home/Home.vue';
import RegisteredUsers from '@/views/admin/RegisteredUsers.vue';
import HospitalList from '@/views/hospital/HospitalList.vue';
import HospitalEdit from '@/views/hospital/HospitalEdit.vue';
import HospitalView from '@/views/hospital/HospitalView.vue';
import TaviProcedureCreateDraft from '@/views/tavi/CreateTaviProcedureDraft.vue';
import TaviProcedureEdit from '@/views/tavi/EditTaviProcedure.vue';
import TaviProcedureDraftList from '@/views/tavi/TaviProcedureDraftList.vue';
import TaviProcedureList from '@/views/tavi/TaviProcedureList.vue';
import { patientRoutes } from '@/routes/patient.routes';
import WaitingForApprove from '@/views/auth/WaitingForApprove.vue';
import NotFound from '@/components/notFound/NotFound.vue';
import NomenclaturesManagement from '@/views/admin/NomenclaturesManagement.vue';
import UsersList from '@/views/admin/UsersList.vue';

export const routes = [
    { path: '/', component: Home },
    { path: '/auth/waiting', component: WaitingForApprove },
    { path: '/login', component: Login },
    { path: '/register', component: Register },
    { path: '/hospital/list', component: HospitalList },
    { path: '/hospital/create', component: HospitalCreate },
    { path: '/hospital/edit/:id', component: HospitalEdit },
    { path: '/hospital/display/:id', component: HospitalView },
    { path: '/admin/registeredUsers', component: RegisteredUsers },
    { path: '/admin/users/list', component: UsersList },
    { path: '/admin/nomenclaturesManagement', component: NomenclaturesManagement },
    { path: '/procedure/create/:patientId?', component: TaviProcedureCreateDraft },
    {
        path: '/procedure/edit/:id?/:isDraft?/:disabled?',
        component: TaviProcedureEdit,
        props: true,
    },
    { path: '/procedure/draftList', component: TaviProcedureDraftList },
    { path: '/procedure/list', component: TaviProcedureList },
    { path: '/:pathMatch(.*)*', component: NotFound },
    ...patientRoutes,
];
