export class FilterModel {
    constructor(page = 0,
                take = 10,
                sortField,
                sortOrder) {
        this.page = page;
        this.take = take;
        this.sortField = sortField;
        this.sortOrder = sortOrder;
        this.filters = [];
    }
}
