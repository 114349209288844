<template>
    <p-button
        :severity="severity"
        :disabled="disabled"
        :label="label"
        :class="styleClasses"
        :icon="icon"
        :text="text"
    ></p-button>
</template>

<script setup>
import PButton from 'primevue/button';
import { ref } from "vue";

const props = defineProps({
    label: String,
    cssClass: {
        type: String,
        default: 'bg-primary'
    },
    icon: String,
    disabled: {
        type: Boolean,
        default: false,
    },
    severity: String,
    text: String,
});

const styleClasses = ref(props.cssClass);

if (props.severity) {
    styleClasses.value = props.cssClass.replace('bg-primary', '');
}
</script>
