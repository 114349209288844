<template>
    <div>
        <div>
            <SearchPatientForm v-model="patient" @search="search" />
            <div class="grid">
                <div class="ml-2">
                    <WButton
                        :label="$t('buttons.search')"
                        :disabled="disabledSearchButton"
                        @click="search"
                        icon="pi pi-search"
                    ></WButton>
                </div>
                <WButton
                    class="ml-2"
                    :label="$t('buttons.clear')"
                    severity="danger"
                    icon="pi pi-refresh"
                    @click="clear"
                ></WButton>
            </div>
            <Grid
                v-if="items.length"
                :columns="columns"
                :items="items"
                class="mt-5"
                :loading="isLoading"
                :actionButtons="actionButtons"
            />
        </div>
        <Dialog
            @after-hide="clearUser"
            :header="$t('taviProcedure.createDraft')"
            v-model:visible="showDate"
            modal
            :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ width: '50vw' }"
        >
            <div class="grid">
                <div v-if="chosenUser.firstName" class="col-4">
                    <WInputText
                        :disabled="true"
                        v-model="chosenUser.firstName"
                        :label="t('patient.name')"
                    />
                </div>
                <div v-if="chosenUser.middleName" class="col-4">
                    <WInputText
                        :disabled="true"
                        v-model="chosenUser.middleName"
                        :label="t('patient.middleName')"
                    />
                </div>
                <div v-if="chosenUser.lastName" class="col-4">
                    <WInputText
                        :disabled="true"
                        v-model="chosenUser.lastName"
                        :label="t('patient.lastName')"
                    />
                </div>
            </div>
            <div class="grid">
                <div class="col-4">
                    <WDatePicker
                        v-model="dto.date"
                        :label="t('taviProcedure.date')"
                        :is-required="true"
                    />
                </div>
                <div class="col-4">
                    <WInputMask
                        :disabled="false"
                        :is-required="true"
                        :mask="'99999/9999'"
                        v-model="dto.historyOfDeseaseNumber"
                        :label="t('patient.historyOfDeseaseNumber')"
                    />
                </div>
                <div class="col-4">
                    <WDropdown
                        :disabled="false"
                        :is-required="true"
                        :items="doctors"
                        v-model="dto.doctorId"
                        :label="t('roles.doctor')"
                    />
                </div>
            </div>
            <template #footer>
                <WButton
                    @click="createDraft"
                    :label="$t('buttons.create')"
                    icon="pi pi-save"
                ></WButton>
                <WButton
                    :label="$t('buttons.cancel')"
                    cssClass="p-button-primary"
                    icon="pi pi-times-circle"
                    @click="clearUser"
                    autofocus
                ></WButton>
            </template>
        </Dialog>
    </div>
</template>

<script setup>
import { useVuelidate } from '@vuelidate/core';
import { useToast } from 'primevue/usetoast';
import Dialog from 'primevue/dialog';
import WInputMask from '@/components/wrappers/form/WInputMask.vue';
import WInputText from '@/components/wrappers/form/WInputText.vue';
import WButton from '@/components/wrappers/button/WButton.vue';
import WDatePicker from '@/components/wrappers/dateTime/WDateTimePicker.vue';
import { router } from '@/router/router';
import { useRoute } from 'vue-router';
import Grid from '@/components/wrappers/data/data-table/WItemsDataTable.vue';
import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import SearchPatientForm from '@/components/patient/SearchPatientForm.vue';
import { SearchPatient } from '@/dto/patient/searchPatient';
import { patientService } from '@/services/patient/patient.service';
import { ActionButtonModel } from '@/components/wrappers/data/data-table/models/actionButtonModel';
import { DataTableColumnType } from '@/components/wrappers/data/data-table/models/dataTableColumnType';
import { TaviProcedureCreateDraftDto } from '@/dto/taviProcedure/taviProcedureCreateDraftDto';
import { taviDraftService } from '@/services/tavi/taviDraft.service';
import { userStore } from '@/store/user.store';
import { Roles } from '@/enums/roles';
import { doctorService } from '@/services/doctor/doctor.service';
import WDropdown from '@/components/wrappers/form/WDropdown.vue';

const { t } = useI18n();
const isLoading = ref(false);
const showDate = ref(false);
const patient = ref(new SearchPatient());
const items = ref([]);
const chosenUser = ref(null);
const v$ = useVuelidate();
const toast = useToast();
const dto = ref(new TaviProcedureCreateDraftDto());
const route = useRoute();
const doctors = ref([]);

const selectUser = (item) => {
    chosenUser.value = item;
    dto.value.patientId = chosenUser.value.id;
};

const actionButtons = computed(() => [
    new ActionButtonModel('pi pi-check', t('buttons.choose'), selectUser),
]);

const columns = computed(() => [
    {
        field: 'firstName',
        header: t('patient.name'),
    },
    {
        field: 'middleName',
        header: t('patient.middleName'),
    },
    {
        field: 'lastName',
        header: t('patient.lastName'),
    },
    {
        field: 'personIdentityNumber',
        header: t('patient.identifier'),
    },
    {
        field: 'birthDate',
        header: t('patient.dateOfBirth'),
        dataType: DataTableColumnType.date,
    },
    {
        field: 'genderName',
        header: t('patient.gender'),
    },
]);

const disabledSearchButton = computed(() => {
    if (
        patient.value.firstName ||
        patient.value.lastName ||
        patient.value.middleName ||
        patient.value.personIdentityNumber
    ) {
        return false;
    } else {
        return true;
    }
});

const clearUser = () => {
    showDate.value = false;
    chosenUser.value = null;
    dto.value.date = null;
};

const clearSearchData = () => {
    patient.value.firstName = '';
    patient.value.lastName = '';
    patient.value.middleName = '';
    patient.value.personIdentityNumber = '';
    items.value = [];
};

const search = async () => {
    isLoading.value = true;
    try {
        items.value = (await patientService.getAllByFilterModelCriteria(patient.value)).data.items;
    } catch (err) {
        toast.add({
            severity: 'error',
            summary: t('notifications.error'),
            detail: t('notifications.somethingGoneWrong'),
            life: 2000,
        });
    } finally {
        isLoading.value = false;
    }
};

const createDraft = async () => {
    await v$.value.$validate();
    if (v$.value.$errors.length > 0) {
        return;
    }
    isLoading.value = true;
    try {
        const newRecordId = (await taviDraftService.create(dto.value)).data;
        toast.add({
            severity: 'success',
            summary: t('notifications.success'),
            detail: t('taviProcedure.successfulCreatedProcedure'),
            life: 2000,
        });
        router.push({ path: `/procedure/edit/${newRecordId}/true` });
    } catch (err) {
        toast.add({
            severity: 'error',
            summary: t('notifications.error'),
            detail: t('notifications.somethingGoneWrong'),
            life: 2000,
        });
    } finally {
        clearUser();
        isLoading.value = false;
        showDate.value = false;
    }
};

watch(chosenUser, () => {
    showDate.value = chosenUser.value != null;
});

const clear = () => {
    clearUser();
    clearSearchData();
};

const loadPatientById = async (patientId) => {
    patient.value.id = patientId;
    await search();
    if (items.value.length > 0) {
        const patient = items.value[0];
        selectUser(patient);
    }
};

onMounted(async () => {
    if (userStore().isUserInRole(Roles.Doctor)) {
        doctors.value = (await doctorService.getCurrentUserDoctor()).data;
        if (doctors.value.length === 1) {
            dto.value.doctorId = doctors.value[0].id;
        }
    } else if (userStore().isUserInRole(Roles.HospitalAdmin)) {
        doctors.value = (await doctorService.getHospitalDoctors()).data;
    } else if (userStore().isUserInRole(Roles.SysAdmin)) {
        // TODO: Ако ще са много да се оптимизира
        doctors.value = (await doctorService.getAll()).data;
    }

    const patientId = route.params.patientId;
    if (patientId) {
        await loadPatientById(patientId);
    }
});
</script>
