import { httpService } from '@/services/http.service';

export const dropdownService = {
    async getProcedureStatus() {
        return httpService.get(`/dropdown/procedureStatus`);
    },

    async getPlanTypes() {
        return httpService.get(`/dropdown/GetPlanTypes`);
    },

    async getVascularTechniques() {
        return httpService.get(`/dropdown/GetVascularVascularTechniques`);
    },

    async getVascularAccessSite() {
        return httpService.get(`/dropdown/vascularAccessSite`);
    },

    async getOtherArterialAccessSites() {
        return httpService.get(`/dropdown/otherArterialAccessSites`);
    },

    async getProceduresDuringTAVI() {
        return httpService.get(`/dropdown/proceduresDuringTAVI`);
    },

    async getRapidPacing() {
        return httpService.get(`/dropdown/rapidPacing`);
    },

    async getPersonalIdentityType() {
        return httpService.get(`/dropdown/personalIdentityType`);
    },

    async getFrailty() {
        return httpService.get(`/dropdown/frailty`);
    },

    async getDiabetesMellitus() {
        return httpService.get(`/dropdown/diabetesMellitus`);
    },

    async getGender() {
        return httpService.get(`/dropdown/gender`);
    },

    async getSelectButtonDefaultValue() {
        return httpService.get(`/dropdown/selectButtonDefaultValue`);
    },

    async getP2Y12Inhibitors() {
        return httpService.get(`/dropdown/p2Y12Inhibitor`);
    },

    async getOralAnticoagulants() {
        return httpService.get(`/dropdown/oralAnticoagulants`);
    },

    async getAtrialRhythm() {
        return httpService.get(`/dropdown/atrialRhythm`);
    },

    async getVentricularConduction() {
        return httpService.get(`/dropdown/ventricularConduction`);
    },

    async getAtrioventricularBlock() {
        return httpService.get(`/dropdown/atrioventricularBlock`);
    },

    async getCirculatorySupportTypes() {
        return httpService.get(`/dropdown/GetCirculatorySupportTypes`);
    },

    async getProceduralEvents() {
        return httpService.get(`/dropdown/proceduralEvents`);
    },

    async getSternotomyTypes() {
        return httpService.get(`/dropdown/GetSternotomyTypes`);
    },

    async getSternotomyReasons() {
        return httpService.get(`/dropdown/GetSternotomyReasons`);
    },

    async getVascularComplications() {
        return httpService.get(`/dropdown/vascularComplications`);
    },

    async getDeviceModel() {
        return httpService.get(`/dropdown/deviceModel`);
    },

    async getAnaesthesiaType() {
        return httpService.get(`/dropdown/anaesthesiaType`);
    },

    async getCardiacInterventions() {
        return httpService.get(`/dropdown/cardiacInterventions`);
    },

    async getReinterventionIndications() {
        return httpService.get(`/dropdown/GetReinterventionIndications`);
    },

    async getHeartValves() {
        return httpService.get(`/dropdown/GetHeartValves`);
    },

    async getStroke() {
        return httpService.get(`/dropdown/stroke`);
    },

    async getCardiogenicShockStage() {
        return httpService.get(`/dropdown/cardiogenicShockStage`);
    },

    async getImplantableCardiacDevice() {
        return httpService.get(`/dropdown/implantableCardiacDevice`);
    },

    async getDeathReason() {
        return httpService.get(`/dropdown/deathReason`);
    },

    async getNYHAClass() {
        return httpService.get(`/dropdown/NYHAClass`);
    },

    async getCCSAnginaStatus() {
        return httpService.get(`/dropdown/CCSAnginaStatus`);
    },

    async getLeftVentricularEjectionFraction() {
        return httpService.get(`/dropdown/leftVentricularEjectionFraction`);
    },

    async getDiseaseSeverities(includeTricuspidRegurgitation = false) {
        return httpService.get(`/dropdown/DiseaseSeverities/${includeTricuspidRegurgitation}`);
    },

    async getValveMorphologies() {
        return httpService.get(`/dropdown/GetValveMorphologies`);
    },

    async getBicuspidAorticValveType() {
        return httpService.get(`/dropdown/bicuspidAorticValveType`);
    },

    async getValveCuspFusions() {
        return httpService.get(`/dropdown/ValveCuspFusions`);
    },

    async getValveDiseaseAetiologies() {
        return httpService.get(`/dropdown/GetValveDiseaseAetiologies`);
    },

    async getValveRegurgitationTypes() {
        return httpService.get(`/dropdown/GetValveRegurgitationTypes`);
    },
    async getCoronaryArteryStenosis() {
        return httpService.get(`/dropdown/coronaryArteryStenosis`);
    },
    async getAorticValvePathologies() {
        return httpService.get(`/dropdown/GetAorticValvePathologies`);
    },

    async getAnginaSymptoms() {
        return httpService.get(`/dropdown/GetAnginaSymptoms`);
    },

    async getImplantableCardiacDeviceForPriviousProcedures() {
        return httpService.get(`/dropdown/implantableCardiacDeviceForPriviousProcedures`);
    },

    async getValveProsthesisTypes() {
        return httpService.get(`/dropdown/GetValveProsthesisTypes`);
    },

    async getAllRoles() {
        return httpService.get(`/dropdown/getAllRoles`);
    },

    async getAorticAnnulusCalcifications() {
        return httpService.get(`/dropdown/aorticAnnulusCalcifications`);
    },

    async getVascularClosureTechniques() {
        return httpService.get(`/dropdown/vascularClosureTechniques`);
    },
};
