<template>
    <div class="grid">
        <div class="col-12 md:col-3">
            <WInputText
                v-model="model.personIdentityNumber"
                :label="$t('patientSearch.personIdentityNumber')"
                @keyup.enter="emit('search')"
            ></WInputText>
        </div>
        <div class="col-12 md:col-3">
            <WInputText
                v-model="model.firstName"
                :label="$t('patientSearch.firstName')"
                @keyup.enter="emit('search')"
            ></WInputText>
        </div>
        <div class="col-12 md:col-3">
            <WInputText
                v-model="model.middleName"
                :label="$t('patientSearch.middleName')"
                @keyup.enter="emit('search')"
            ></WInputText>
        </div>
        <div class="col-12 md:col-3">
            <WInputText
                v-model="model.lastName"
                css-class="bg-info"
                :label="$t('patientSearch.lastName')"
                @keyup.enter="emit('search')"
            ></WInputText>
        </div>
    </div>
</template>

<script setup>
import WInputText from '@/components/wrappers/form/WInputText.vue';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { SearchPatient } from '@/dto/patient/searchPatient';

const emit = defineEmits(['change', 'update:modelValue', 'search']);
const props = defineProps({
    modelValue: SearchPatient,
});
const model = ref(props.modelValue);

watch(
    () => model.value,
    (val) => {
        if (val !== props.modelValue) {
            emit('update:modelValue', model.value);
            emit('change', model.value);
        }
    }
);

watch(
    () => props.modelValue,
    (val) => {
        model.value = val;
    }
);
const { t } = useI18n();
</script>
