import { createApp } from 'vue'
import App from './App.vue'
import PrimeVue from "primevue/config"
import { router } from "@/router/router"

import 'primevue/resources/themes/mdc-light-indigo/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import Tooltip from "primevue/tooltip";
import { createPinia } from "pinia"
import i18n from './i18n'
import ToastService from "primevue/toastservice"


const app = createApp(App).use(i18n)
app.use(router)

const pinia = createPinia()
app.use(pinia)

import { bg } from '@/locales/PrimeVue/locales'

app.use(PrimeVue, {
    locale: bg
})
app.use(ToastService)

app.directive('tooltip', Tooltip)

app.mount('#app')
