<template>
    <Dialog
        v-model:visible="showDialog"
        modal
        :header="t('users.editRoles')"
        :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
        :style="{ width: '50vw' }"
        :draggable="false"
        @hide="emit('update:modelValue', showDialog)"
    >
        <div class="mb-3 mt-3" v-if="selectedUser">
            <div class="grid">
                <div class="col-6">
                    <strong>
                        {{ t('patient.name') }}: {{ selectedUser.firstName }}
                        {{ selectedUser.lastName }}
                    </strong>
                </div>
            </div>
            <div class="grid">
                <div class="col-6">
                    <strong>{{ t('email') }}: {{ selectedUser.email }}</strong>
                </div>
            </div>
            <div class="grid">
                <div class="col">
                    <strong>
                        {{ t('registeredUsers.currentRoles') }}: {{ selectedUser.rolesNames }}
                    </strong>
                </div>
            </div>
            <div class="grid mt-3">
                <div class="col-12">
                    <WMultiSelect
                        v-model="editDto.roles"
                        :items="roles"
                        :label="t('registeredUsers.allRoles')"
                    />
                </div>
            </div>
        </div>
        <template #footer>
            <WButton :label="t('buttons.save')" @click="editUserRoles"></WButton>
        </template>
    </Dialog>

    <Dialog
        v-model:visible="showConfirmRemoveApprovedRoleDialog"
        modal
        :header="t('notifications.confirmation')"
        :style="{ width: '25%' }"
        :draggable="false"
    >
        <div class="mb-3 mt-3">
            <div class="grid">
                <div class="col-10">
                    <strong>
                        {{ t('registeredUsers.confirmationMessage') }}
                    </strong>
                </div>
            </div>
        </div>
        <template #footer>
            <WButton
                :label="t('buttons.cancel')"
                css-class=""
                severity="danger"
                @click="cancelOnConfirm"
            ></WButton>
            <WButton :label="t('buttons.save')" @click="editOnConfirm"></WButton>
        </template>
    </Dialog>
</template>

<script setup>
import Dialog from 'primevue/dialog';
import WMultiSelect from '@/components/wrappers/form/WMultiSelect.vue';
import WButton from '@/components/wrappers/button/WButton.vue';
import { dropdownService } from '@/services/dropdown/dropdown.service';
import { registeredUsersService } from '@/services/admin/registeredUsers.service';
import { onMounted, ref, watch } from 'vue';
import { EditRegisteredUserDto } from '@/dto/admin/registeredUsers/editRegisteredUserDto';
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';

const emit = defineEmits(['update:modelValue', 'refreshDataTable']);

const props = defineProps({
    modelValue: Boolean,
    selectedUser: Object,
    roles: {
        type: [],
        required: true,
    },
});

const showDialog = ref(props.modelValue);
const showConfirmRemoveApprovedRoleDialog = ref(false);
const approvedUserExist = ref(true);
const editDto = ref(new EditRegisteredUserDto());
const { t } = useI18n();
const toast = useToast();
const loading = ref(false);

const editOnConfirm = async () => {
    try {
        await registeredUsersService.editRoles(editDto.value);
        emit('refreshDataTable');
        const successMessage = editDto.value.isConfirmed
            ? t('registeredUsers.successfulRolesEditing')
            : 'registeredUsers.successfulRolesEditing';
        toast.add({
            severity: 'success',
            summary: t('notifications.success'),
            detail: successMessage,
            life: 2000,
        });
    } catch (err) {
        toast.add({
            severity: 'error',
            summary: t('notifications.error'),
            detail: t('notifications.somethingGoneWrong'),
            life: 2000,
        });
    } finally {
        loading.value = false;
        showConfirmRemoveApprovedRoleDialog.value = false;
    }
};

const cancelOnConfirm = () => {
    showConfirmRemoveApprovedRoleDialog.value = false;
    showDialog.value = true;
};

const editUserRoles = async () => {
    loading.value = true;
    showDialog.value = false;

    approvedUserExist.value = editDto.value.roles.some((x) => {
        let approvedUser = props.roles.find((x) => x.nameEn === 'ApprovedUser');
        return approvedUser.id === x;
    });

    if (!approvedUserExist.value) {
        showConfirmRemoveApprovedRoleDialog.value = true;
        loading.value = false;
    } else {
        editOnConfirm();
    }
};

watch(
    () => props.modelValue,
    () => {
        showDialog.value = props.modelValue;
    }
);

watch(
    () => props.selectedUser,
    () => {
        if (props.selectedUser) {
            editDto.value.id = props.selectedUser.regsteredUserId;
            editDto.value.isConfirmed = true;
            editDto.value.roles = props.selectedUser.roles;
            editDto.value.email = props.selectedUser.email;
            editDto.value.firstName = props.selectedUser.firstName;
            editDto.value.lastName = props.selectedUser.lastName;
        } else {
            editDto.value.id = '';
            editDto.value.isConfirmed = false;
            editDto.value.roles = [];
            editDto.value.email = '';
            editDto.value.firstName = '';
            editDto.value.lastName = '';
        }
    }
);
</script>
