<template>
    <p-input-number
        v-model="inputValue"
        :inputId="inputId"
        :class="cssClass"
        :max="max"
        :min="min"
        :showButtons="showButtons"
        type="number"
        :mode="mode"
        @update:modelValue="$emit('update:modelValue', inputValue)"
    ></p-input-number>
</template>

<script setup>
import PInputNumber from 'primevue/inputnumber';
import { ref, watch } from 'vue';

const props = defineProps({
    modelValue: Number,
    cssClass: String,
    min: Number,
    max: Number,
    mode: String,
    inputId: {
        type: String,
        required: false,
    },
    showButtons: {
        type: Boolean,
        default: false,
    },
});

const inputValue = ref(props.modelValue);

watch(
    () => props.modelValue,
    (val) => {
        inputValue.value = val;
    }
);
</script>
