<template>
    <DataTable
        v-model:filters="filters"
        :value="items"
        :loading="loading"
        :paginator="true"
        :total-records="totalRecordsCount"
        :rows="10"
        :row-hover="true"
        :rowsPerPageOptions="[10, 20, 50]"
        :lazy="true"
        filter-display="menu"
        show-gridlines
        @filter="onFilter($event)"
        @sort="onSort($event)"
        @page="onPageChanged($event)"
    >
        <template #empty>
            {{ $t('dataTable.noItemsFound') }}
        </template>
        <Column
            v-for="col of columns"
            :field="col.field"
            :header="col.header"
            :key="col.field"
            :data-type="col.dataType"
            :sortable="col.sortable"
        >
            <template v-if="col.filterable" #filter="{ filterModel }">
                <div v-if="col.dataType === DataTableColumnType.date">
                    <WDateTimePicker v-model="filterModel.value"></WDateTimePicker>
                </div>
                <div v-else-if="col.dataType === DataTableColumnType.text">
                    <WInputText v-model="filterModel.value"></WInputText>
                </div>
                <div v-else-if="col.dataType === DataTableColumnType.number">
                    <WInputNumber v-model="filterModel.value"></WInputNumber>
                </div>
                <div v-else-if="col.dataType === DataTableColumnType.boolean">
                    <WToggleButton v-model="filterModel.value"></WToggleButton>
                </div>
            </template>
            <template #filterclear="{ filterCallback }">
                <WButton
                    icon="pi pi-times"
                    css-class="p-button-secondary"
                    @click="filterCallback()"
                ></WButton>
            </template>
            <template #filterapply="{ filterCallback }">
                <WButton
                    icon="pi pi-check"
                    css-class="p-button-success"
                    @click="filterCallback()"
                ></WButton>
            </template>
            <template #body="{ data }">
                <span v-if="col.dataType === DataTableColumnType.date">
                    {{ Formatters.date(data[col.field]) }}
                </span>
                <span v-else-if="col.dataType === DataTableColumnType.dateTime">
                    {{ Formatters.dateTime(data[col.field]) }}
                </span>
                <span v-else-if="col.dataType === DataTableColumnType.boolean">
                    <Tag v-if="data[col.field]" severity="success">{{ $t('dataTable.yes') }}</Tag>
                    <Tag v-else class="bg-error" severity="danger">{{ $t('dataTable.no') }}</Tag>
                </span>
                <span v-else>{{ data[col.field] }}</span>
            </template>
        </Column>
        <Column v-if="actionButtons" class="buttons-cell">
            <!-- :header="'Действия'" -->
            <template #body="slotProps">
                <WButton
                    v-for="button in actionButtons"
                    v-show="button.isVisible"
                    v-tooltip.left="button.tooltip"
                    :key="button.text"
                    :icon="button.icon"
                    :severity="button.color"
                    :css-class="`p-button-text`"
                    @click="button.callback(slotProps.data)"
                    :disabled="slotProps.data.haveAnyProcedures && button.icon === 'pi pi-trash'"
                ></WButton>
            </template>
        </Column>
    </DataTable>
</template>

<script setup>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { onMounted, ref } from 'vue';
import { dataTaleService } from '@/components/wrappers/data/data-table/services/dataTable.service';
import { FilterModel } from '@/components/wrappers/data/data-table/models/filterModel';
import WButton from '@/components/wrappers/button/WButton.vue';
import WDateTimePicker from '@/components/wrappers/dateTime/WDateTimePicker.vue';
import WInputText from '@/components/wrappers/form/WInputText.vue';
import WInputNumber from '@/components/wrappers/form/WInputNumber.vue';
import { DataTableColumnType } from '@/components/wrappers/data/data-table/models/dataTableColumnType';
import { Formatters } from '@/components/wrappers/data/data-table/utils/formatters';
import WToggleButton from '@/components/wrappers/button/WToggleButton.vue';
import Tag from 'primevue/tag';

const props = defineProps({
    columns: Array,
    apiUrl: String,
    actionButtons: Array,
});

const loading = ref(false);
let items = ref([]);
let totalRecordsCount = ref(0);

const filters = ref({});

for (const col of props.columns) {
    if (col.filterable) {
        switch (col.dataType) {
            case DataTableColumnType.date:
                Object.assign(filters.value, {
                    [col.field]: {
                        operator: FilterOperator.AND,
                        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
                    },
                });
                break;
            case DataTableColumnType.text:
                Object.assign(filters.value, {
                    [col.field]: {
                        operator: FilterOperator.AND,
                        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
                    },
                });
                break;
            case DataTableColumnType.number:
                Object.assign(filters.value, {
                    [col.field]: {
                        operator: FilterOperator.AND,
                        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
                    },
                });
                break;
            case DataTableColumnType.boolean:
                Object.assign(filters.value, {
                    [col.field]: {
                        operator: FilterOperator.AND,
                        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
                    },
                });
                break;
        }
    }
}

const filter = new FilterModel();

onMounted(async () => {
    loadData();
});

const onPageChanged = async (event) => {
    filter.page = event.page;
    filter.take = event.rows;
    loadData();
};

const onSort = async (event) => {
    filter.sortField = event.sortField;
    filter.sortOrder = event.sortOrder;
    await loadData();
};

const onFilter = async (event) => {
    const filters = [];
    if (event.filters) {
        for (const field of Object.keys(event.filters)) {
            const filterValue = event.filters[field];
            if (filterValue && filterValue.constraints) {
                const constraints = filterValue.constraints.map((constraint) => {
                    return {
                        value: constraint.value,
                        matchMode: constraint.matchMode,
                    };
                });

                if (constraints[0].value) {
                    const column = props.columns.find((column) => column.field === field);
                    const filter = {
                        field: field,
                        constraints: constraints,
                        dataType: column.dataType, //filterValue.constraints[0].value.constructor
                    };
                    filters.push(filter);
                }
            }
        }
    }
    filter.filters = filters;
    loadData();
};

const loadData = async () => {
    loading.value = true;
    const result = await dataTaleService.getFiltered(props.apiUrl, filter);
    items.value = result.data.items;
    totalRecordsCount = result.data.count;
    loading.value = false;
};

const refresh = async () => {
    await loadData();
};

defineExpose({ refresh });
</script>
<style>
.buttons-cell[role='cell'] {
    display: flex;
}
</style>
