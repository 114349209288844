export const CardiacInterventions = {
    No: 1,
    RedoTAVI: 2,
    BalloonAorticValvuloplastyAfterTAVI: 3,
    OtherTranscatheterHeartValveProcedure: 4,
    Pci: 5,
    SurgicalAorticValveReplacement: 6,
    OtherHeartValveSurgery: 7,
    Cabg: 8,
    OtherInterventionInvolvingSternotomyThoracotomy: 9,
    Other: 10,
    Unknown: 11
};
