export class PreviousProcedureDto {
    constructor(obj) {
        this.dateForSurgicalReplacement = obj.dateForSurgicalReplacement;
        this.cardiacInterventionsId = obj.cardiacInterventionsId;
        this.valveProsthesisTypeId = obj.valveProsthesisTypeId;
        this.hasTaviId = obj.hasTaviId;
        this.date = obj.date;
        this.otherTranscatheterValveInterventionId = obj.otherTranscatheterValveInterventionId;
        this.priorImplantableCardiacDeviceId = obj.priorImplantableCardiacDeviceId;
        this.implantableCardiacDevices = obj.implantableCardiacDevices;
        this.otherTranscatheterValves = obj.otherTranscatheterValves;
        this.cardiacInterventions = obj.cardiacInterventions;
        this.valves = obj.valves;
    }
}
