import { localeStore } from '@/store/locale.store';

export const Formatters = {
    date(date) {
        if (date) {
            const locale = localeStore().currentLocale
            return new Date(date).toLocaleDateString(locale);
        }
    },
    dateTime(date) {
        if (date) {
            return `${ this.date(date) } ${ new Date(date).toLocaleTimeString(
                localeStore().currentLocale
            ) }`;
        }
    }
};
