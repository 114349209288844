<template>
    <div>
        <div class="grid formgrid">
            <div class="col-12 md:col-4 field">
                <WDatePicker
                    :disabled="disabled"
                    v-model="model.date"
                    :label="t('followUp.date')"
                />
            </div>

            <div class="col-12 md:col-4 field">
                <WMultiSelect
                    :disabled="disabled"
                    :items="anginaSymptoms"
                    v-model="model.anginaSymptoms"
                    :is-required="true"
                    optionLabel="nameBg"
                    :label="t('council.symptoms')"
                />
            </div>
            <div class="col-12 md:col-4 field">
                <WDropdown
                    :disabled="disabled"
                    v-model="model.nyhaid"
                    :items="nyhaClass"
                    :label="t('council.nyha')"
                    :is-required="true"
                ></WDropdown>
            </div>
        </div>

        <div class="grid">
            <div class="col-12 md:col-4 field">
                <WSelectButton
                    :disabled="disabled"
                    v-model="model.preProceduralCirculatorySupportId"
                    :items="selectButtonItems"
                    :label="t('council.preProceduralCirculatorySupport')"
                />
            </div>
            <div
                v-if="model.preProceduralCirculatorySupportId === SelectButtonValues.Yes"
                class="col-12 md:col-4 field"
            >
                <WMultiSelect
                    :disabled="disabled"
                    v-model="model.circulatorySupportTypes"
                    :items="circulatorySupportTypes"
                    optionLabel="nameBg"
                    :label="t('council.preProceduralCirculatorySupport')"
                />
            </div>
            <div class="col-12 md:col-4 field">
                <WSelectButton
                    :disabled="disabled"
                    v-model="model.activeEndocarditisId"
                    :items="selectButtonItems"
                    :label="t('council.activeEndocarditis')"
                />
            </div>
            <div
                v-if="model.activeEndocarditisId === SelectButtonValues.Yes"
                class="col-12 md:col-4 field"
            >
                <WMultiSelect
                    :disabled="disabled"
                    v-model="model.implantableCardiacDevices"
                    :items="implantableCardiacDevices"
                    optionLabel="nameBg"
                    :label="t('council.activeEndocarditis')"
                />
            </div>
        </div>
        <div class="grid">
            <div class="col-12 md:col-6 field">
                <WInputNumberCheckbox
                    :disabled="disabled"
                    v-model="model.stsriskScore"
                    :showCheckBox="false"
                    :label="t('council.sTSRiskScore')"
                />
            </div>
<!--            <div class="col-12 md:col-4 field">
                <WInputNumberCheckbox
                    :disabled="disabled"
                    v-model="model.euroScore"
                    :showCheckBox="false"
                    :label="t('council.euroScore')"
                />
            </div>-->
            <div class="col-12 md:col-6 field">
                <WInputNumberCheckbox
                    :disabled="disabled"
                    v-model="model.euroScoreIi"
                    :is-required="true"
                    :showCheckBox="false"
                    :label="t('council.euroScoreII')"
                />
            </div>
        </div>
        <div class="grid">
            <div class="col-12 field">
                <WTextArea
                    :disabled="disabled"
                    v-model="model.conclusion"
                    :label="t('council.conclusion')"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import WInputNumberCheckbox from '@/components/wrappers/form/WInputNumberCheckbox.vue';
import WMultiSelect from '@/components/wrappers/form/WMultiSelect.vue';
import WSelectButton from '@/components/wrappers/button/WSelectButton.vue';
import WDropdown from '@/components/wrappers/form/WDropdown.vue';
import WTextArea from '@/components/wrappers/form/WTextArea.vue';
import { dropdownService } from '@/services/dropdown/dropdown.service';
import { ref, onMounted, watch } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { useI18n } from 'vue-i18n';
import WDatePicker from '@/components/wrappers/dateTime/WDateTimePicker.vue';
import { CouncilDto } from '@/dto/council/councilDto';
import { SelectButtonValues } from '@/enums/selectButtonValues';

/* const emit = defineEmits(['update:modelValue']); */

const props = defineProps({
    modelValue: CouncilDto,
    disabled: {
        type: Boolean,
        default: false,
    },
    selectButtonItems: Array,
    nyhaClass: Array,
    circulatorySupportTypes: Array,
    implantableCardiacDevices: Array,
});

const { t } = useI18n();
const v$ = useVuelidate();
const model = ref(props.modelValue);
const anginaSymptoms = ref([]);

/* watch(
    () => model.value,
    (val) => {
        emit('update:modelValue', val);
    }
);

watch(
    () => props.modelValue,
    (val) => {
        model.value = val;
    }
); */

watch(
    () => model.value.preProceduralCirculatorySupportId,
    (val) => {
        if (val !== SelectButtonValues.Yes) model.value.circulatorySupportTypes = null;
    }
);

watch(
    () => model.value.activeEndocarditisId,
    (val) => {
        if (val !== SelectButtonValues.Yes) model.value.implantableCardiacDevices = null;
    }
);

const getAnginaSymptoms = async () => {
    anginaSymptoms.value = (await dropdownService.getAnginaSymptoms()).data;
};

onMounted(async () => {
    getAnginaSymptoms();
});

defineExpose({ v$ });
</script>
