<template>
    <div v-if="taviProcedureModel">
        <div class="grid">
            <p v-if="patientInfoModel.firstName">
                <span class="col-1" />
                <b class="text-4xl">
                    {{
                        `${patientInfoModel.firstName} ${patientInfoModel.middleName} ${patientInfoModel.lastName}`
                    }}
                </b>
            </p>
        </div>
        <div>
            <div class="card">
                <TabView :scrollable="true">
                    <TabPanel v-if="patientInfoModel">
                        <template #header>
                            <TabPanelHeader
                                :title="t('patientSearch.patientInfo')"
                            ></TabPanelHeader>
                        </template>
                        <TAVIPatientInfo :model="patientInfoModel" />
                    </TabPanel>
                    <TabPanel v-if="taviProcedureModel.generalPatientClinicalData">
                        <template #header>
                            <!-- v$.$dirty &&  -->
                            <TabPanelHeader
                                :title="t('patientCharacteristics.generalPatientClinicalData')"
                                :badge="
                                    generalPatientClinicalData
                                        ? generalPatientClinicalData.v$.$errors.length
                                        : 0
                                "
                            ></TabPanelHeader>
                        </template>
                        <GeneralPatientClinicalData
                            ref="generalPatientClinicalData"
                            :disabled="disableForm"
                            v-model="taviProcedureModel.generalPatientClinicalData"
                            :selectButtonItems="selectButtonItems"
                        />
                    </TabPanel>
                    <TabPanel v-if="taviProcedureModel.previousProcedure">
                        <template #header>
                            <TabPanelHeader
                                :title="t('previousProcedures.previousProceduresTitle')"
                                :badge="previousProcedure ? previousProcedure.v$.$errors.length : 0"
                            ></TabPanelHeader>
                        </template>
                        <PreviousProcedure
                            ref="previousProcedure"
                            :disabled="disableForm"
                            v-model="taviProcedureModel.previousProcedure"
                            :selectButtonItems="selectButtonItems"
                            :cardiacInterventions="cardiacInterventions"
                            :heartValves="heartValves"
                        />
                    </TabPanel>
                    <TabPanel v-if="taviProcedureModel.proceduralDiagnostic">
                        <template #header>
                            <TabPanelHeader
                                :title="t('taviProcedure.proceduralDiagnostics')"
                                :badge="
                                    proceduralDiagnostics
                                        ? proceduralDiagnostics.v$.$errors.length
                                        : 0
                                "
                            ></TabPanelHeader>
                        </template>
                        <ProceduralDiagnostics
                            ref="proceduralDiagnostics"
                            :disabled="disableForm"
                            v-model="taviProcedureModel.proceduralDiagnostic"
                            :hasCabg="containsCabg"
                            :selectButtonItems="selectButtonItems"
                            :diseaseSeverities="diseaseSeverities"
                            :leftVentricularEjectionFraction="leftVentricularEjectionFraction"
                            :atrialRhythm="atrialRhythm"
                            :ventricularConduction="ventricularConduction"
                            :atrioventricularBlock="atrioventricularBlock"
                        />
                    </TabPanel>
                    <TabPanel v-if="taviProcedureModel.council">
                        <template #header>
                            <TabPanelHeader
                                :title="t('council.councilTitle')"
                                :badge="council ? council.v$.$errors.length : 0"
                            ></TabPanelHeader>
                        </template>
                        <Council
                            ref="council"
                            :disabled="disableForm"
                            v-model="taviProcedureModel.council"
                            :selectButtonItems="selectButtonItems"
                            :nyhaClass="nyhaClass"
                            :circulatorySupportTypes="circulatorySupportTypes"
                            :implantableCardiacDevices="implantableCardiacDevices"
                        />
                    </TabPanel>
                    <TabPanel v-if="taviProcedureModel.tavi">
                        <template #header>
                            <TabPanelHeader
                                :title="t('taviProcedure.tavi')"
                                :badge="
                                    createTAVIProcedureForm
                                        ? createTAVIProcedureForm.v$.$errors.length
                                        : 0
                                "
                            ></TabPanelHeader>
                        </template>
                        <CreateTAVIProcedureForm
                            ref="createTAVIProcedureForm"
                            :disabled="disableForm"
                            v-model="taviProcedureModel.tavi"
                            :selectButtonItems="selectButtonItems"
                            :aorticValvePathologies="aorticValvePathologies"
                            :circulatorySupportTypes="circulatorySupportTypes"
                        />
                    </TabPanel>
                    <TabPanel v-if="taviProcedureModel.procedureComplications">
                        <template #header>
                            <TabPanelHeader
                                :title="t('taviProcedure.procedureComplication')"
                                :badge="
                                    procedureComplication
                                        ? procedureComplication.v$.$errors.length
                                        : 0
                                "
                            ></TabPanelHeader>
                        </template>
                        <ProcedureComplication
                            ref="procedureComplication"
                            :disabled="disableForm"
                            v-model="taviProcedureModel.procedureComplications"
                            :selectButtonItems="selectButtonItems"
                            :cardiacInterventions="cardiacInterventions"
                            :reinterventionIndications="reinterventionIndications"
                            :implantableCardiacDevices="implantableCardiacDevices"
                            :heartValves="heartValves"
                        />
                    </TabPanel>
                    <TabPanel v-if="taviProcedureModel.dischargeDetails">
                        <template #header>
                            <TabPanelHeader
                                :title="t('dischargeData.dischargeData')"
                                :badge="dischargeData ? dischargeData.v$.$errors.length : 0"
                            ></TabPanelHeader>
                        </template>
                        <DischargeDetails
                            ref="dischargeData"
                            :disabled="disableForm"
                            v-model="taviProcedureModel.dischargeDetails"
                            :selectButtonItems="selectButtonItems"
                            :diseaseSeverities="diseaseSeverities"
                            :atrialRhythm="atrialRhythm"
                            :ventricularConduction="ventricularConduction"
                            :atrioventricularBlock="atrioventricularBlock"
                        />
                    </TabPanel>
                    <TabPanel v-if="taviProcedureModel.followUpExams && props.isDraft == 'false'">
                        <template #header>
                            <TabPanelHeader
                                :title="t('followUp.title')"
                                :badge="followUps ? followUps.v$.$errors.length : 0"
                            ></TabPanelHeader>
                        </template>
                        <FollowUps
                            ref="followUps"
                            :disabled="disableForm"
                            :items="taviProcedureModel.followUpExams"
                            :aorticValvePathologies="aorticValvePathologies"
                            :diseaseSeverities="diseaseSeverities"
                            :cardiacInterventions="cardiacInterventions"
                            :reinterventionIndications="reinterventionIndications"
                            :leftVentricularEjectionFraction="leftVentricularEjectionFraction"
                            :nyhaClass="nyhaClass"
                        />
                    </TabPanel>
                </TabView>
            </div>
            <div>
                <WButton
                    v-if="props.isDraft == 'true'"
                    class="mr-4"
                    :label="$t('buttons.createDraft')"
                    icon="pi pi-pencil"
                    @click="updateDraft"
                ></WButton>
                <WButton
                    :label="$t('buttons.save')"
                    icon="pi pi-save"
                    @click="saveTAVIProcedure"
                ></WButton>
            </div>
        </div>
    </div>
    <WLoader :is-loading="isLoading"></WLoader>
</template>

<script setup>
import DischargeDetails from '@/components/tavi/DischargeDetails.vue';
import WLoader from '@/components/wrappers/loaderSpinner/WLoader.vue';
import WButton from '@/components/wrappers/button/WButton.vue';
import CreateTAVIProcedureForm from '@/components/tavi/CreateTAVIProcedureForm.vue';
import GeneralPatientClinicalData from '@/components/tavi/GeneralPatientClinicalData.vue';
import TAVIPatientInfo from '@/components/tavi/TaviPatientInfo.vue';
import { ref, onMounted, computed } from 'vue';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import TabPanelHeader from '@/components/wrappers/panels/TabPanelHeader.vue';
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';
import { useRoute } from 'vue-router';
import { TaviProcedureEditDraftDto } from '@/dto/taviProcedure/taviProcedureEditDraftDto';
import { TaviProcedureDto } from '@/dto/taviProcedure/taviProcedureCreatDto';
import { PatientViewDto } from '@/dto/patient/patientViewDto';
import { patientService } from '@/services/patient/patient.service';
import ProcedureComplication from '@/components/tavi/ProcedureComplication.vue';
import ProceduralDiagnostics from '@/components/tavi/ProceduralDiagnostics.vue';
import Council from '@/components/tavi/Council.vue';
import { router } from '@/router/router';
import PreviousProcedure from '@/components/tavi/PreviousProcedure.vue';
import { taviDraftService } from '@/services/tavi/taviDraft.service';
import { useVuelidate } from '@vuelidate/core';
import { taviService } from '@/services/tavi/tavi.service';
import { CardiacInterventions } from '@/enums/cardiacInterventions';
import { dropdownService } from '@/services/dropdown/dropdown.service';
import FollowUps from '@/components/tavi/FollowUps.vue';

const toast = useToast();
const { t } = useI18n();
const route = useRoute();
const isLoading = ref(false);
const draftModel = ref(new TaviProcedureEditDraftDto());
const patientInfoModel = ref(new PatientViewDto());
const taviProcedureModel = ref(null);
const disableForm = ref(false);
const selectButtonItems = ref([]);
const aorticValvePathologies = ref([]);
const diseaseSeverities = ref([]);
const cardiacInterventions = ref([]);
const reinterventionIndications = ref([]);
const leftVentricularEjectionFraction = ref([]);
const nyhaClass = ref([]);
const circulatorySupportTypes = ref([]);
const implantableCardiacDevices = ref([]);
const atrialRhythm = ref([]);
const ventricularConduction = ref([]);
const atrioventricularBlock = ref([]);
const heartValves = ref([]);

const v$ = useVuelidate({}, { taviProcedureModel });

const createTAVIProcedureForm = ref(null);
const generalPatientClinicalData = ref(null);
const dischargeData = ref(null);
const procedureComplication = ref(null);
const proceduralDiagnostics = ref(null);
const council = ref(null);
const followUps = ref(null);
const previousProcedure = ref(null);

const props = defineProps({
    id: {
        type: Number,
    },
    isDraft: {
        type: Boolean,
    },
    disabled: {
        type: Boolean,
    },
});

const getPatientInfo = async (patientId) => {
    try {
        patientInfoModel.value = (await patientService.getById(patientId)).data;
    } catch (err) {
        toast.add({
            severity: 'error',
            summary: t('notifications.error'),
            detail: t('notifications.somethingGoneWrong'), // TODO:
            life: 2000,
        });
    }
};

const getDraftModel = async (id) => {
    isLoading.value = true;
    try {
        const savedDraft = (await taviDraftService.getById(id)).data;
        draftModel.value = new TaviProcedureEditDraftDto(
            savedDraft.id,
            savedDraft.date,
            savedDraft.patientId,
            savedDraft.objectContent,
            savedDraft.createdBy,
            savedDraft.historyOfDeseaseNumber,
            savedDraft.doctorId
        );

        if (draftModel.value.objectContent) {
            taviProcedureModel.value = new TaviProcedureDto(
                JSON.parse(draftModel.value.objectContent)
            );
        } else {
            taviProcedureModel.value = new TaviProcedureDto({});
        }
        taviProcedureModel.value.doctorId = draftModel.value.doctorId;
        taviProcedureModel.value.date = draftModel.value.date;
        taviProcedureModel.value.patientId = draftModel.value.patientId;
    } catch (err) {
        toast.add({
            severity: 'error',
            summary: t('notifications.error'),
            detail: t('notifications.somethingGoneWrong'),
            life: 3000,
        });
    } finally {
        isLoading.value = false;
        if (draftModel.value.patientId) {
            getPatientInfo(draftModel.value.patientId);
        }
    }
};

const updateDraft = async () => {
    try {
        draftModel.value.objectContent = JSON.stringify(taviProcedureModel.value);
        const result = await taviDraftService.edit(draftModel.value);
        if (result.status === 200)
            toast.add({
                severity: 'success',
                summary: t('notifications.success'),
                detail: t('notifications.successfulEdit'),
                life: 2000,
            });
    } catch (err) {
        toast.add({
            severity: 'error',
            summary: t('notifications.error'),
            detail: t('notifications.somethingGoneWrong'),
            life: 2000,
        });
    } finally {
        isLoading.value = false;
    }
};

const getTaviProcedure = async (id) => {
    isLoading.value = true;
    try {
        const taviProcedureRaw = await taviService.getById(id);
        taviProcedureModel.value = new TaviProcedureDto(taviProcedureRaw.data);
        getPatientInfo(taviProcedureModel.value.patientId);
    } catch (err) {
        toast.add({
            severity: 'error',
            summary: t('notifications.error'),
            detail: t('notifications.somethingGoneWrong'), // TODO:
            life: 3000,
        });
    } finally {
        isLoading.value = false;
        if (draftModel.value.patientId) {
            getPatientInfo(draftModel.value.patientId);
        }
    }
};

const saveTAVIProcedure = async () => {
    await v$.value.$validate();
    if (v$.value.$errors.length > 0) {
        toast.add({
            severity: 'error',
            summary: t('notifications.validationErrors'),
            detail: t('notifications.validationErrorsDetail', { count: v$.value.$errors.length }),
            life: 2000,
        });
        return;
    }
    // TODO: да направя този параметър boolean
    if (props.isDraft == 'true') {
        try {
            isLoading.value = true;
            await taviService.create(taviProcedureModel.value);
            toast.add({
                severity: 'success',
                summary: t('notifications.success'),
                detail: t('notifications.successfulSave'),
                life: 2000,
            });
            router.push('/procedure/list');
        } catch {
            toast.add({
                severity: 'error',
                summary: t('notifications.error'),
                detail: t('notifications.errorCreate'),
                life: 2000,
            });
        } finally {
            isLoading.value = false;
        }
    } else {
        isLoading.value = true;
        try {
            await taviService.edit(taviProcedureModel.value);
            toast.add({
                severity: 'success',
                summary: t('notifications.success'),
                detail: t('notifications.successfulSave'),
                life: 2000,
            });
        } catch {
            toast.add({
                severity: 'error',
                summary: t('notifications.error'),
                detail: t('notifications.errorEdit'),
                life: 2000,
            });
        } finally {
            isLoading.value = false;
        }
    }
};

const containsCabg = computed(() => {
    return taviProcedureModel.value.previousProcedure.cardiacInterventions?.some(
        (x) => x === CardiacInterventions.Cabg
    );
});

const getSelectButtonItems = async () => {
    selectButtonItems.value = (await dropdownService.getSelectButtonDefaultValue()).data;
};

const getAorticValvePathologies = async () => {
    aorticValvePathologies.value = (await dropdownService.getAorticValvePathologies()).data;
};

const getDiseaseSeverities = async () => {
    diseaseSeverities.value = (await dropdownService.getDiseaseSeverities()).data;
};

const getCardiacInterventions = async () => {
    cardiacInterventions.value = (await dropdownService.getCardiacInterventions()).data;
};

const getReinterventionIndications = async () => {
    reinterventionIndications.value = (await dropdownService.getReinterventionIndications()).data;
};

const getLeftVentricularEjectionFraction = async () => {
    leftVentricularEjectionFraction.value = (
        await dropdownService.getLeftVentricularEjectionFraction()
    ).data;
};

const getNyhaClass = async () => {
    nyhaClass.value = (await dropdownService.getNYHAClass()).data;
};

const getCirculatorySupportTypes = async () => {
    circulatorySupportTypes.value = (await dropdownService.getCirculatorySupportTypes()).data;
};

const getImplantableCardiacDevice = async () => {
    implantableCardiacDevices.value = (await dropdownService.getImplantableCardiacDevice()).data;
};

const getAtrialRhythm = async () => {
    atrialRhythm.value = (await dropdownService.getAtrialRhythm()).data;
};

const getVentricularConduction = async () => {
    ventricularConduction.value = (await dropdownService.getVentricularConduction()).data;
};

const getAtrioventricularBlock = async () => {
    atrioventricularBlock.value = (await dropdownService.getAtrioventricularBlock()).data;
};

const getHeartValves = async () => {
    heartValves.value = (await dropdownService.getHeartValves()).data;
};

onMounted(async () => {
    disableForm.value = props.disabled == 'true';
    if (props.id && props.isDraft == 'true') {
        getDraftModel(props.id);
    } else if (props.id && props.isDraft === 'false') {
        getTaviProcedure(props.id);
    } else {
        // TODO: '/'?
        router.push({ path: `/` });
    }

    getSelectButtonItems();
    getAorticValvePathologies();
    getDiseaseSeverities();
    getCardiacInterventions();
    getReinterventionIndications();
    getLeftVentricularEjectionFraction();
    getNyhaClass();
    getCirculatorySupportTypes();
    getImplantableCardiacDevice();
    getAtrialRhythm();
    getVentricularConduction();
    getAtrioventricularBlock();
    getHeartValves();
});
</script>
