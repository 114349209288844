<template>
    <div :style="expand">
        <Checkbox
            v-model="model"
            class="p-checkbox-box-primary"
            :binary="true"
            :disabled="disabled"
            @click.stop="toggleCheckbox"
        />
        <label :class="{ 'p-error': v$.model.$invalid && v$.$dirty }">{{ label }}</label>
        <small v-if="v$.model.$errors.length > 0" class="p-error">
            {{ v$.model.$errors[0].$message }}
        </small>
    </div>
</template>

<script setup>
import Checkbox from 'primevue/checkbox';
import { computed, ref, watch } from 'vue';
import { required } from '@/validation/validators';
import { useVuelidate } from '@vuelidate/core';

const emit = defineEmits(['change', 'update:modelValue']);

const props = defineProps({
    disabled: {
        type: Boolean,
        default: false,
    },
    label: String,
    modelValue: {
        type: Boolean,
        required: false,
        default: false,
    },
    isRequired: {
        type: Boolean,
        default: false,
    },
});

const model = ref(props.modelValue);

const toggleCheckbox = () => (model.value = !model.value);

const expand = computed(() => {
    return props.modelValue
        ? {
              width: '100vw',
          }
        : null;
});

const rules = {
    model: {},
};

if (props.isRequired) {
    Object.assign(rules.model, { required });
}

const v$ = useVuelidate(rules, { model });

watch(
    () => model.value,
    (val) => {
        if (val !== props.modelValue) {
            emit('update:modelValue', model.value);
            emit('change', model.value);
        }
    }
);
watch(
    () => props.modelValue,
    (val) => {
        if (val !== model.value) {
            model.value = val;
        }
    }
);
</script>
