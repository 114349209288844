<template>
    <div v-if="dto">
        <WInputText
            v-model="dto.name"
            :is-required="true"
            :disabled="true"
            :label="t('hospital.name')"
        ></WInputText>

        <WInputText
            v-model="dto.rhiNumber"
            :is-required="true"
            :disabled="true"
            :exact-length="10"
            :label="t('hospital.rhiNumber')"
        ></WInputText>

        <WAddress v-model="dto.address" :is-required="true" :disabled="true"></WAddress>

        <ContactPerson v-model="dto.contactPerson" :disabled="true"></ContactPerson>

        <h2>{{ $t('hospital.users') }}</h2>
        <WDataTable :columns="columns" :api-url="apiUrl" v-if="apiUrl"></WDataTable>
    </div>
    <WLoader :isLoading="loading"></WLoader>
</template>

<script setup>
import WLoader from '@/components/wrappers/loaderSpinner/WLoader.vue';
import WDataTable from '@/components/wrappers/data/data-table/WDataTable.vue';
import { DataTableColumnType } from '@/components/wrappers/data/data-table/models/dataTableColumnType';
import WInputText from '@/components/wrappers/form/WInputText.vue';
import WAddress from '@/components/wrappers/address/WAddress.vue';
import ContactPerson from '@/components/person/ContactPerson.vue';
import { useRoute } from 'vue-router';
import { onMounted, ref, computed } from 'vue';
import { hospitalService } from '@/services/hospital/hospital.service';
import { HospitalDto } from '@/dto/hospital/hospitalDto';
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';

const { t } = useI18n();
const toast = useToast();
const route = useRoute();
const hospitalId = route.params.id;
const dto = ref();
const loading = ref(false);
const apiUrl = computed(() => `/Hospital/GetAllUsers/${hospitalId}`);

const getHospitalData = async () => {
    try {
        loading.value = true;
        const hospital = (await hospitalService.getById(hospitalId)).data;
        dto.value = new HospitalDto(hospital);
    } catch {
        toast.add({
            severity: 'error',
            summary: t('notifications.error'),
            detail: t('notifications.somethingGoneWrong'),
            life: 2000,
        });
    } finally {
        loading.value = false;
    }
};

const columns = computed(() => [
    {
        field: 'firstName',
        header: t('person.firstName'),
        dataType: DataTableColumnType.text,
        sortable: true,
        filterable: true,
    },
    {
        field: 'lastName',
        header: t('person.lastName'),
        dataType: DataTableColumnType.text,
        sortable: true,
        filterable: true,
    },
    { field: 'email', header: t('email'), dataType: DataTableColumnType.text, filterable: true },
    {
        field: 'rolesNames',
        header: t('roles.name'),
        sortable: true,
        dataType: DataTableColumnType.text,
        filterable: true,
    },
    {
        field: 'createdDate',
        header: t('registeredUsers.date'),
        dataType: DataTableColumnType.date,
        sortable: true,
        filterable: true,
    },
]);

onMounted(async () => {
    getHospitalData();
});
</script>
