import { defineStore } from "pinia";

export const menuStore = defineStore('menu', {
    state: () => ({
        expandMobileMenu: false,
        resolution: 1024
    }),
    getters: {
        isMobileResolution: (state) => state.resolution <= 1024,
        showMobileMenu: (state) => state.expandMobileMenu
    },
    actions: {
        setResolution(width) {
            this.resolution = width
        }
    }
})
