<template>
    <Dialog
        v-model:visible="showDialog"
        modal
        :header="t('patient.addPatientFromApi')"
        :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
        :style="{ width: '50vw' }"
        :draggable="false"
        @hide="emit('update:modelValue', showDialog)"
    >
        <WInputText v-model="identityNumber" :label="t('patient.identifier')" :is-required="true"></WInputText>
        <WInputText v-model="apiUrl" label="API url" :is-required="true"></WInputText>
        <template #footer>
            <WButton :label="t('buttons.addFromAPI')" @click="addPatient"></WButton>
        </template>
    </Dialog>
</template>

<script setup>
import Dialog from 'primevue/dialog';
import WInputText from '@/components/wrappers/form/WInputText.vue';
import WButton from '@/components/wrappers/button/WButton.vue';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';
import { patientService } from '@/services/patient/patient.service';
import { useVuelidate } from "@vuelidate/core";

const emit = defineEmits(['update:modelValue', 'refreshDataTable']);

const props = defineProps({
    modelValue: Boolean,
});

const { t } = useI18n();
const v$ = useVuelidate();
const toast = useToast();

const showDialog = ref(props.modelValue);
const loading = ref(false);
const apiUrl = ref('');
const identityNumber = ref('');

const addPatient = async () => {
    await v$.value.$validate();
    if (v$.value.$errors.length > 0) {
        return;
    }
    let patientDataFromApi = null;
    try {
        const result = await patientService.getFromApi(apiUrl.value, identityNumber.value);
        if (result.data || result.data.length < 1) {
            patientDataFromApi = result.data;
        } else {
            toast.add({
                severity: 'warn',
                summary: t('notification.error'),
                detail: t('patient.notFoundPatients'),
                life: 3000,
            });
        }
    } catch {
        toast.add({
            severity: 'error',
            summary: t('notifications.error'),
            detail: t('patient.errorGettingDataFromApi'),
            life: 3000,
        });
    }

    if (patientDataFromApi) {
        const isPatientExisting = (
            await patientService.isExistingPatient(patientDataFromApi.personIdentityNumber)
        ).data;
        if (isPatientExisting) {
            toast.add({
                severity: 'warn',
                summary: t('patient.existingPatient'),
                detail: t('patient.thereIsExistingPatientWithThisIdentification'),
                life: 3000,
            });
            return;
        }
        try {
            await patientService.create(patientDataFromApi);
            toast.add({
                severity: 'success',
                summary: t('notifications.success'),
                detail: t('notifications.successfulSave'),
                life: 3000,
            });
            emit('refreshDataTable');
            showDialog.value = false;
        } catch {
            toast.add({
                severity: 'error',
                summary: t('notifications.error'),
                detail: t('notifications.errorCreate'),
                life: 3000,
            });
        }
    }
};

watch(
    () => props.modelValue,
    () => {
        showDialog.value = props.modelValue;
    }
);
</script>
