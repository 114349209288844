<template>
    <WDataTable
        :action-buttons="actionButtons"
        :columns="columns"
        ref="dataTable"
        api-url="/Patient/GetFiltered"
    ></WDataTable>
    <WButton :label="$t('buttons.create')" icon="pi pi-user-plus" @click="create"></WButton>
    <WButton :label="$t('buttons.addFromAPI')" class="ml-3" severity="info" @click="addPatientFromApi"></WButton>
    <AddPatientFromApiDialog
        v-model="showAddPatientFromApiDialog"
        @refreshDataTable="refreshDataTable"
    ></AddPatientFromApiDialog>
</template>

<script setup>
import { ActionButtonModel } from '@/components/wrappers/data/data-table/models/actionButtonModel';
import WDataTable from '@/components/wrappers/data/data-table/WDataTable.vue';
import { useI18n } from 'vue-i18n';
import { DataTableColumnType } from '@/components/wrappers/data/data-table/models/dataTableColumnType';
import WButton from '@/components/wrappers/button/WButton.vue';
import { useRouter } from 'vue-router';
import { computed } from 'vue';
import { patientService } from '@/services/patient/patient.service';
import { ref } from 'vue';
import { useToast } from 'primevue/usetoast';
import AddPatientFromApiDialog from '@/components/patient/AddPatientFromApiDialog.vue';

const { t } = useI18n();
const router = useRouter();
const toast = useToast();
const dataTable = ref(null);
const showAddPatientFromApiDialog = ref(false);

const columns = computed(() => [
    {
        field: 'firstName',
        header: t('patient.name'),
        sortable: true,
        dataType: DataTableColumnType.text,
        filterable: true,
    },
    {
        field: 'middleName',
        header: t('patient.middleName'),
        sortable: true,
        dataType: DataTableColumnType.text,
        filterable: true,
    },
    {
        field: 'lastName',
        header: t('patient.lastName'),
        sortable: true,
        dataType: DataTableColumnType.text,
        filterable: true,
    },
    {
        field: 'personIdentityNumber',
        header: t('patient.identifier'),
        sortable: true,
        dataType: DataTableColumnType.text,
        filterable: true,
    },
    {
        field: 'birthDate',
        header: t('patient.dateOfBirth'),
        sortable: true,
        dataType: DataTableColumnType.date,
        filterable: true,
    },
    {
        field: 'genderName',
        header: t('patient.gender'),
        sortable: true,
        dataType: DataTableColumnType.text,
        filterable: true,
    },
    {
        field: 'email',
        header: t('patient.email'),
        dataType: DataTableColumnType.text,
        filterable: true,
    },
    {
        field: 'phoneNumber',
        header: t('patient.phoneContact'),
        dataType: DataTableColumnType.text,
        filterable: true,
    },
]);

const editPatientData = async (item) => {
    try {
        router.push({ path: `/patient/edit/${item.id}` });
    } catch (err) {
        toast.add({
            severity: 'error',
            summary: t('notifications.error'),
            detail: t('notifications.somethingGoneWrong'),
            life: 2000,
        });
    }
};

const displayPatientData = async (item) => {
    try {
        router.push({ path: `/patient/display/${item.id}` });
    } catch (err) {
        toast.add({
            severity: 'error',
            summary: t('notifications.error'),
            detail: t('notifications.somethingGoneWrong'),
            life: 2000,
        });
    }
};

const displayPatientProcedureList = async (item) => {
    try {
        router.push({ path: `/patient/procedureList/${item.id}` });
    } catch (err) {
        toast.add({
            severity: 'error',
            summary: t('notifications.error'),
            detail: t('notifications.somethingGoneWrong'),
            life: 2000,
        });
    }
};

const deletePatient = async (item) => {
    try {
        await patientService.delete(item.id);
        toast.add({
            severity: 'success',
            summary: t('notifications.success'),
            detail: t('patient.successfulDeletedPatient'),
            life: 2000,
        });
    } catch (err) {
        toast.add({
            severity: 'error',
            summary: t('notifications.error'),
            detail: t('notifications.somethingGoneWrong'),
            life: 2000,
        });
    } finally {
        refreshDataTable();
    }
};

const refreshDataTable = () => {
    dataTable.value.refresh();
};

const createProcedure = (event) => {
    router.push({ path: `/procedure/create/${event.id}` });
};

const create = () => {
    router.push({ path: '/patient/create' });
};

const addPatientFromApi = () => {
    showAddPatientFromApiDialog.value = true;
};

const actionButtons = computed(() => [
    new ActionButtonModel('pi pi-eye', t('buttons.display'), displayPatientData),
    new ActionButtonModel('pi pi-pencil', t('buttons.edit'), editPatientData),
    new ActionButtonModel('pi pi-trash', t('buttons.delete'), deletePatient, 'danger'),
    new ActionButtonModel(
        'pi pi-list',
        t('patient.patientProcedureList'),
        displayPatientProcedureList
    ),
    new ActionButtonModel('pi pi-plus', t('taviProcedure.createNewProcedure'), createProcedure),
]);
</script>
