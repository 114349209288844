import { FollowUpEchocardiographyDto } from '@/dto/followUp/followUpEchocardiographyDto';

export class FollowUpExamDto {
    constructor(obj) {
        this.id = obj.id;
        this.date = obj.date;
        this.nyhaClassId = obj.nyhaClassId;
        this.ccsAnginaStatusId = obj.ccsAnginaStatusId;
        this.cardiacInterventions = obj.cardiacInterventions;
        this.reinterventionIndications = obj.reinterventionIndications;
        this.followUpEchocardiography = new FollowUpEchocardiographyDto(
            obj.followUpEchocardiography || {}
        );
    }
}
