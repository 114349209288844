export class PatientCreateDto {
    constructor(
        firstName,
        middleName,
        lastName,
        personIdentityTypeId,
        personIdentityNumber,
        birthDate,
        genderId,
        email,
        phoneNumber,
        id
    ) {
        this.firstName = firstName;
        this.middleName = middleName;
        this.lastName = lastName;
        this.personIdentityTypeId = personIdentityTypeId;
        this.personIdentityNumber = personIdentityNumber;
        this.birthDate = birthDate;
        this.genderId = genderId;
        this.email = email;
        this.phoneNumber = phoneNumber;
        this.id = id;
    }
}
