<template>
    <WButton
        :disabled="disabledBtn"
        :label="t('followUp.addFollowUp')"
        icon="pi pi-plus"
        class="mb-4"
        @click="addNewExam"
    />
    <div>
        <Accordion :activeIndex="0">
            <AccordionTab
                v-for="(exam, index) in followUpExams"
                :key="exam"
                :header="`${t('followUp.title')} ${format(new Date(exam.date || new Date()))}`"
            >
                <template #header
                    ><Badge
                        v-if="refExams[index] && refExams[index].v$.$errors.length"
                        :value="refExams[index].v$.$errors.length"
                        severity="danger"
                        class="ml-2"
                /></template>
                <FollowUp
                    :ref="
                        (el) => {
                            refExams[index] = el;
                        }
                    "
                    :disabled="disabled"
                    :item="exam"
                    :aorticValvePathologies="aorticValvePathologies"
                    :diseaseSeverities="diseaseSeverities"
                    :cardiacInterventions="cardiacInterventions"
                    :reinterventionIndications="reinterventionIndications"
                    :leftVentricularEjectionFraction="leftVentricularEjectionFraction"
                    :nyhaClass="nyhaClass"
                    :formatDate="format"
                    @remove-exam="removeExam"
                />
            </AccordionTab>
        </Accordion>
    </div>
</template>
<script setup>
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import FollowUp from '@/components/tavi/FollowUp.vue';
import { FollowUpExamDto } from '@/dto/followUp/followUpExamDto';
import { onUpdated, ref, onBeforeUpdate } from 'vue';
import WButton from '@/components/wrappers/button/WButton.vue';
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';
import { localeStore } from '@/store/locale.store';
import Badge from 'primevue/badge';

const v$ = useVuelidate();

const props = defineProps({
    items: Array,
    disabled: {
        type: Boolean,
        default: false,
    },
    aorticValvePathologies: Array,
    diseaseSeverities: Array,
    cardiacInterventions: Array,
    reinterventionIndications: Array,
    leftVentricularEjectionFraction: Array,
    nyhaClass: Array,
});

const { t } = useI18n();
const followUpExams = ref(props.items);
const disabledBtn = ref(props.disabled);
const refExams = ref([]);

const addNewExam = () => {
    disabledBtn.value = true;
    followUpExams.value.splice(0, 0, new FollowUpExamDto({}));
};

const removeExam = (followUpExam) => {
    const index = followUpExams.value.findIndex((x) => x === followUpExam);
    followUpExams.value.splice(index, 1);
};

const format = (date) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };

    return localeStore().isBg
        ? date.toLocaleDateString('bg-BG', options)
        : date.toLocaleDateString('en-GB');
};

onBeforeUpdate(() => {
    refExams.value = [];
});

onUpdated(() => {
    if (!props.disabled && disabledBtn.value) {
        disabledBtn.value = false;
    }
});

defineExpose({ v$ });
</script>
