<template>
    <div>
        <label
            class="block font-medium mb-2"
            :class="{ 'p-error': v$.selectedValue.$invalid && v$.$dirty, required: isRequired }"
        >
            {{ label }}
        </label>
        <SelectButton
            id="btn"
            v-model="selectedValue"
            :options="items"
            class="select-button"
            :disabled="disabled"
            :optionLabel="optionLabel"
            :optionValue="optionValue"
            :aria-labelledby="ariaLabelledby"
        />
        <small v-if="v$.selectedValue.$errors.length > 0" class="p-error">
            {{ v$.selectedValue.$errors[0].$message }}
        </small>
    </div>
</template>

<script setup>
import SelectButton from 'primevue/selectbutton';
import { computed, ref, watch } from 'vue';
import { required } from '@/validation/validators';
import { useVuelidate } from '@vuelidate/core';
import { localeStore } from '@/store/locale.store';

const emit = defineEmits(['change', 'update:modelValue']);
const props = defineProps({
    modelValue: {
        type: [Number, String, Boolean], // TODO:
        required: false,
    },
    optionValue: {
        type: String,
        default: 'id',
    },
    label: String,
    items: {
        type: Array,
        default: () => [],
    },
    ariaLabelledby: {
        type: String,
        default: 'single',
    },
    isRequired: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});

const optionLabel = computed(() => (localeStore().isBg ? 'nameBg' : 'nameEn'));
const selectedValue = ref(props.modelValue);
const rules = {
    selectedValue: {},
};

if (props.isRequired) {
    Object.assign(rules.selectedValue, { required });
}

watch(
    () => selectedValue.value,
    (val) => {
        if (val !== props.modelValue) {
            emit('update:modelValue', selectedValue.value);
            emit('change', selectedValue.value);
        }
    }
);

watch(
    () => props.modelValue,
    (val) => {
        if (val !== selectedValue.value) {
            selectedValue.value = val;
        }
    }
);

const v$ = useVuelidate(rules, { selectedValue });
</script>
