export default {
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вход"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имейл"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Парола"])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нова парола"])},
  "userName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потребителско име"])},
  "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потвърждаване на парола"])},
  "confirmNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потвърждаване на нова парола"])},
  "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Смяна на парола"])},
  "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Справки"])},
  "selectedItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["избрани елемента"])},
  "buttons": {
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запис"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редакция"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изчистване"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Търсене"])},
    "createDraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запис на чернова"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създаване"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отказ"])},
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Избор"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изход"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
    "display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преглед"])},
    "addFromAPI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне от API"])}
  },
  "validations": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задължително поле"])},
    "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Полето трябва да е минимум ", _interpolate(_named("min")), " символа"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невалиден имейл"])},
    "minValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "maxValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "alpha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "alphaNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "numeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "integer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "decimal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "sameAs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a"])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невалиден url адрес"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "exactLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Трябва да е точно ", _interpolate(_named("value")), " символа"])},
    "uniquePersonIdentifier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невалиден ЕГН или ЛНЧ"])},
    "uniquePersonIdentifierExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въведеният ЕГН вече съществува в системата."])},
    "sameAsPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Паролите на съвпадат"])}
  },
  "address": {
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
    "area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Област"])},
    "municipality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Община"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Населено място"])}
  },
  "hospital": {
    "hospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Болница"])},
    "hospitals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Болници"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование"])},
    "rhiNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["РЗИ номер"])},
    "successfulDeletedHospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно изтрита болница"])},
    "contactPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лице за контакт"])},
    "contactPersonFirstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лице за контакт (име)"])},
    "contactPersonLastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лице за контакт (фамилия)"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потребители свързани с болницата"])}
  },
  "patient": {
    "dateOfBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата на раждане"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имейл"])},
    "identifier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор"])},
    "identifierType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип идентификатор"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фамилия"])},
    "middleName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Презиме"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Име"])},
    "patients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пациенти"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пол"])},
    "phoneContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])},
    "historyOfDeseaseNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИЗ №"])},
    "successfulDeletedPatient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно изтрит пациент"])},
    "patientProcedureList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Списък с процедури на пациента"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Теелфон"])},
    "existingPatient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Съществуващ пациент"])},
    "thereIsExistingPatientWithThisIdentification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вече има запис за пациент с този идентификатор"])},
    "notFoundPatients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма намерени пациенти"])},
    "errorGettingDataFromApi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Грешка при зареждане на данни от API"])},
    "addPatientFromApi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на пациент от API"])}
  },
  "person": {
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Име"])},
    "middleName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Презиме"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фамилия"])}
  },
  "personIdentity": {
    "identifierType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип"])},
    "identifier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЕГН"])}
  },
  "roles": {
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роля"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роли"])},
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Администратор"])},
    "hospitalAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Администратор на ЛЗ"])},
    "doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лекар"])}
  },
  "taviProcedure": {
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
    "procedureStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спешност"])},
    "generalAnaesthesia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обща анестезия"])},
    "vascularAccessTechnique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основен артериален съдов достъп, техника"])},
    "vascularAccessSite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основен артериален съдов достъп, място"])},
    "otherArterialAccessSites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Друг артериален съдов достъп"])},
    "proceduresDuringTAVI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Други процедури по време на TAVI"])},
    "prostheticValveFractureAttempted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crack на аортна клапна протеза"])},
    "aorticBalloonValvuloplastyBeforeValveDeployment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аортна балонна валвулопластика преди поставяне на клапата"])},
    "postImplantationBalloonDilatationOfImplantedValve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Балонна постдилатация на клапата след поставянето ѝ"])},
    "balloonSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Размер на балона (mm)"])},
    "rapidPacing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapid pacing"])},
    "vascularClosureTechnique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Техника за затваряне на основния съдов достъп"])},
    "pre-implantationPeak-to-peakInvasiveAVGradient(mmHg)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инвазивен предпроцедурен peak-to-peak AV градиент (mmHg)"])},
    "post-implantationPeak-to-peakInvasiveAVGradient(mmHg)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Постпроцедурен peak-to-peak invasive AV gradient (mmHg)"])},
    "createNewProcedure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създаване на нова процедура"])},
    "currentProcedureList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Списък с настоящи процедури"])},
    "procedureList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Списък с процедури"])},
    "procedures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Процедури"])},
    "successfulDeletedProcedure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно изтрита процедура"])},
    "successfulCreatedProcedure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно създадена процедура"])},
    "createDraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създаване на чернова"])},
    "tavi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TAVI"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Промените са записани успешно"])},
    "postImplantationPVL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post-implantation PVL (degree)"])},
    "anaesthesiaType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вид анестезия"])},
    "proceduralCirculatorySupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procedural circulatory support"])},
    "proceduralCirculatorySupportType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procedural circulatory support, type"])},
    "proceduralEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Усложнения по време на TAVI"])},
    "proceduralEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Усложнения"])},
    "conversionToSternotomyDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стернотомия, дата"])},
    "conversionToSternotomyCardiopulmonaryBypass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стернотомия, кардиопулмонарен байпас"])},
    "conversionToSternotomyReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стернотомия, причина"])},
    "vascularComplications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Съдови усложнения"])},
    "addDevices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добави Медицински изделия (АО клапа)"])},
    "devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Медицински изделия (АО клапа)"])},
    "device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Медицинско изделие (АО клапа)"])},
    "deviceModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Модел"])},
    "diameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Диаметър (mm)"])},
    "successfullyImplanted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно поставяне"])},
    "unsuccessfulImplantationReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина за неуспешно поставяне"])},
    "valveSerialNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сериен номер"])},
    "procedureComplication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Постпроцедурни усложнения"])},
    "proceduralDiagnostics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предпроцедурна диагностика"])},
    "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чернова"])}
  },
  "selectButton": {
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не"])},
    "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неизвестно"])}
  },
  "patientSearch": {
    "personIdentityNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЕГН/ЛНЧ"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Име"])},
    "middleName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Презиме"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фамилия"])},
    "patientInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данни за пациент"])}
  },
  "dataTable": {
    "noItemsFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма намерени елементи"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не"])}
  },
  "registeredUsers": {
    "waitingUsersToApprove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрирани потребители, чакащи одобрение"])},
    "allRegisteredUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрирани потребители"])},
    "successfulConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно потвърждаване на потребител"])},
    "successfulRejection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно отказан достъп на потребител"])},
    "rolesEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редакция на ролите"])},
    "successfulRolesEditing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешна редакция на ролите"])},
    "confirmationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Премахната е ролята \"ApprovedUser\".Сигурни ли сте, че искате да продължите?"])},
    "allRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички роли"])},
    "currentRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущи роли"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата на регистрация"])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отказан"])}
  },
  "notifications": {
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успех"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Грешка"])},
    "somethingGoneWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нещо се обърка"])},
    "registrationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Грешка при регистрация"])},
    "loginError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Грешка при вход"])},
    "successfulSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно направен запис"])},
    "successfulEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешна промяна"])},
    "errorCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неуспешен запис"])},
    "validationErrors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валидационни грешки"])},
    "validationErrorsDetail": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Има ", _interpolate(_named("count")), " валидационни грешки"])},
    "errorEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неуспешна промяна"])},
    "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потвърждаване"])}
  },
  "patientCharacteristics": {
    "generalPatientClinicalData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общи клинични данни"])},
    "patientCharacteristics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Антропометрични данни"])},
    "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Височина (cm)"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тегло (kg)"])},
    "frailty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценка на недееспособността"])},
    "diabetesMellitus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Захарен диабет"])},
    "hypertension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хипертония"])},
    "chronicObstructivePulmonaryDisease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ХОББ"])},
    "activeMalignancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Злокачествено заболяване в активен стадий"])},
    "currentRenalDialysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Провежда хемодиализа"])},
    "atrialFibrillationFlutter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ППМ/ППТ"])},
    "peripheralArterialDisease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Болести на периферните артериални съдове"])},
    "porcelainAorta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Порцеланова аорта"])},
    "priorStroke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преживян мозъчен инсулт"])},
    "coronaryHeartDisease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коронарна болест на сърцето (ИБС)"])},
    "priorMyocardialInfarction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преживян миокарден инфаркт"])}
  },
  "waitingForApprove": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Очакване на одобрение"])},
    "detailsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Трябва да изчакате одобрение от администратор."])}
  },
  "dischargeData": {
    "dischargeDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изписване"])},
    "dischargedateDeathDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата на изписване / Дата на смърт"])},
    "oralAnticoagulants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перорални антикоагуланти"])},
    "aspirin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аспирин"])},
    "myocardialInfarction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Миокарден инфаркт"])},
    "p2Y12Inhibitors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P2Y12 инхибитори"])},
    "laboratory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клинична лаборатория"])},
    "creatinineAtDischarge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Креатинин (µmol/L)"])},
    "haemoglobinAtDischarge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хемоглобин (g/L)"])},
    "atrialRhythm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ритъм"])},
    "ventricularConduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Камерно провеждане"])},
    "atrioventricularBlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AV блок"])},
    "ecg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЕКГ"])},
    "preDischargeEchocardiogram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ехокардиография"])},
    "postDeploymentAorticValveMeanGradient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Среден градиент на аортна клапа"])},
    "postDeploymentMitralRegurgitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Митрална регургитация след TAVI"])},
    "dischargeData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данни при изписването"])},
    "pvl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PVL"])}
  },
  "procedureComplication": {
    "cardiacInterventions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кардиологични интервенции"])},
    "redoTaviReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина за повторна TAVI"])},
    "otherHeartValveSurgery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Засегната клапа"])},
    "myocardialInfarction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Миокарден инфаркт"])},
    "brainStroke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мозъчен инсулт"])},
    "inHospitalCardiogenicShock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кардиогенен шок"])},
    "ifCardiogenicShockStage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кардиогенен шок, стадий"])},
    "cardiacArrest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сърдечен арест"])},
    "vARC3MajorBleeding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VARC 3 major bleeding"])},
    "newRequirementForRenalReplacementTherapy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Необходимост от хемодиализа (новопоявила се)"])},
    "implantableCardiacDevice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имплантирано сърдечно устройство"])},
    "inHospitalDeath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Смърт (по време на хоспитализацията)"])},
    "deathReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина за смъртта"])}
  },
  "passwordLabels": {
    "promptLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въведете парола"])},
    "weakLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Слаба"])},
    "mediumLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Средна"])},
    "strongLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Силна"])}
  },
  "menu": {
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Табло"])},
    "hospitals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Болници"])},
    "patients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пациенти"])},
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Админ"])},
    "administration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Администрация"])},
    "registeredUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрирани потребители"])},
    "userAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потребителски достъп"])},
    "procedures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Процедури"])},
    "createNewProcedure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създаване на нова процедура"])},
    "procedureDraftList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Списък с чернови"])},
    "procedureList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Списък с процедури"])},
    "nomenclaturesManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление на номенклатури"])},
    "usersWaitingApprove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чакащи одобрение"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потребители"])}
  },
  "followUp": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проследяване"])},
    "generalData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общи данни"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
    "nyhaClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NYHA клас"])},
    "ccsAngina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CCS ангина"])},
    "echocardiography": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ехокардиография"])},
    "leftVentricularEjectionFraction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фракция на изтласкване на ЛК"])},
    "avMeanGradient(mmHg)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Среден градиент на аортна клапа (mmHg)"])},
    "avPeakGradient(mmHg)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пиков градиент през аортна клапа (mmHg)"])},
    "aorticRegurgitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аортна регургитация"])},
    "mitralRegurgitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Митрална регургитация"])},
    "tricuspidRegurgitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Трикуспидална регургитация"])},
    "newValveRelatedPathological": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новооткрита патология на аортната клапа"])},
    "interventions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интервенции"])},
    "subsequentInterventions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последващи интервенции"])},
    "subsequentInterventionsPrimaryIndication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина за последваща интервенция"])},
    "addFollowUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добави проследяване"])}
  },
  "proceduralDiagnostics": {
    "aVMeanGradient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Среден градиент на аортна клапа (mmHg)"])},
    "aVPeakVelocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимална скорост на кръвотока (m/s)"])},
    "aVArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Площ на аортна клапа"])},
    "aorticValveMorphology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Морфология на аортната клапа"])},
    "bicuspidAorticValveType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бикуспидна аортна клапа, тип"])},
    "bicuspidAorticValveCuspFusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бикуспидна аортна клапа, слепване на комисури"])},
    "aorticAnnulusArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аортен пръстен, площ (cm2)"])},
    "aorticAnnulusPerimeter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аортен пръстен, периметър (mm)"])},
    "aorticAnnulusCalcification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Калциноза на аортния пръстен"])},
    "aorticLeafletsCuspsCalcification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Калциноза на платната/комисурите на аортната клапа"])},
    "lVOTCalcification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LVOT calcification"])},
    "rapheCalcification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raphe calcification"])},
    "aorticValveDiseaseAetiology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Етиология на болестта на аортната клапа"])},
    "mitralRegurgitationType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Митрална регургитация, тип"])},
    "mitralStenosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Митрална стеноза"])},
    "tricuspidRegurgitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Трикуспидална регургитация"])},
    "pASystolicPressure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Систолично налягане в пулмоналната артерия (mmHg)"])},
    "laboratory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клинична лаборатория"])},
    "haemoglobin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хемоглобин (g/L)"])},
    "bNP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BNP (ng/L)"])},
    "nTProBNP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NT-pro BNP (ng/L)"])},
    "creatinine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Краетинин (µmol/L)"])},
    "coronaryAngiographyOrCTCoronaryAngiography": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коронарография и КАТ"])},
    "coronaryArteryStenosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стенози на коронарни артерии"])},
    "lVOTDiameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Диаметър на LVOT (mm)"])},
    "sinusesOfValsalvaMeanDiameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Синус на Валсалва, диаметър (mm)"])},
    "sinotubularJunctionMeanDiameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Синотубуларен възел, диаметър (mm)"])},
    "sinotubularJunctionHeightFromAnnulus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Синотубуларен възел, височина от пръстена (mm)"])},
    "rcaOstiumHeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RCA ostium, height (mm)"])},
    "lmsOstiumHeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LMS ostium, height (mm)"])},
    "aorticAnnulusDiameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Диаметър на ринга (mm)"])}
  },
  "council": {
    "councilTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Консилиум"])},
    "symptoms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Симптоматика"])},
    "nyha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NYHA"])},
    "preProceduralCirculatorySupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подпомагане на кръвообращението"])},
    "activeEndocarditis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Остър ендокардит"])},
    "conclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заключение"])},
    "sTSRiskScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STS risk score"])},
    "euroScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EuroScore"])},
    "euroScoreII": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EuroScore II"])}
  },
  "previousProcedures": {
    "previousProceduresTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предишни процедури"])},
    "cardiacInterventions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кардиологична интервенция"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
    "surgicalAorticValveReplacement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вид на аортната клапна протеза"])},
    "valve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клапа"])},
    "TAVI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TAVI"])},
    "TAVIDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
    "otherTranscatheterValve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Друга транскатетърна интервенция на сърдечна клапа"])},
    "priorImplantableCardiacDevice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поставен кардиостимулатор"])}
  },
  "nomenclature": {
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нов"])},
    "nameBg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Име на български"])},
    "nameEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Име на английски"])},
    "validFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валиден от"])},
    "validTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валиден до"])}
  },
  "users": {
    "editRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Промяна на роли"])},
    "newRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нова регистрация"])},
    "createUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създаване на потребител"])}
  }
}