<template>
    <Card style="width: 100%; margin-bottom: 2em">
        <template #title>{{ t('followUp.generalData') }} </template>
        <template #content>
            <div class="grid formgrid">
                <div class="col-12 md:col-4 field">
                    <WDatePicker
                        :disabled="disabled"
                        v-model="model.date"
                        :label="t('followUp.date')"
                        :is-required="true"
                    />
                </div>
                <div class="col-12 md:col-4 field">
                    <WDropdown
                        :disabled="disabled"
                        :items="nyhaClass"
                        :is-required="true"
                        v-model="model.nyhaClassId"
                        optionLabel="nameBg"
                        :label="t('followUp.nyhaClass')"
                    ></WDropdown>
                </div>
                <div class="col-12 md:col-4 field">
                    <WDropdown
                        :disabled="disabled"
                        :items="ccsAngina"
                        v-model="model.ccsAnginaStatusId"
                        optionLabel="nameBg"
                        :label="t('followUp.ccsAngina')"
                    ></WDropdown>
                </div>
            </div>
        </template>
    </Card>
    <Card style="width: 100%; margin-bottom: 2em">
        <template #title>{{ t('followUp.echocardiography') }} </template>
        <template #content>
            <div class="grid">
                <div class="col-12 md:col-4 field">
                    <WDropdown
                        :disabled="disabled"
                        :items="leftVentricularEjectionFraction"
                        :is-required="true"
                        v-model="model.followUpEchocardiography.leftVentricularEjectionFractionId"
                        optionLabel="nameBg"
                        :label="t('followUp.leftVentricularEjectionFraction')"
                    ></WDropdown>
                </div>
                <div class="col-12 md:col-4 field">
                    <WInputNumberCheckbox
                        :disabled="disabled"
                        :is-required="true"
                        v-model="model.followUpEchocardiography.avMeanGradient"
                        :label="t('followUp.avMeanGradient(mmHg)')"
                    />
                </div>
                <div class="col-12 md:col-4 field">
                    <WInputNumberCheckbox
                        :disabled="disabled"
                        :is-required="true"
                        v-model="model.followUpEchocardiography.avPeakGradient"
                        :label="t('followUp.avPeakGradient(mmHg)')"
                    />
                </div>
                <div class="col-12 md:col-12 field">
                    <WMultiSelect
                        :disabled="disabled"
                        :max-selected-labels="6"
                        :is-required="true"
                        :items="aorticValvePathologies"
                        v-model="model.followUpEchocardiography.aorticValvePathologies"
                        optionLabel="nameBg"
                        :label="t('followUp.newValveRelatedPathological')"
                    />
                </div>
            </div>
            <div class="grid">
                <div class="col-12 md:col-4 field">
                    <WDropdown
                        :disabled="disabled"
                        :items="diseaseSeverities"
                        :is-required="true"
                        v-model="
                            model.followUpEchocardiography.aorticRegurgitationDiseaseSeverityId
                        "
                        optionLabel="nameBg"
                        :label="t('followUp.aorticRegurgitation')"
                    ></WDropdown>
                </div>
                <div class="col-12 md:col-4 field">
                    <WDropdown
                        :disabled="disabled"
                        :items="diseaseSeverities"
                        v-model="
                            model.followUpEchocardiography.mitralRegurgitationIdDiseaseSeverityId
                        "
                        optionLabel="nameBg"
                        :label="t('followUp.mitralRegurgitation')"
                    ></WDropdown>
                </div>
                <div class="col-12 md:col-4 field">
                    <WDropdown
                        :disabled="disabled"
                        :items="tricuspidRegurgitation"
                        v-model="model.followUpEchocardiography.diseaseSeverityId"
                        optionLabel="nameBg"
                        :label="t('followUp.tricuspidRegurgitation')"
                    ></WDropdown>
                </div>
            </div>
        </template>
    </Card>
    <Card style="width: 100%; margin-bottom: 2em">
        <template #title>{{ t('followUp.interventions') }} </template>
        <template #content>
            <div class="grid">
                <div class="col-12 md:col-6 field">
                    <WMultiSelect
                        :disabled="disabled"
                        :items="cardiacInterventions"
                        v-model="model.cardiacInterventions"
                        optionLabel="nameBg"
                        :label="t('followUp.subsequentInterventions')"
                    />
                </div>
                <div class="col-12 md:col-6 field">
                    <WMultiSelect
                        :disabled="disabled"
                        :items="reinterventionIndications"
                        v-model="model.reinterventionIndications"
                        optionLabel="nameBg"
                        :label="t('followUp.subsequentInterventionsPrimaryIndication')"
                    />
                </div>
            </div>
        </template>
    </Card>
    <WButton
        :disabled="disabled"
        icon="pi pi-trash"
        css-class
        severity="danger"
        :label="t('buttons.delete')"
        v-tooltip.right="`${t('followUp.title')} ${followUpDate}`"
        @click="removeExamFromModel(model)"
    ></WButton>
</template>

<script setup>
import Card from 'primevue/card';
import WDatePicker from '@/components/wrappers/dateTime/WDateTimePicker.vue';
import WDropdown from '@/components/wrappers/form/WDropdown.vue';
import WInputNumberCheckbox from '@/components/wrappers/form/WInputNumberCheckbox.vue';
import { useI18n } from 'vue-i18n';
import { ref, onMounted, computed } from 'vue';
import { dropdownService } from '@/services/dropdown/dropdown.service';
import { FollowUpExamDto } from '@/dto/followUp/followUpExamDto';
import WMultiSelect from '@/components/wrappers/form/WMultiSelect.vue';
import { useVuelidate } from '@vuelidate/core';
import WButton from '@/components/wrappers/button/WButton.vue';

const emit = defineEmits(['removeExam']);

const props = defineProps({
    item: FollowUpExamDto,
    disabled: {
        type: Boolean,
        default: false,
    },
    aorticValvePathologies: Array,
    diseaseSeverities: Array,
    cardiacInterventions: Array,
    reinterventionIndications: Array,
    leftVentricularEjectionFraction: Array,
    nyhaClass: Array,
    formatDate: {
        type: Function,
    },
});

const { t } = useI18n();
const v$ = useVuelidate();
const model = ref(props.item);
const ccsAngina = ref([]);
const tricuspidRegurgitation = ref([]);

const format = ref(props.formatDate);

const followUpDate = computed(() => {
    return format.value(new Date(model.value.date || new Date()));
});

const removeExamFromModel = (followUpExam) => {
    emit('removeExam', followUpExam);
};

const getCCSAnginaStatus = async () => {
    ccsAngina.value = (await dropdownService.getCCSAnginaStatus()).data;
};

const getTricuspidRegurgitation = async () => {
    tricuspidRegurgitation.value = (await dropdownService.getDiseaseSeverities()).data;
};

onMounted(async () => {
    getCCSAnginaStatus();
    getTricuspidRegurgitation();
});

defineExpose({ v$ });
</script>
