<template>
    <Card class="mt-8">
        <template #header>
            <i
                class="pi pi-user text-primary flex align-items-center justify-content-center primary"
                style="font-size: 3rem;"
            ></i>
        </template>
        <template #title>
            <h3>{{ t('waitingForApprove.title') }}</h3>
        </template>
        <template #subtitle>
            <h4>{{ t('waitingForApprove.detailsText') }}</h4>
        </template>
    </Card>
</template>

<script setup>
import Card from 'primevue/card';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
</script>
