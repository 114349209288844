import { httpService } from "@/services/http.service";

export const hospitalService = {
    async getAll() {
        return httpService.get('/hospital/GetAll');
    },
    async getById(id) {
        return httpService.get(`/hospital/${id}`);
    },
    async create(dto) {
        return httpService.post('/hospital', dto);
    },
    async edit(dto) {
        return httpService.put('/hospital', dto);
    },
    async delete(id) {
        return httpService.delete(`/hospital/${id}`)
    }
}
