<template>
    <div class="my-2">
        <label
            :for="`inputText${_uid}`"
            class="block font-medium mb-2"
            :class="{ 'p-error': v$.inputValue.$invalid && v$.$dirty, required: isRequired }"
        >
            {{ label }}
        </label>
        <Password
            v-model="inputValue"
            toggle-mask
            class="block text-900 font-medium w-full"
            input-class="w-full"
            :feedback="feedback"
            :disabled="disabled"
            :class="v$.inputValue.$invalid && v$.$dirty ? `p-invalid ${cssClass}` : cssClass"
            :id="`inputText${_uid}`"
            :prompt-label="$t('passwordLabels.promptLabel')"
            :weak-label="$t('passwordLabels.weakLabel')"
            :medium-label="$t('passwordLabels.mediumLabel')"
            :strong-label="$t('passwordLabels.strongLabel')"
            @update:modelValue="$emit('update:modelValue', inputValue)"
        ></Password>
        <small v-if="v$.inputValue.$errors.length > 0" class="p-error">
            {{ v$.inputValue.$errors[0].$message }}
        </small>
    </div>
</template>

<script setup>
import Password from 'primevue/password';
import { computed, ref, watch } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, minLength, sameAs } from '@/validation/validators';

const props = defineProps({
    modelValue: String,
    label: String,
    cssClass: String,
    isRequired: {
        type: Boolean,
        default: false,
    },
    isSameAs: {
        type: String,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    minLength: {
        type: Number,
    },
    feedback: {
        type: Boolean,
        default: false
    }
});

const inputValue = ref(props.modelValue);

const rules = {
    inputValue: {},
};
if (props.isRequired) {
    Object.assign(rules.inputValue, { required });
}
if (props.minLength) {
    Object.assign(rules.inputValue, { minLength: minLength(props.minLength) });
}
Object.assign(rules.inputValue, {
    sameAsPassword: sameAs(
        // Ако няма подадено property isSameAs, стойността е undefined и винаги дава че няма съвпадение
        computed(() => (props.isSameAs === undefined ? inputValue.value : props.isSameAs))
    ),
});

const v$ = useVuelidate(rules, { inputValue });

watch(
    () => props.modelValue,
    (val) => {
        inputValue.value = val;
    }
);
</script>
