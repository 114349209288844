<template>
    <ToggleButton
        class="w-full sm:w-10rem"
        :class="bgColor"
        :onLabel="onLabel"
        :offLabel="offLabel"
        :onIcon="onIcon"
        :offIcon="offIcon"
        :disabled="disabled"
        :iconPos="iconPos"
        v-model="model"
    />
</template>

<script setup>
import ToggleButton from 'primevue/togglebutton';
import { computed, ref, watch } from 'vue';

const emit = defineEmits(['change', 'update:modelValue']);
const props = defineProps({
    onLabel: String, //Стойност на бутона когато е true.
    offLabel: String, //Стойност на бутона когато е false.
    // Иконка на бутона когато е true.
    onIcon: {
        type: String,
        default: 'pi pi-check',
    },
    //Иконка на бутона когато е false.
    offIcon: {
        type: String,
        default: 'pi pi-times',
    },
    onColor: {
        type: String,
        default: 'bg-primary',
    },
    offColor: {
        type: String,
        default: 'bg-red-600 text-white',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    iconPos: {
        type: String,
        default: 'left',
        required: false,
    },
    modelValue: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const model = ref(props.modelValue);

const bgColor = computed(() => (model.value === true ? props.onColor : props.offColor));

watch(
    () => model.value,
    (val) => {
        if (val !== props.modelValue) {
            emit('update:modelValue', model.value);
            emit('change', model.value);
        }
    }
);
watch(
    () => props.modelValue,
    (val) => {
        if (val !== model.value) {
            model.value = val;
        }
    }
);
</script>
