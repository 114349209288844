<template>
    <div>
        <div class="formgrid grid">
            <div class="col-12 md:col-4 field" v-if="model.firstName">
                <WInputText
                    :disabled="true"
                    v-model="model.firstName"
                    :label="t('patientSearch.firstName')"
                ></WInputText>
            </div>
            <div class="col-12 md:col-4 field" v-if="model.middleName">
                <WInputText
                    :disabled="true"
                    v-model="model.middleName"
                    :label="t('patientSearch.middleName')"
                ></WInputText>
            </div>
            <div class="col-12 md:col-4 field" v-if="model.lastName">
                <WInputText
                    :disabled="true"
                    v-model="model.lastName"
                    :label="t('patientSearch.lastName')"
                ></WInputText>
            </div>
        </div>
        <div class="grid">
            <div class="col-12 md:col-4 field" v-if="model.personIdentityNumber">
                <WInputText
                    :disabled="true"
                    :label="t('patient.identifier')"
                    v-model="model.personIdentityNumber"
                ></WInputText>
            </div>
            <div class="col-12 md:col-4 field" v-if="model.personIdentityTypeName">
                <WInputText
                    :disabled="true"
                    v-model="model.personIdentityTypeName"
                    :label="t('patient.identifierType')"
                ></WInputText>
            </div>
            <div class="col-12 md:col-4 field" v-if="model.genderName">
                <WInputText
                    :disabled="true"
                    v-model="model.genderName"
                    :label="t('patient.gender')"
                ></WInputText>
            </div>
            <div class="col-12 md:col-4 field mt-2" v-if="model.birthDate">
                <WDatePicker
                    v-model="model.birthDate"
                    :disabled="true"
                    :label="t('patient.dateOfBirth')"
                />
            </div>
        </div>
        <div class="grid">
            <div class="col-12 md:col-4 field" v-if="model.phoneNumber">
                <WInputText
                    :disabled="true"
                    v-model="model.phoneNumber"
                    :label="t('patient.phoneContact')"
                ></WInputText>
            </div>
        </div>
        <div v-if="show">
            <WButton
                :label="t('buttons.back')"
                icon="pi pi-arrow-left"
                @click="goBack"
            ></WButton>
        </div>
    </div>
</template>

<script setup>
import WButton from '@/components/wrappers/button/WButton.vue';
import WDatePicker from '@/components/wrappers/dateTime/WDateTimePicker.vue';
import WInputText from '@/components/wrappers/form/WInputText.vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { PatientViewDto } from '@/dto/patient/patientViewDto';
import { useRouter } from 'vue-router';

const router = useRouter();
const props = defineProps({
    model: PatientViewDto,
    show: {
        type: Boolean,
        default: false,
    },
});
const goBack = () => {
    router.push({ path: '/patient/list' });
};
const model = computed(() => props.model);
const { t } = useI18n();
</script>
