import { httpService } from '@/services/http.service';

export const taviService = {
    async getById(id) {
        return httpService.get(`/tavi/${id}`)
    },
    async create(taviDto) {
        return httpService.post('/tavi', taviDto);
    },
    async edit(taviDto) {
        return httpService.put('/tavi', taviDto);
    },
    async delete(taviId) {
        return httpService.delete(`/tavi/${taviId}`);
    },
    async getAllByPatientId(patientId) {
        return httpService.get(`/tavi/getAllByPatientId/${patientId}`);
    },
};
