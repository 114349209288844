<template>
    <div class="grid" v-if="model">
        <div class="col-12 md:col-4 field">
            <WDropdown
                :disabled="disabled"
                :items="leftVentricularEjectionFraction"
                v-model="model.leftVentricularEjectionFractionId"
                :is-required="true"
                optionLabel="nameBg"
                :label="t('followUp.leftVentricularEjectionFraction')"
            ></WDropdown>
        </div>
        <div class="col-12 md:col-4 field">
            <WInputNumberCheckbox
                :disabled="disabled"
                v-model="model.avMeanGradient"
                :is-required="true"
                :label="t('proceduralDiagnostics.aVMeanGradient')"
            />
        </div>
        <div class="col-12 md:col-4 field">
            <WInputNumberCheckbox
                :disabled="disabled"
                v-model="model.avPeakVelocity"
                :is-required="true"
                :label="t('proceduralDiagnostics.aVPeakVelocity')"
            />
        </div>
    </div>
    <div class="grid">
        <div class="col-12 md:col-4 field">
            <WInputNumberCheckbox
                :disabled="disabled"
                v-model="model.avArea"
                :label="t('proceduralDiagnostics.aVArea')"
            />
        </div>
        <div class="col-12 md:col-4 field">
            <WDropdown
                :disabled="disabled"
                :items="diseaseSeverities"
                v-model="model.aorticRegurgitationDiseaseSeverityId"
                :is-required="true"
                optionLabel="nameBg"
                :label="t('followUp.aorticRegurgitation')"
            ></WDropdown>
        </div>
        <div class="col-12 md:col-4 field">
            <WDropdown
                :disabled="disabled"
                :items="valveMorphology"
                v-model="model.valveMorphologyId"
                :is-required="true"
                optionLabel="nameBg"
                :label="t('proceduralDiagnostics.aorticValveMorphology')"
            ></WDropdown>
        </div>
    </div>
    <div class="grid">
        <div
            class="col-12 md:col-4 field"
            v-if="model.valveMorphologyId === AorticValveMorphology.Bicuspid"
        >
            <WDropdown
                :disabled="disabled"
                :items="bicuspidAorticValveType"
                v-model="model.bicuspidAorticValveTypeId"
                :is-required="true"
                optionLabel="nameBg"
                :label="t('proceduralDiagnostics.bicuspidAorticValveType')"
            ></WDropdown>
        </div>
        <div
            class="col-12 md:col-8 field"
            v-if="model.valveMorphologyId === AorticValveMorphology.Bicuspid"
        >
            <WMultiSelect
                :items="bicuspidAorticValveCuspFusion"
                v-model="model.bicuspidAorticValves"
                :is-required="true"
                :disabled="disabled"
                optionLabel="nameBg"
                :max-selected-labels="6"
                :label="t('proceduralDiagnostics.bicuspidAorticValveCuspFusion')"
            />
        </div>
    </div>
    <div class="grid">
        <div class="col-12 md:col-4 field">
            <WInputNumberCheckbox
                :disabled="disabled"
                v-model="model.aorticAnnulusArea"
                :label="t('proceduralDiagnostics.aorticAnnulusArea')"
            />
        </div>
        <div class="col-12 md:col-4 field">
            <WInputNumberCheckbox
                :disabled="disabled"
                v-model="model.aorticAnnulusPerimeter"
                :label="t('proceduralDiagnostics.aorticAnnulusPerimeter')"
            />
        </div>
        <div class="col-12 md:col-4 field">
            <WInputNumberCheckbox
                :disabled="disabled"
                v-model="model.aorticAnnulusDiameter"
                :label="t('proceduralDiagnostics.aorticAnnulusDiameter')"
            />
        </div>
        <div class="col-12 md:col-6 field">
            <WDropdown
                :disabled="disabled"
                :items="diseaseSeverities"
                v-model="model.aorticAnnulusCalcificationDiseaseSeverityId"
                optionLabel="nameBg"
                :label="t('proceduralDiagnostics.aorticAnnulusCalcification')"
            ></WDropdown>
        </div>
        <div class="col-12 md:col-6 field">
            <WDropdown
                :disabled="disabled"
                :items="diseaseSeverities"
                v-model="model.aorticLeafletsCuspsCalcificationId"
                optionLabel="nameBg"
                :label="t('proceduralDiagnostics.aorticLeafletsCuspsCalcification')"
            ></WDropdown>
        </div>
    </div>
    <div class="grid">
        <div class="col-12 md:col-4 field">
            <WInputNumberCheckbox
                :disabled="disabled"
                v-model="model.lvotDiameter"
                :label="t('proceduralDiagnostics.lVOTDiameter')"
            />
        </div>
        <div class="col-12 md:col-4 field">
            <WDropdown
                :disabled="disabled"
                :items="diseaseSeverities"
                v-model="model.lvotCalcificationId"
                optionLabel="nameBg"
                :label="t('proceduralDiagnostics.lVOTCalcification')"
            ></WDropdown>
        </div>
        <div
            class="col-12 md:col-4 field"
            v-if="model.valveMorphologyId === AorticValveMorphology.Bicuspid"
        >
            <WDropdown
                :disabled="disabled"
                :items="diseaseSeverities"
                v-model="model.rapheCalcificationId"
                optionLabel="nameBg"
                :label="t('proceduralDiagnostics.rapheCalcification')"
            ></WDropdown>
        </div>
    </div>
    <div class="grid">
        <div class="col-12 md:col-4 field">
            <WDropdown
                :disabled="disabled"
                :items="valveDiseaseAetiologies"
                v-model="model.valveDiseaseAetiologyId"
                optionLabel="nameBg"
                :label="t('proceduralDiagnostics.aorticValveDiseaseAetiology')"
            ></WDropdown>
        </div>
        <div class="col-12 md:col-4 field">
            <WDropdown
                :disabled="disabled"
                :items="diseaseSeverities"
                v-model="model.mitralRegurgitationIdDiseaseSeverityId"
                optionLabel="nameBg"
                :label="t('followUp.mitralRegurgitation')"
            ></WDropdown>
        </div>
        <div
            class="col-12 md:col-4 field"
            v-if="
                model.mitralRegurgitationIdDiseaseSeverityId !== MitralRegurgitation.No &&
                model.mitralRegurgitationIdDiseaseSeverityId !== MitralRegurgitation.Unknown
            "
        >
            <WDropdown
                :disabled="disabled"
                :items="valveRegurgitationTypes"
                v-model="model.valveRegurgitationTypeId"
                :is-required="true"
                optionLabel="nameBg"
                :label="t('proceduralDiagnostics.mitralRegurgitationType')"
            ></WDropdown>
        </div>
    </div>
    <div class="grid">
        <div class="col-12 md:col-4 field">
            <WDropdown
                :disabled="disabled"
                :items="diseaseSeverities"
                v-model="model.mitralStenosisId"
                :is-required="true"
                optionLabel="nameBg"
                :label="t('proceduralDiagnostics.mitralStenosis')"
            ></WDropdown>
        </div>
        <div class="col-12 md:col-4 field">
            <WDropdown
                :disabled="disabled"
                :items="tricuspidRegurgitationDiseaseSeverities"
                v-model="model.diseaseSeverityId"
                :is-required="true"
                optionLabel="nameBg"
                :label="t('proceduralDiagnostics.tricuspidRegurgitation')"
            ></WDropdown>
        </div>
        <div class="col-12 md:col-4 field">
            <WInputNumberCheckbox
                :disabled="disabled"
                v-model="model.paSystolicPressure"
                :is-required="true"
                :label="t('proceduralDiagnostics.pASystolicPressure')"
            />
        </div>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { onMounted, ref, watch } from 'vue';
import WDropdown from '@/components/wrappers/form/WDropdown.vue';
import WMultiSelect from '@/components/wrappers/form/WMultiSelect.vue';
import WInputNumberCheckbox from '@/components/wrappers/form/WInputNumberCheckbox.vue';
import { ProceduralDiagnosticEchocardiographyDto } from '@/dto/proceduralDiagnostics/proceduralDiagnosticEchocardiographyDto';
import { dropdownService } from '@/services/dropdown/dropdown.service';
import { AorticValveMorphology } from '@/enums/aorticValveMorphology';
import { MitralRegurgitation } from '@/enums/mitralRegurgitation';

const props = defineProps({
    modelValue: ProceduralDiagnosticEchocardiographyDto,
    disabled: {
        type: Boolean,
        default: false,
    },
    diseaseSeverities: Array,
    leftVentricularEjectionFraction: Array,
});

/* const emit = defineEmits(['update:modelValue']); */
const { t } = useI18n();
const model = ref(props.modelValue);
const valveMorphology = ref([]);
const bicuspidAorticValveType = ref([]);
const bicuspidAorticValveCuspFusion = ref([]);
const valveDiseaseAetiologies = ref([]);
const valveRegurgitationTypes = ref([]);
const tricuspidRegurgitationDiseaseSeverities = ref([]);

const getValveMorphologies = async () => {
    valveMorphology.value = (await dropdownService.getValveMorphologies()).data;
};

const getBicuspidAorticValveType = async () => {
    bicuspidAorticValveType.value = (await dropdownService.getBicuspidAorticValveType()).data;
};

const getBicuspidAorticValveCuspFusion = async () => {
    bicuspidAorticValveCuspFusion.value = (await dropdownService.getValveCuspFusions()).data;
};

const getValveDiseaseAetiologies = async () => {
    valveDiseaseAetiologies.value = (await dropdownService.getValveDiseaseAetiologies()).data;
};

const getValveRegurgitationTypes = async () => {
    valveRegurgitationTypes.value = (await dropdownService.getValveRegurgitationTypes()).data;
};

const getTricuspidRegurgitationDiseaseSeverities = async () => {
    tricuspidRegurgitationDiseaseSeverities.value = (
        await dropdownService.getDiseaseSeverities(true)
    ).data;
};

/* watch(
    () => model.value,
    (val) => {
        emit('update:modelValue', val);
    }
);

watch(
    () => props.modelValue,
    (val) => {
        model.value = val;
    }
); */

watch(
    () => model.value.valveMorphologyId,
    (val) => {
        if (val !== AorticValveMorphology.Bicuspid) {
            model.value.bicuspidAorticValveTypeId = null;
            model.value.bicuspidAorticValves = null;
            model.value.rapheCalcificationId = null;
        }
    }
);

watch(
    () => model.value.mitralRegurgitationIdDiseaseSeverityId,
    (val) => {
        if (val === MitralRegurgitation.No || val === MitralRegurgitation.Unknown) {
            model.value.valveRegurgitationTypeId = null;
        }
    }
);

onMounted(async () => {
    getValveMorphologies();
    getBicuspidAorticValveType();
    getBicuspidAorticValveCuspFusion();
    getValveDiseaseAetiologies();
    getValveRegurgitationTypes();
    getTricuspidRegurgitationDiseaseSeverities();
});
</script>
