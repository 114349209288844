import { httpService } from "@/services/http.service";

export const doctorService = {
    async getCurrentUserDoctor() {
        return httpService.get('/doctor/GetCurrentUserDoctor');
    },
    async getHospitalDoctors() {
        return httpService.get(`/doctor/GetCurrentUserHospitalDoctors`);
    },
    async getAll() {
        return httpService.get('/doctor')
    }
}
