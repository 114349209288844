import { GeneralPatientClinicalDataDto } from '@/dto/generalPatientClinicalData/generalPatientClinicalDataDto';
import { DischargeDetailsDto } from '@/dto/dischargeDetails/dischargeDetailsDto';
import { TaviDto } from '@/dto/tavi/taviDto';
import { ProcedureComplicationDto } from '@/dto/procedureComplication/procedureComplicationDto';
import { FollowUpExamDto } from '@/dto/followUp/followUpExamDto';
import { CouncilDto } from '@/dto/council/councilDto';
import { PreviousProcedureDto } from '@/dto/previousProcedure/previousProcedureDto';
import { ProceduralDiagnosticsDto } from '@/dto/proceduralDiagnostics/proceduralDiagnosticsDto';

export class TaviProcedureDto {
    constructor(obj = {}) {
        this.id = obj.id;
        this.date = obj.date;
        this.patientId = obj.patientId;
        this.historyOfDeseaseNumber = obj.historyOfDeseaseNumber || '00123/2023';
        this.generalPatientClinicalData = new GeneralPatientClinicalDataDto(
            obj.generalPatientClinicalData || {}
        );
        this.dischargeDetails = new DischargeDetailsDto(obj.dischargeDetails || {});
        this.tavi = new TaviDto(obj.tavi || {});
        this.procedureComplications = new ProcedureComplicationDto(
            obj.procedureComplications || {}
        );
        this.followUpExams = [];
        if (obj.followUpExams) {
            obj.followUpExams.forEach((x) => {
                this.followUpExams.push(new FollowUpExamDto(x || {}));
            });
        }
        this.council = new CouncilDto(obj.council || {});
        this.previousProcedure = new PreviousProcedureDto(obj.previousProcedure || {});
        this.proceduralDiagnostic = new ProceduralDiagnosticsDto(obj.proceduralDiagnostic || {});
    }
}
