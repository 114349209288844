<template>
    <div>
        <label
            class="block font-medium mb-2"
            :class="{ 'p-error': v$.selectedValue.$invalid && v$.$dirty, required: isRequired }"
        >
            {{ label }}
        </label>
        <MultiSelect
            v-model="selectedValue"
            :maxSelectedLabels="maxSelectedLabels"
            :filter="filter"
            :disabled="disabled"
            :options="items"
            :option-label="optionLabel"
            :option-value="optionValue"
            :class="v$.selectedValue.$invalid && v$.$dirty ? `p-invalid` : 'text-primary'"
            class="w-full text-primary"
            input-class="w-full"
            :editable="true"
            @update:modelValue="$emit('update:modelValue', selectedValue)"
            :selectedItemsLabel="`{0} ${t('selectedItems')}`"
        />
        <small v-if="v$.selectedValue.$errors.length > 0" class="p-error">
            {{ v$.selectedValue.$errors[0].$message }}
        </small>
    </div>
</template>

<script setup>
import MultiSelect from 'primevue/multiselect';
import { computed, ref, watch } from 'vue';
import { required } from '@/validation/validators';
import { useVuelidate } from '@vuelidate/core';
import { localeStore } from '@/store/locale.store';
import { useI18n } from 'vue-i18n';

const props = defineProps({
    modelValue: Array,
    items: Array,
    maxSelectedLabels: {
        type: Number,
        default: 3,
    },
    filter: {
        type: Boolean,
        default: true,
    },
    optionValue: {
        type: String,
        default: 'id',
    },
    label: String,
    isRequired: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});
const { t } = useI18n();

const optionLabel = computed(() => (localeStore().isBg ? 'nameBg' : 'nameEn'));

const selectedValue = ref(props.modelValue);

const rules = {
    selectedValue: {},
};

if (props.isRequired) {
    Object.assign(rules.selectedValue, { required });
}

const v$ = useVuelidate(rules, { selectedValue });

watch(
    () => props.modelValue,
    (val) => {
        selectedValue.value = val;
    }
);
</script>
