import { httpService } from "@/services/http.service";

export const registeredUsersService = {
    async edit(dto) {
        return httpService.put('/RegisteredUsers', dto);
    },
    async editRoles(dto) {
        return httpService.put('/RegisteredUsers/editRoles', dto);
    }
}
