export class FollowUpEchocardiographyDto {
    constructor(obj) {
        this.id = obj.id;
        this.leftVentricularEjectionFractionId = obj.leftVentricularEjectionFractionId;
        this.aorticRegurgitationDiseaseSeverityId = obj.aorticRegurgitationDiseaseSeverityId;
        this.mitralRegurgitationIdDiseaseSeverityId = obj.mitralRegurgitationIdDiseaseSeverityId;
        this.diseaseSeverityId = obj.diseaseSeverityId;
        this.avMeanGradient = obj.avMeanGradient;
        this.avPeakGradient = obj.avPeakGradient;
        this.aorticValvePathologies = obj.aorticValvePathologies;
    }
}
