import { defineStore } from 'pinia';
import { Locales } from '@/enums/locales';
import { bg, en } from '@/locales/PrimeVue/locales';

const LOCALE_KEY = 'locale';

export const localeStore = defineStore('localeStore', {
    state: () => ({
        current: localStorage.getItem(LOCALE_KEY) || Locales.bg,
    }),
    getters: {
        currentLocale: (state) => state.current,
        isBg: (state) => state.current === Locales.bg,
    },
    actions: {
        setLocale(newLocale) {
            this.current = newLocale;
            localStorage.setItem(LOCALE_KEY, newLocale);
        },
    },
});
