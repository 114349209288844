import { createRouter, createWebHistory } from 'vue-router';
import { routes } from '@/routes/routes';
import { userStore } from '@/store/user.store';

export const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    //const { authorize, claims } = to.meta;

    if (!userStore().isAuthenticated) {
        const freePaths = ['/login', '/register'];

        if (freePaths.includes(to.path)) {
            next();
            return;
        } else {
            if (to.path.includes('login')) {
                next();
            } else {
                const returnUrl = to.fullPath;
                if (returnUrl && returnUrl.indexOf('login') > -1) {
                    next(`/login`); // returnUrl от към login. получава се зацикляна
                } else {
                    next(`/login?returnUrl=${to.fullPath}`);
                }
            }
            return;
        }
    } else {
        if (!userStore().approvedUser && to.path != '/auth/waiting') {
            next('/auth/waiting');
        } else {
            next();
        }
    }

    /*if (authorize || (claims && claims.length)) {
        // The route needs authorization
        let isUserInRole = false;
        if (authorize) {
            const userRoles = userStore.userRoles;
            userRoles.forEach((userRole) => {
                if (authorize.includes(userRole)) {
                    isUserInRole = true;
                }
            })
        }

        let hasClaim = false;
        if (claims) {
            hasClaim = claims.some((claim) => {
                return userStore.claims[claim.name][claim.crud] === true;
            });
        }

        if (!isUserInRole && !hasClaim) {
            next('/unauthorized');
        }
    }*/
});
