export class RegisterUserDto {
    constructor(email, userName, password, confirmPassword, firstName, lastName, roleName, hospitalId) {
        this.email = email
        this.userName = userName
        this.password = password
        this.confirmPassword = confirmPassword
        this.firstName = firstName
        this.lastName = lastName
        this.roleName = roleName
        this.hospitalId = hospitalId
    }
}
