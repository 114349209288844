import { httpService } from '@/services/http.service';
import { Genders } from '@/enums/genders';

export const patientService = {
    async create(dto) {
        return httpService.post('/patient', dto);
    },
    async edit(dto) {
        return httpService.put('/patient', dto);
    },
    async getAllByFilterModelCriteria(dto) {
        return httpService.get(
            `patient/getPatientBySearchCriteria?id=${dto.id}&firstName=${dto.firstName}&middleName=${dto.middleName}&lastName=${dto.lastName}&personIdentityNumber=${dto.personIdentityNumber}`
        );
    },
    async getById(id) {
        return httpService.get(`/patient/${id}`);
    },
    async delete(id) {
        return httpService.delete(`/patient/${id}`);
    },
    async getFromApi(apiUrl, identityNumber) {
        return httpService.get(`${apiUrl}/${identityNumber}`);
    },
    async isExistingPatient(identityNumber) {
        return httpService.get(`/patient/isExistingPatient/${identityNumber}`);
    },
    getDateFromIdentityNumber(personIdentityNumber) {
        if (!personIdentityNumber || personIdentityNumber.length != 10) {
            return null;
        }

        let year = parseInt(personIdentityNumber.substring(0, 2));
        let month = parseInt(personIdentityNumber.substring(2, 4));
        // Месеците в JS започват от 0
        month--;

        if (month > 40) {
            month -= 40;
            year += 2000;
        } else if (month > 20) {
            month -= 20;
            year += 1800;
        } else {
            year += 1900;
        }

        const day = parseInt(personIdentityNumber.substring(4, 6));
        let dayStr = `${day}`;
        if (day < 10) {
            dayStr = `0${dayStr}`;
        }
        let monthStr = `${month}`;
        if (month < 10) {
            monthStr = `0${monthStr}`;
        }

        return new Date(year, month, day);
    },
    getGenderIdFromIdentityNumber(personIdentityNumber) {
        if (!personIdentityNumber || personIdentityNumber.length != 10) {
            return null;
        }

        // Ако 9-тата цифра е четна, значи е мъж
        const ninthDigit = parseInt(personIdentityNumber.charAt(8));
        const isMale = ninthDigit % 2 === 0;

        if (isMale) {
            return Genders.Male;
        } else {
            return Genders.Female;
        }
    },
};
