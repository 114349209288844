<template>
    <div class="loginForm surface-card p-4 shadow-2 border-round w-full lg:w-6">
        <div>
            <WInputText
                v-model="loginDto.email"
                type="email"
                :is-required="true"
                :label="$t('email')"
            ></WInputText>

            <WPassword
                v-model="loginDto.password"
                :is-required="true"
                :label="$t('password')"
                type="password"
                @keydown.enter="login"
            ></WPassword>


            <div class="flex align-items-center justify-content-between mb-6">
                <router-link
                    to="/register"
                    class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer"
                >
                    {{ $t('register') }}
                </router-link>
                <!-- <a class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">Forgot password?</a>-->
            </div>

            <WButton
                @click="login"
                :label="$t('login')"
                icon="pi pi-user"
                class="w-full"
            ></WButton>
        </div>
        <WLoader :is-loading="loading"></WLoader>
    </div>
</template>

<script setup>
import WInputText from '@/components/wrappers/form/WInputText.vue';
import WPassword from "@/components/wrappers/form/WPassword.vue";
import WButton from '@/components/wrappers/button/WButton.vue';
import { ref } from 'vue';
import { UserLoginDto } from '@/dto/auth/userLoginDto';
import { authService } from '@/services/auth/auth.service';
import { userStore } from '@/store/user.store';
import { useVuelidate } from '@vuelidate/core';
import { useToast } from 'primevue/usetoast';
import WLoader from '@/components/wrappers/loaderSpinner/WLoader.vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from "vue-i18n";

const loginDto = ref(new UserLoginDto());

const v$ = useVuelidate();
const toast = useToast();
const { t } = useI18n();
const router = useRouter();
const route = useRoute();

const loading = ref(false);

const login = async () => {
    await v$.value.$validate();
    if (v$.value.$errors.length > 0) {
        return;
    }

    loading.value = true;
    try {
        const tokenData = await authService.login(loginDto.value);
        userStore().authenticateUser(tokenData.data);
        if (userStore().approvedUser) {
            const { returnUrl } = route.query;
            router.push({ path: returnUrl || '/' });
        } else {
            router.push('/auth/waiting')
        }
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: t('notifications.error'),
            detail: t('notifications.loginError'),
            life: 3000,
        });
    } finally {
        loading.value = false;
    }
};
</script>

<style scoped>
.loginForm {
    max-width: 600px;
}
</style>
