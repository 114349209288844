export class CouncilDto {
    constructor(obj) {
        this.date = obj.date;
        this.nyhaid = obj.nyhaid;
        this.preProceduralCirculatorySupportId = obj.preProceduralCirculatorySupportId;
        this.activeEndocarditisId = obj.activeEndocarditisId;
        this.stsriskScore = obj.stsriskScore;
        this.euroScore = obj.euroScore;
        this.euroScoreIi = obj.euroScoreIi;
        this.conclusion = obj.conclusion;
        this.implantableCardiacDevices = obj.implantableCardiacDevices;
        this.circulatorySupportTypes = obj.circulatorySupportTypes;
        this.anginaSymptoms = obj.anginaSymptoms;
    }
}
