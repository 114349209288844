<template>
    <div>
        <h1>{{ t('registeredUsers.allRegisteredUsers') }}</h1>
        <WButton
            v-if="isSysAdmin"
            :label="t('users.newRegistration')"
            class="mb-3"
            @click="showCreateUserDialog = true"
        ></WButton>
        <WDataTable
            :columns="columns"
            :action-buttons="actionButtons"
            ref="dataTable"
            api-url="/RegisteredUsers/getAll"
        ></WDataTable>
        <EditRolesDialog
            v-model="showEditRolesDialog"
            :selected-user="selectedUser"
            :roles="roles"
            @refreshDataTable="refreshDataTable"
        ></EditRolesDialog>
        <CreateUserDialog
            v-model="showCreateUserDialog"
            @refreshDataTable="refreshDataTable"
        ></CreateUserDialog>
        <ChangeUserPasswordDialog
            v-model="showChangeUserPasswordDialog"
            :selectedUser="selectedUser"
        ></ChangeUserPasswordDialog>
    </div>
    <WLoader :is-loading="loading"></WLoader>
</template>

<script setup>
import WDataTable from '@/components/wrappers/data/data-table/WDataTable.vue';
import WButton from '@/components/wrappers/button/WButton.vue';
import WLoader from '@/components/wrappers/loaderSpinner/WLoader.vue';
import CreateUserDialog from '@/components/admin/CreateUserDialog.vue';
import { useToast } from 'primevue/usetoast';
import { ActionButtonModel } from '@/components/wrappers/data/data-table/models/actionButtonModel';
import { DataTableColumnType } from '@/components/wrappers/data/data-table/models/dataTableColumnType';
import { ref, computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { Roles } from '@/enums/roles';
import { userStore } from '@/store/user.store';
import ChangeUserPasswordDialog from '@/components/admin/ChangeUserPasswordDialog.vue';
import EditRolesDialog from '@/components/admin/EditRolesDialog.vue';
import { dropdownService } from '@/services/dropdown/dropdown.service';

const { t } = useI18n();
const toast = useToast();
const loading = ref(false);
const selectedUser = ref(null);
const dataTable = ref(null);
const showEditRolesDialog = ref(false);
const showCreateUserDialog = ref(false);
const showChangeUserPasswordDialog = ref(false);

const roles = ref([]);

const columns = computed(() => [
    {
        field: 'firstName',
        header: t('person.firstName'),
        dataType: DataTableColumnType.text,
        sortable: true,
        filterable: true,
    },
    {
        field: 'lastName',
        header: t('person.lastName'),
        dataType: DataTableColumnType.text,
        sortable: true,
        filterable: true,
    },
    { field: 'email', header: t('email'), dataType: DataTableColumnType.text, filterable: true },
    {
        field: 'rolesNames',
        header: t('roles.name'),
        sortable: true,
        dataType: DataTableColumnType.text,
        filterable: true,
    },
    {
        field: 'hospitalName',
        header: t('hospital.hospital'),
        dataType: DataTableColumnType.text,
        sortable: true,
        filterable: true,
    },
    {
        field: 'createdDate',
        header: t('registeredUsers.date'),
        dataType: DataTableColumnType.date,
        sortable: true,
        filterable: true,
    },
]);

const isSysAdmin = computed(() => {
    return userStore().isUserInRole(Roles.SysAdmin);
});

const actionButtons = computed(() => [
    new ActionButtonModel('pi pi-unlock', t('users.editRoles'), editRoles),
    new ActionButtonModel('pi pi-key', t('changePassword'), changeUserPassword),
]);

const editRoles = (user) => {
    selectedUser.value = user;
    showEditRolesDialog.value = true;
};

const changeUserPassword = async (user) => {
    selectedUser.value = user;
    showChangeUserPasswordDialog.value = true;
};

const refreshDataTable = () => {
    dataTable.value.refresh();
};

const getAllRoles = async () => {
    roles.value = (await dropdownService.getAllRoles()).data;
};

onMounted(async () => {
    getAllRoles();
});
</script>
