<template>
    <Accordion>
        <AccordionTab>
            <template #header>
                <i class="pi pi-sitemap" style="font-size: 1.4rem; font-weight: 500"></i>
                <span class="ml-2" style="font-size: 1.4rem; font-weight: 700">{{
                    t('taviProcedure.devices')
                }}</span>
                <Badge
                    v-if="v$.$errors.length"
                    :value="v$.$errors.length"
                    severity="danger"
                    size="large"
                    class="ml-1"
                />
            </template>
            <div v-if="!disabled" class="grid">
                <WButton
                    :disabled="disabled"
                    :label="t('taviProcedure.addDevices')"
                    icon="pi pi-plus"
                    class="mb-4"
                    @click="addNewDeviceToModel"
                ></WButton>
            </div>
            <Card
                v-for="(item, index) in model"
                :key="index"
                style="width: 100%; margin-bottom: 2em"
            >
                <template #title>
                    <span style="font-size: medium">{{ t('taviProcedure.device') }}</span>
                    <WButton
                        :disabled="disabled"
                        @click="removeDeviceFromModel(index)"
                        class="pi pi-trash"
                        severity="danger"
                        text
                        css-class
                        style="float: right"
                        v-tooltip.left="t('buttons.delete')"
                    ></WButton>
                </template>
                <template #content>
                    <div class="grid">
                        <div class="col-12 md:col-4 field">
                            <WDropdown
                                :disabled="disabled"
                                :items="deviceModel"
                                v-model="item.deviceModelId"
                                :is-required="true"
                                optionLabel="nameBg"
                                :label="t('taviProcedure.deviceModel')"
                            ></WDropdown>
                        </div>
                        <div class="col-12 md:col-4 field">
                            <WInputNumberCheckbox
                                :disabled="disabled"
                                v-model="item.diameter"
                                :is-required="true"
                                :label="t('taviProcedure.diameter')"
                            />
                        </div>
                    </div>
                    <div class="grid">
                        <div class="col-12 md:col-4 field">
                            <WSelectButton
                                :disabled="disabled"
                                :items="selectButtonItems"
                                v-model="item.successfullImplantedId"
                                :label="t('taviProcedure.successfullyImplanted')"
                            />
                        </div>
                        <div
                            v-if="item.successfullImplantedId === SelectButtonValues.No"
                            class="col-12 md:col-4 field"
                        >
                            <WDropdown
                                :disabled="disabled"
                                :items="aorticValvePathologies"
                                v-model="item.unsuccessfulImplantationReasonId"
                                optionLabel="nameBg"
                                :label="t('taviProcedure.unsuccessfulImplantationReason')"
                            ></WDropdown>
                        </div>
                        <div class="col-12 md:col-4 field">
                            <WInputText
                                :disabled="disabled"
                                v-model="item.valveSerialNumber"
                                :label="t('taviProcedure.valveSerialNumber')"
                            />
                        </div>
                    </div>
                </template>
            </Card>
        </AccordionTab>
    </Accordion>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { onMounted, ref, watch } from 'vue';
import { TaviDeviceCreateDto } from '@/dto/taviDevice/taviDeviceCreateDto';
import WButton from '@/components/wrappers/button/WButton.vue';
import WInputText from '@/components/wrappers/form/WInputText.vue';
import WDropdown from '@/components/wrappers/form/WDropdown.vue';
import WSelectButton from '@/components/wrappers/button/WSelectButton.vue';
import { SelectButtonValues } from '@/enums/selectButtonValues';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import { dropdownService } from '@/services/dropdown/dropdown.service';
import Card from 'primevue/card';
import WInputNumberCheckbox from '@/components/wrappers/form/WInputNumberCheckbox.vue';
import { useVuelidate } from '@vuelidate/core';
import Badge from 'primevue/badge';

const emit = defineEmits(['addNewDevice', 'removeDevice']);

const props = defineProps({
    modelValue: TaviDeviceCreateDto,
    disabled: {
        type: Boolean,
        default: false,
    },
    aorticValvePathologies: Array,
    selectButtonItems: Array,
});

const { t } = useI18n();
const model = ref(props.modelValue);
const deviceModel = ref([]);
const v$ = useVuelidate();

const addNewDeviceToModel = () => {
    emit('addNewDevice', new TaviDeviceCreateDto({}));
};

const removeDeviceFromModel = (index) => {
    emit('removeDevice', index);
};

const getDeviceModel = async () => {
    deviceModel.value = (await dropdownService.getDeviceModel()).data;
};

watch(
    () => model.value,
    (val) => {
        val.forEach((x) => {
            if (x.successfullImplantedId !== SelectButtonValues.No) {
                x.unsuccessfulImplantationReasonId = null;
            }
        });
    },
    { deep: true }
);

onMounted(async () => {
    getDeviceModel();
});
</script>
