export class ProceduralDiagnosticEchocardiographyDto {
    constructor(obj) {
        this.id = obj.id;
        this.leftVentricularEjectionFractionId = obj.leftVentricularEjectionFractionId;
        this.avMeanGradient = obj.avMeanGradient;
        this.avPeakVelocity = obj.avPeakVelocity;
        this.avArea = obj.avArea;
        this.aorticRegurgitationDiseaseSeverityId = obj.aorticRegurgitationDiseaseSeverityId;
        this.valveMorphologyId = obj.valveMorphologyId;
        this.bicuspidAorticValveTypeId = obj.bicuspidAorticValveTypeId;
        this.bicuspidAorticValves = obj.bicuspidAorticValves;
        this.aorticAnnulusArea = obj.aorticAnnulusArea;
        this.aorticAnnulusPerimeter = obj.aorticAnnulusPerimeter;
        this.aorticAnnulusDiameter = obj.aorticAnnulusDiameter;
        this.aorticAnnulusCalcificationDiseaseSeverityId =
            obj.aorticAnnulusCalcificationDiseaseSeverityId;
        this.aorticLeafletsCuspsCalcificationId = obj.aorticLeafletsCuspsCalcificationId;
        this.lvotCalcificationId = obj.lvotCalcificationId;
        this.lvotDiameter = obj.lvotDiameter;
        this.rapheCalcificationId = obj.rapheCalcificationId;
        this.valveDiseaseAetiologyId = obj.valveDiseaseAetiologyId;
        this.mitralRegurgitationIdDiseaseSeverityId = obj.mitralRegurgitationIdDiseaseSeverityId;
        this.valveRegurgitationTypeId = obj.valveRegurgitationTypeId;
        this.mitralStenosisId = obj.mitralStenosisId;
        this.diseaseSeverityId = obj.diseaseSeverityId;
        this.paSystolicPressure = obj.paSystolicPressure;
    }
}
