<template>
    <div class="grid">
        <WInputText
            v-model="dto.firstName"
            class="col-12 md:col-4"
            :max-length="250"
            :isRequired="true"
            :label="t('patient.name')"
        >
        </WInputText>
        <WInputText
            v-model="dto.middleName"
            class="col-12 md:col-4"
            :is-required="false"
            :max-length="250"
            :label="t('patient.middleName')"
        >
        </WInputText>
        <WInputText
            v-model="dto.lastName"
            :isRequired="true"
            class="col-12 md:col-4"
            :max-length="250"
            :label="t('patient.lastName')"
        >
        </WInputText>
        <PersonIdentity
            v-model="dto"
            :isInvalidPersonalCivilNumber="invalidPersonalCivilNumber"
            @setValidityCivilNumber="onSetCivilNumber"
            class="col-12 md:col-4"
        >
        </PersonIdentity>
        <div class="col-12 md:col-4 my-2">
            <WDateTimePicker
                v-model="dto.birthDate"
                :label="t('patient.dateOfBirth')"
            ></WDateTimePicker>
        </div>
        <WDropdown
            v-model="dto.genderId"
            class="my-2 col-12 md:col-4"
            :items="genders"
            :label="t('patient.gender')"
            :editable="false"
        >
        </WDropdown>
        <!-- TODO: Къде е това :max-length? -->
        <WInputText
            v-model="dto.email"
            class="col-12 md:col-4"
            :max-length="250"
            :label="t('email')"
        >
        </WInputText>
        <WInputText
            v-model="dto.phoneNumber"
            class="col-12 md:col-4"
            type="tel"
            inputmode="tel"
            :max-length="20"
            :label="t('patient.phoneContact')"
        >
        </WInputText>
    </div>
    <WButton :label="t('buttons.save')" icon="pi pi-save" @click="edit"></WButton>
    <WLoader :isLoading="loading" />
</template>

<script setup>
import { ref, onMounted } from 'vue';
import WInputText from '@/components/wrappers/form/WInputText.vue';
import WButton from '@/components/wrappers/button/WButton.vue';
import WLoader from '@/components/wrappers/loaderSpinner/WLoader.vue';
import PersonIdentity from '@/components/personIdentity/PersonIdentity.vue';
import { PatientCreateDto } from '@/dto/patient/patientCreateDto';
import { patientService } from '@/services/patient/patient.service';
import { useVuelidate } from '@vuelidate/core';
import { useToast } from 'primevue/usetoast';
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import WDateTimePicker from '@/components/wrappers/dateTime/WDateTimePicker.vue';
import { dropdownService } from '@/services/dropdown/dropdown.service';
import WDropdown from "@/components/wrappers/form/WDropdown.vue";

const dto = ref(new PatientCreateDto());
const route = useRoute();
const router = useRouter();
const v$ = useVuelidate();
const toast = useToast();
const loading = ref(false);
const { t } = useI18n();
const invalidPersonalCivilNumber = ref(false);

const getPatientData = async (patientId) => {
    try {
        loading.value = true;
        dto.value = (await patientService.getById(patientId)).data;
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: t('notifications.error'),
            detail: t('notifications.somethingGoneWrong'),
            life: 2000,
        });
    } finally {
        loading.value = false;
    }
};

const edit = async () => {
    await v$.value.$validate();
    if (v$.value.$errors.length > 0) {
        return;
    }

    loading.value = true;
    try {
        await patientService.edit(dto.value);
        toast.add({
            severity: 'success',
            summary: t('notifications.success'),
            detail: t('notifications.successfulEdit'),
            life: 2000,
        });
        router.push({ path: '/patient/list' });
    } catch (error) {
        if(error.response.data === "Existing personalCivilNumber"){
            invalidPersonalCivilNumber.value = true;
        }

        toast.add({
            severity: 'error',
            summary: t('notifications.error'),
            detail: invalidPersonalCivilNumber.value ? t('validations.uniquePersonIdentifierExists') : t('notifications.errorEdit'),
            life: 2000,
        });
    } finally {
        loading.value = false;
    }
};

const genders = ref([]);
const loadGenders = async () => {
    genders.value = (await dropdownService.getGender()).data;
};

const onSetCivilNumber = (val) => {
    invalidPersonalCivilNumber.value = val;
};

onMounted(async () => {
    const patientId = route.params.id;
    if (patientId) {
        getPatientData(patientId);
        loadGenders();
    }
});
</script>
