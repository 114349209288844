import axios from 'axios';
/*import helpers from '../helpers/helpers'
import i18n from '../language';
import { router } from '../router/router';*/

import { userStore } from '@/store/user.store';
import { router } from "@/router/router";
import { UrlConfig } from "@/config/urlConfig";

axios.interceptors.request.use(config => {
    // TODO: да се сетва автоматично
    //config.baseURL = UrlConfig.baseUrl;
    //config.baseURL = 'https://localhost:5001/api';
    //config.baseURL = 'https://etavi.azurewebsites.net/api';
    //config.baseUrl = 'https://tavi.bsetbg.com/api';
    config.baseURL = 'https://tavi.bsetbg.com/api';
    //config.baseURL = 'https://etavi-test.azurewebsites.net/api';
    //config.baseURL = 'https://apptest.kontrax.bg/TAVI/api';

    config.headers["Content-Type"] = "application/json";

    // Auth token
    const token = userStore().authToken;
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
});

// Add a 401 response interceptor
axios.interceptors.response.use(response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (!error) {
        return Promise.reject(error);
    }

    // TODO:
    /*if (!error.response) {
        const regex = /Network Error/gi;
        const found = error.toString().match(regex);
        if (found) {
            error = {
                response: { statusText: i18n.t('responseErrors.networkError') }
            }
        }
        return Promise.reject(error);
    }*/

    if (error.response.status) {
        const returnUrl = router.currentRoute.fullPath;
        switch (error.response.status) {
            case 400:
                // TODO:
                break;
            case 401:
                if (returnUrl && returnUrl.indexOf('login') > -1) {
                    router.push({ name: 'login' }); // returnUrl от към login. получава се зацикляне
                } else {
                    router.push({ name: 'login', query: { returnUrl: `${router.currentRoute.fullPath}` } });
                }
                break;
            case 403:
                // TODO:
                break;
            default:
        }
    }

    return Promise.reject(error);
});

export const httpService = {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
    patch: axios.patch,
    all: axios.all
}
