import { httpService } from "@/services/http.service";

export const addressService = {
    async getAreas() {
        return httpService.get('/area');
    },
    async getMunicipalitiesForArea(areaId) {
        return httpService.get(`/municipality/ByArea/${areaId}`);
    },
    async getCitiesForMunicipality(municipalityId) {
        return httpService.get(`/city/ByMunicipality/${municipalityId}`);
    }
}
