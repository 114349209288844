<template>
    <div class="grid">
        <div class="col-6">
            <WInputText
                v-model="contactPersonDto.firstName"
                :disabled="disabled"
                :label="$t('hospital.contactPersonFirstName')"
                :is-required="true"
            ></WInputText>
        </div>
        <div class="col-6">
            <WInputText
                v-model="contactPersonDto.lastName"
                :disabled="disabled"
                :label="$t('hospital.contactPersonLastName')"
                :is-required="true"
            ></WInputText>
        </div>
        <div class="col-6">
            <WInputText
                v-model="contactPersonDto.phoneNumber"
                :disabled="disabled"
                :label="$t('phone')"
                type="tel"
                inputmode="tel"
            ></WInputText>
        </div>
        <div class="col-6">
            <WInputText
                v-model="contactPersonDto.email"
                :disabled="disabled"
                :label="$t('email')"
                :is-required="true"
            ></WInputText>
        </div>
    </div>
</template>

<script setup>
import WInputText from '@/components/wrappers/form/WInputText.vue';
import { ContactPersonDto } from '@/dto/person/contactPersonDto';
import { ref } from 'vue';

const props = defineProps({
    modelValue: ContactPersonDto,
    isRequired: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});

const contactPersonDto = ref(props.modelValue);
</script>
