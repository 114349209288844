<template>
    <DataTable
        v-model:expandedRows="expandedRows"
        :value="nomenclatureTables"
        :loading="loadingNomenclatures"
        dataKey="id"
        :rows="10"
        :paginator="true"
        @rowExpand="onRowExpand"
        @rowCollapse="onRowCollapse"
    >
        <Column expander style="width: 5rem" />
        <Column field="nameBg" :header="$t('nomenclature.nameBg')"></Column>
        <Column field="nameEn" :header="$t('nomenclature.nameEn')"></Column>
        <template #expansion="slotProps">
            <div class="p-3">
                <WButton
                    :label="t('nomenclature.new')"
                    icon="pi pi-plus"
                    @click="
                        showAddNomenclatureItemDialog(
                            $event,
                            slotProps.data.entityTypeName,
                            slotProps.data.nomenclatureItems
                        )
                    "
                ></WButton>
                <DataTable
                    :value="slotProps.data.nomenclatureItems"
                    :loading="slotProps.data.loading"
                    tableClass="editable-cells-table"
                    editMode="cell"
                    tableStyle="min-width: 50rem"
                    @cell-edit-complete="onCellEditComplete($event, slotProps.data.entityTypeName)"
                >
                    <Column field="nameBg" :header="$t('nomenclature.nameBg')" sortable>
                        <template #body="{ data, field }">
                            {{ data[field] }}
                        </template>
                        <template #editor="{ data, field }">
                            <WInputText v-model="data[field]" autofocus></WInputText>
                        </template>
                    </Column>
                    <Column field="nameEn" :header="$t('nomenclature.nameEn')" sortable>
                        <template #body="{ data, field }">
                            {{ data[field] }}
                        </template>
                        <template #editor="{ data, field }">
                            <WInputText v-model="data[field]" autofocus></WInputText>
                        </template>
                    </Column>
                    <Column field="validFrom" :header="$t('nomenclature.validFrom')" sortable>
                        <template #body="{ data, field }">
                            {{ Formatters.date(data[field]) }}
                        </template>
                        <template #editor="{ data, field }">
                            <WDateTimePicker v-model="data[field]" autofocus></WDateTimePicker>
                        </template>
                    </Column>
                    <Column field="validTo" :header="$t('nomenclature.validTo')" sortable>
                        <template #body="{ data, field }">
                            {{ Formatters.date(data[field]) }}
                        </template>
                        <template #editor="{ data, field }">
                            <WDateTimePicker v-model="data[field]" autofocus></WDateTimePicker>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </template>
    </DataTable>
    <Dialog
        v-model:visible="showAddNomenclatureDialog"
        modal
        header="Одобряване на потребител"
        @hide="onAddNomenclatureDialogHide"
    >
        <div class="mb-3">
            <div>
                <WInputText
                    :is-required="true"
                    :label="$t('nomenclature.nameBg')"
                    v-model="nomenclatureItemToAdd.nameBg"
                ></WInputText>
            </div>
            <div>
                <WInputText
                    :is-required="true"
                    :label="$t('nomenclature.nameEn')"
                    v-model="nomenclatureItemToAdd.nameEn"
                ></WInputText>
            </div>
            <div>
                <WDateTimePicker
                    :label="$t('nomenclature.validFrom')"
                    v-model="nomenclatureItemToAdd.validFrom"
                ></WDateTimePicker>
            </div>
            <div>
                <WDateTimePicker
                    :label="$t('nomenclature.validTo')"
                    v-model="nomenclatureItemToAdd.validTo"
                ></WDateTimePicker>
            </div>
        </div>
        <template #footer>
            <WButton label="Запис" @click="addNewNomenclatureItem"></WButton>
        </template>
    </Dialog>
    <WLoader :is-loading="loading"></WLoader>
</template>

<script setup>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import WInputText from '@/components/wrappers/form/WInputText.vue';
import WDateTimePicker from '@/components/wrappers/dateTime/WDateTimePicker.vue';
import WLoader from '@/components/wrappers/loaderSpinner/WLoader.vue';
import WButton from '@/components/wrappers/button/WButton.vue';
import Dialog from 'primevue/dialog';
import { nomenclatureManagementService } from '@/services/admin/nomenclatureManagement.service';
import { Formatters } from '@/components/wrappers/data/data-table/utils/formatters';
import { onMounted, ref } from 'vue';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';
import { required } from "@/validation/validators";
import { useVuelidate } from "@vuelidate/core";

const loadingNomenclatures = ref(false);
const loading = ref(false);
const nomenclatureTables = ref();
const expandedRows = ref([]);
const showAddNomenclatureDialog = ref(false);
const nomenclatureItemToAdd = ref({});
const entityTypeNameToAdd = ref('');
const nomenclatureItemsRefToAdd = ref([]);

const { t } = useI18n();
const toast = useToast();

const addNomenclatureRules = {
    nomenclatureItemToAdd: {
        nameBg: required,
        nameEn: required
    },
};

const v$ = useVuelidate(addNomenclatureRules, { nomenclatureItemToAdd });

const onRowExpand = async (event) => {
    event.data.loading = true;
    event.data.nomenclatureItems = (
        await nomenclatureManagementService.getNomenclatureItems(event.data.entityTypeName)
    ).data;
    event.data.loading = false;
};

const onCellEditComplete = async (event, entityTypeName) => {
    loading.value = true;
    try {
        let { data, newValue, field } = event;
        data[field] = newValue;
        await nomenclatureManagementService.editNomenclatureItem(data, entityTypeName);
        toast.add({
            severity: 'success',
            summary: t('notifications.success'),
            detail: t('notifications.successfulEdit'),
            life: 2000,
        });
    } catch {
        toast.add({
            severity: 'error',
            summary: t('notifications.error'),
            detail: t('notifications.errorEdit'),
            life: 2000,
        });
    } finally {
        loading.value = false;
    }
};

const addNewNomenclatureItem = async () => {
    await v$.value.$validate();
    if (v$.value.$errors.length > 0) {
        return;
    }

    loading.value = true;
    try {
        const addedNomenclatureItem = (
            await nomenclatureManagementService.addNomenclatureItem(
                nomenclatureItemToAdd.value,
                entityTypeNameToAdd.value
            )
        ).data;
        nomenclatureItemsRefToAdd.value.push(addedNomenclatureItem);
        toast.add({
            severity: 'success',
            summary: t('notifications.success'),
            detail: t('notifications.successfulEdit'),
            life: 2000,
        });
    } catch {
        toast.add({
            severity: 'error',
            summary: t('notifications.error'),
            detail: t('notifications.errorEdit'),
            life: 2000,
        });
    } finally {
        loading.value = false;
        showAddNomenclatureDialog.value = false;
    }
};

const showAddNomenclatureItemDialog = (event, entityTypeName, nomenclatureItems) => {
    entityTypeNameToAdd.value = entityTypeName;
    nomenclatureItemsRefToAdd.value = nomenclatureItems;
    showAddNomenclatureDialog.value = true;
};

const onAddNomenclatureDialogHide = () => {
    nomenclatureItemToAdd.value = {};
    entityTypeNameToAdd.value = '';
    nomenclatureItemsRefToAdd.value = [];
};

const onRowCollapse = (event) => {};

const expandAll = () => {};

const collapseAll = () => {};

const loadNomenclatureTables = async () => {
    loadingNomenclatures.value = true;
    nomenclatureTables.value = (await nomenclatureManagementService.getNomenclatureTables()).data;
    loadingNomenclatures.value = false;
};

onMounted(async () => {
    loadNomenclatureTables();
});
</script>
