<template>
    <div class="w-full">
        <label
            class="block font-medium mb-2"
            :class="{ 'p-error': v$.date.$invalid && v$.$dirty, required: isRequired }"
        >
            {{ label }}
        </label>
        <Calendar
            v-model="date"
            :showTime="showTime"
            :disabled="disabled"
            :class="v$.date.$invalid && v$.$dirty ? `p-invalid` : ''"
            class="w-full"
            :timeOnly="timeOnly"
            :showIcon="showIcon"
            input-class="w-full"
            :dateFormat="format"
        />
        <small v-if="v$.date.$errors.length > 0" class="p-error">
            {{ v$.date.$errors[0].$message }}
        </small>
    </div>
</template>

<script setup>
import Calendar from 'primevue/calendar';
import { computed, ref, watch } from 'vue';
import { required } from '@/validation/validators';
import { useVuelidate } from '@vuelidate/core';
import { localeStore } from '@/store/locale.store';

const emit = defineEmits(['change', 'update:modelValue']);

const props = defineProps({
    modelValue: {
        type: [String, null],
        required: false,
    },
    showIcon: {
        type: Boolean,
        default: true,
    },
    //В календара се добавя и тайм пикър
    showTime: {
        type: Boolean,
        default: false,
    },
    label: String,
    //Kалендара става само тайм пикър
    timeOnly: {
        type: Boolean,
        default: false,
    },
    isRequired: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});

const format = computed(() => {
    return localeStore().isBg ? 'dd.mm.yy г.' : 'dd/mm/yy';
});

const date = ref(props.modelValue ? new Date(props.modelValue) : '');
const rules = {
    date: {},
};

if (props.isRequired) {
    Object.assign(rules.date, { required });
}

const v$ = useVuelidate(rules, { date });

watch(
    () => date.value,
    (val) => {
        if (val !== props.modelValue) {
            const isoDate = val && !isNaN(val.valueOf()) ? val.toISOString() : null;
            emit('update:modelValue', isoDate);
            emit('change', isoDate);
        }
    }
);

watch(
    () => props.modelValue,
    (val) => {
        if (val !== date.value) {
            if (val) {
                date.value = new Date(val);
            } else {
                date.value = '';
            }
        }
    }
);

watch(
    () => props.label,
    () => {
        if (date.value) {
            date.value = new Date(props.modelValue);
        }
    }
);
</script>
