import { createI18n } from 'vue-i18n';
import { Locales } from "@/enums/locales";

function loadLocaleMessages() {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages = {};
    locales.keys().forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key).default;
        }
    });
    return messages;
}

const locale = localStorage.getItem('locale') === Locales.en ? 'en' : 'bg';

export default createI18n({
    legacy: false,
    locale: locale,
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'bg',
    messages: loadLocaleMessages(),
});
