export class ContactPersonDto {
    constructor(obj) {
        this.id = obj.id || null;
        this.firstName = obj.firstName || '';
        this.middleName = obj.middleName || '';
        this.lastName = obj.lastName || '';
        this.phoneNumber = obj.phoneNumber || '';
        this.email = obj.email || '';
    }
}
