export class GeneralPatientClinicalDataDto {
    constructor(obj) {
        this.height = obj.height;
        this.weight = obj.weight;
        this.frailtyId = obj.frailtyId;
        this.diabetesMellitusId = obj.diabetesMellitusId;
        this.hypertensionId = obj.hypertensionId;
        this.chronicObstructivePulmonaryDiseaseId = obj.chronicObstructivePulmonaryDiseaseId;
        this.activeMalignancyId = obj.activeMalignancyId;
        this.currentRenalDialysisId = obj.currentRenalDialysisId;
        this.atrialFibrillationFlutterId = obj.atrialFibrillationFlutterId;
        this.peripheralArterialDiseaseId = obj.peripheralArterialDiseaseId;
        this.porcelainAortaId = obj.porcelainAortaId;
        this.priorStrokeId = obj.priorStrokeId;
        this.coronaryHeartDiseaseId = obj.coronaryHeartDiseaseId;
        this.priorMyocardialInfarctionId = obj.priorMyocardialInfarctionId;
    }
}
