<template>
    <div>
        <WDataTable
        :columns="columns"
        :action-buttons="actionButtons"
        ref="dataTable"
        api-url="/Hospital/GetFiltered"
        ></WDataTable>
        <WButton
            v-if="isSysAdmin || isAdmin"
            :label="$t('buttons.create')"
            icon="pi pi-plus"
            @click="create"
            class="mb-3"
        ></WButton>
        <WLoader :is-loading="loading"></WLoader>
    </div>
</template>

<script setup>
import WDataTable from '@/components/wrappers/data/data-table/WDataTable.vue';
import { ActionButtonModel } from '@/components/wrappers/data/data-table/models/actionButtonModel';
import { hospitalService } from '@/services/hospital/hospital.service';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';
import WLoader from '@/components/wrappers/loaderSpinner/WLoader.vue';
import WButton from '@/components/wrappers/button/WButton.vue';
import { useRouter } from 'vue-router';
import { DataTableColumnType } from '@/components/wrappers/data/data-table/models/dataTableColumnType';
import { userStore } from '@/store/user.store';
import { Roles } from '@/enums/roles';

const { t } = useI18n();
const toast = useToast();
const router = useRouter();

const loading = ref(false);

const columns = computed(() => [
    {
        field: 'name',
        header: t('hospital.name'),
        sortable: true,
        dataType: DataTableColumnType.text,
        filterable: true,
    },
    {
        field: 'rhiNumber',
        header: t('hospital.rhiNumber'),
        sortable: true,
        dataType: DataTableColumnType.text,
        filterable: true,
    },
    {
        field: 'cityName',
        header: t('address.city'),
        dataType: DataTableColumnType.text,
        filterable: true,
    },
    { field: 'contactPerson', header: t('hospital.contactPerson') },
]);

const dataTable = ref(null);

const isSysAdmin = computed(() => {
    return userStore().isUserInRole(Roles.SysAdmin);
});

const isAdmin = computed(() => {
    return userStore().isUserInRole(Roles.Admin);
});

const editHospital = (hospital) => {
    router.push(`/hospital/edit/${hospital.id}`);
};

const deleteHospital = async (item) => {
    loading.value = true;
    try {
        await hospitalService.delete(item.id);
        toast.add({
            severity: 'success',
            summary: t('notifications.success'),
            detail: t('hospital.successfulDeletedHospital'),
            life: 2000,
        });
    } catch (err) {
        toast.add({
            severity: 'error',
            summary: t('notifications.error'),
            detail: t('notifications.somethingGoneWrong'),
            life: 2000,
        });
    } finally {
        loading.value = false;
        dataTable.value.refresh();
    }
};

const displayHospital = (hospital) => {
    router.push(`/hospital/display/${hospital.id}`);
};

const actionButtons = computed(() => [
    new ActionButtonModel('pi pi-eye', t('buttons.display'), displayHospital),
    new ActionButtonModel('pi pi-pencil', t('buttons.edit'), editHospital),
    new ActionButtonModel(
        'pi pi-trash',
        t('buttons.delete'),
        deleteHospital,
        'danger',
        isAdmin.value || isSysAdmin.value
    ),
]);

const create = () => {
    router.push({ path: '/hospital/create' });
};
</script>
