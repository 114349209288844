<template>
    <div v-if="dto">
        <TAVIPatientInfo :show="true" :model="dto" />
    </div>
    <WLoader :isLoading="loading" />
</template>

<script setup>
import { ref, onMounted } from 'vue';
import TAVIPatientInfo from '@/components/tavi/TaviPatientInfo.vue';
import WLoader from '@/components/wrappers/loaderSpinner/WLoader.vue';
import { PatientViewDto } from '@/dto/patient/patientViewDto';
import { patientService } from '@/services/patient/patient.service';
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

let dto = ref(new PatientViewDto());
const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const loading = ref(false);

const getPatienData = async (patientId) => {
    try {
        loading.value = true;
        dto.value = (await patientService.getById(patientId)).data;
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: t('notifications.error'),
            detail: t('notifications.somethingGoneWrong'),
            life: 2000,
        });
    } finally {
        loading.value = false;
    }
};

const goBack = () => {
    router.push({ path: '/patient/list' });
};

onMounted(async () => {
    const patientId = route.params.id;
    if (patientId) {
        getPatienData(patientId);
    }
});
</script>
