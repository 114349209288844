export class ProceduralDiagnosticCoronaryAngDto {
    constructor(obj) {
        this.id = obj.id;
        this.hasCabg = obj.hasCabg;
        this.lvotPerimeter = obj.lvotPerimeter;
        this.sinusesValsalvaMeanDiameter = obj.sinusesValsalvaMeanDiameter;
        this.sinotubularJunctionMeanDiameter = obj.sinotubularJunctionMeanDiameter;
        this.sinotubularJunctionHeightFromAnnulus = obj.sinotubularJunctionHeightFromAnnulus;
        this.rcaOstiumHeigh = obj.rcaOstiumHeigh;
        this.lmsOstiumHeigh = obj.lmsOstiumHeigh;
        this.coronaryArteryStenoses = obj.coronaryArteryStenoses;
    }
}
