import { httpService } from "@/services/http.service";

export const dataTaleService = {
    async getFiltered(apiUrl, filter) {
        return httpService.post(apiUrl, filter
            /*, {
            params: filter
        }*/
        );
    }
}
