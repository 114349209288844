export const bg = {
    "accept": "Да",
        "reject": "Не",
        "choose": "Избор",
        "upload": "Качване",
        "cancel": "Отказ",
        "dayNames": ["Неделя", "Понеделник", "Вторник", "Сряда", "Четвъртък", "Петък", "Събота"],
        "dayNamesShort": ["Нед", "Пон", "Вто", "Сря", "Чет", "Пет", "Съб"],
        "dayNamesMin": ["Нд", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
        "monthNames": [
        "Януари",
        "Февруари",
        "Март",
        "Април",
        "Май",
        "Юни",
        "Юли",
        "Август",
        "Септември",
        "Октомври",
        "Ноември",
        "Декември"
    ],
        "monthNamesShort": [
        "Яну",
        "Фев",
        "Мар",
        "Апр",
        "Май",
        "Юни",
        "Юли",
        "Авг",
        "Сеп",
        "Окт",
        "Ное",
        "Дек"
    ],
        "today": "Днес",
        "clear": "Изчистване",
        "weekHeader": "Сд",
        "firstDayOfWeek": 1,
        "dateFormat": "dd/mm/yyyy",
        "weak": "Слаба",
        "medium": "Средна",
        "strong": "Добра",
        "passwordPrompt": "Въведете парола",
        "startsWith": "Започва с",
        "contains": "Съдържа",
        "notContains": "Не съдържа",
        "endsWith": "Завършва на",
        "equals": "Равно е на",
        "notEquals": "Не е равно на",
        "noFilter": "Без филтър",
        "lt": "По-малко от",
        "lte": "По-малко или равно на",
        "gt": "По-голямо от",
        "gte": "По-голямо или равно на",
        "dateIs": "Датата е",
        "dateIsNot": "Дататата не е",
        "dateBefore": "Датата е преди",
        "dateAfter": "Датата е след",
        "custom": "Персонализиран",
        "apply": "Приложи",
        "matchAll": "Съвпадение на всички",
        "matchAny": "Съвпадение на някое ",
        "addRule": "Добавяне на условие",
        "removeRule": "Премахване на условие",
        "emptyFilterMessage": "Няма налична информация",
        "emptyMessage": "Не са открити резултати"
}

export const en = {
    "startsWith": "Starts with",
    "contains": "Contains",
    "notContains": "Not contains",
    "endsWith": "Ends with",
    "equals": "Equals",
    "notEquals": "Not equals",
    "noFilter": "No Filter",
    "filter": "Filter",  // only available for PrimeReact
    "lt": "Less than",
    "lte": "Less than or equal to",
    "gt": "Greater than",
    "gte": "Greater than or equal to",
    "dateIs": "Date is",
    "dateIsNot": "Date is not",
    "dateBefore": "Date is before",
    "dateAfter": "Date is after",
    "custom": "Custom",  // only available for PrimeReact
    "clear": "Clear",
    "apply": "Apply",
    "matchAll": "Match All",
    "matchAny": "Match Any",
    "addRule": "Add Rule",
    "removeRule": "Remove Rule",
    "accept": "Yes",
    "reject": "No",
    "choose": "Choose",
    "upload": "Upload",
    "cancel": "Cancel",
    "completed": "Completed",
    "pending": "Pending",
    "dayNames": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    "dayNamesShort": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    "dayNamesMin": ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
    "monthNames": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    "monthNamesShort": ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    "chooseYear": "Choose Year",
    "chooseMonth": "Choose Month",
    "chooseDate": "Choose Date",
    "prevDecade": "Previous Decade",
    "nextDecade": "Next Decade",
    "prevYear": "Previous Year",
    "nextYear": "Next Year",
    "prevMonth": "Previous Month",
    "nextMonth": "Next Month",
    "prevHour": "Previous Hour",
    "nextHour": "Next Hour",
    "prevMinute": "Previous Minute",
    "nextMinute": "Next Minute",
    "prevSecond": "Previous Second",
    "nextSecond": "Next Second",
    "am": "am",
    "pm": "pm",
    "today": "Today",
    "weekHeader": "Wk",
    "firstDayOfWeek": 0,
    "dateFormat": "mm/dd/yy",
    "weak": "Weak",
    "medium": "Medium",
    "strong": "Strong",
    "passwordPrompt": "Enter a password",
    "emptyFilterMessage": "No results found", // @deprecated Use "emptySearchMessage" option instead.
    "searchMessage": "{0} results are available",
    "selectionMessage": "{0} items selected",
    "emptySelectionMessage": "No selected item",
    "emptySearchMessage": "No results found",
    "emptyMessage": "No available options",
    "aria": {
        "trueLabel": "True",
        "falseLabel": "False",
        "nullLabel": "Not Selected",
        "star": "1 star",
        "stars": "{star} stars",
        "selectAll": "All items selected",
        "unselectAll": "All items unselected",
        "close": "Close",
        "previous": "Previous",
        "next": "Next",
        "navigation": "Navigation",
        "scrollTop": "Scroll Top",
        "moveTop": "Move Top",
        "moveUp": "Move Up",
        "moveDown": "Move Down",
        "moveBottom": "Move Bottom",
        "moveToTarget": "Move to Target",
        "moveToSource": "Move to Source",
        "moveAllToTarget": "Move All to Target",
        "moveAllToSource": "Move All to Source",
        "pageLabel": "{page}",
        "firstPageLabel": "First Page",
        "lastPageLabel": "Last Page",
        "nextPageLabel": "Next Page",
        "previousPageLabel": "Previous Page",
        "rowsPerPageLabel": "Rows per page",
        "jumpToPageDropdownLabel": "Jump to Page Dropdown",
        "jumpToPageInputLabel": "Jump to Page Input",
        "selectRow": "Row Selected",
        "unselectRow": "Row Unselected",
        "expandRow": "Row Expanded",
        "collapseRow": "Row Collapsed",
        "showFilterMenu": "Show Filter Menu",
        "hideFilterMenu": "Hide Filter Menu",
        "filterOperator": "Filter Operator",
        "filterConstraint": "Filter Constraint",
        "editRow": "Row Edit",
        "saveEdit": "Save Edit",
        "cancelEdit": "Cancel Edit",
        "listView": "List View",
        "gridView": "Grid View",
        "slide": "Slide",
        "slideNumber": "{slideNumber}",
        "zoomImage": "Zoom Image",
        "zoomIn": "Zoom In",
        "zoomOut": "Zoom Out",
        "rotateRight": "Rotate Right",
        "rotateLeft": "Rotate Left"
    }
}
