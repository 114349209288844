export default {
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
  "userName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
  "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password"])},
  "confirmNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm new password"])},
  "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
  "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reports"])},
  "selectedItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["items selected"])},
  "buttons": {
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "createDraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create draft"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review"])},
    "addFromAPI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add from API"])}
  },
  "validations": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required field"])},
    "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The field must be at least ", _interpolate(_named("min")), " characters"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid email"])},
    "minValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "maxValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "alpha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "alphaNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "numeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "integer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "decimal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "sameAs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid url address"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "exactLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Must be ", _interpolate(_named("value")), " symbols"])},
    "uniquePersonIdentifier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid PersonalCivilNumber or PersonalNumberOfForeigner"])},
    "uniquePersonIdentifierExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The entered ID already exists in the system."])},
    "sameAsPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The passwords does not match"])}
  },
  "address": {
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area"])},
    "municipality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Municipality"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])}
  },
  "hospital": {
    "hospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hospital"])},
    "hospitals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hospitals"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "rhiNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RHI number"])},
    "successfulDeletedHospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful deleted hospital"])},
    "contactPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact person"])},
    "contactPersonFirstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact person (first name)"])},
    "contactPersonLastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact person (last name)"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users associated with the hospital"])}
  },
  "patient": {
    "dateOfBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of birth"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "identifier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifier"])},
    "identifierType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifier type"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "middleName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Middle name"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "patients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patients"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
    "phoneContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "historyOfDeseaseNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History of desease number"])},
    "successfulDeletedPatient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful deleted patient"])},
    "patientProcedureList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of patient procedures"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "existingPatient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Existing patient"])},
    "thereIsExistingPatientWithThisIdentification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is already saved patient with this identification"])},
    "notFoundPatients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not fount patients"])},
    "errorGettingDataFromApi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error getting data from API"])},
    "addPatientFromApi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add patient from API"])}
  },
  "person": {
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "middleName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Middle name"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])}
  },
  "personIdentity": {
    "identifierType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "identifier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])}
  },
  "roles": {
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles"])},
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])},
    "hospitalAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hospital administrator"])},
    "doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor"])}
  },
  "taviProcedure": {
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "procedureStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procedure status"])},
    "generalAnaesthesia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General anaesthesia"])},
    "vascularAccessTechnique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vascular access technique"])},
    "vascularAccessSite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vascular access site"])},
    "otherArterialAccessSites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other arterial access sites"])},
    "proceduresDuringTAVI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procedures during TAVI"])},
    "prostheticValveFractureAttempted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosthetic valve fracture attempted "])},
    "aorticBalloonValvuloplastyBeforeValveDeployment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aortic balloon valvuloplasty before valve deployment"])},
    "postImplantationBalloonDilatationOfImplantedValve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post implantation balloon dilatation of implanted valve"])},
    "balloonSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balloon size (mm)"])},
    "rapidPacing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapid pacing"])},
    "vascularClosureTechnique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vascular closure technique"])},
    "pre-implantationPeak-to-peakInvasiveAVGradient(mmHg)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-implantation peak-to-peak invasive AV gradient (mmHg)"])},
    "post-implantationPeak-to-peakInvasiveAVGradient(mmHg)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post-implantation peak-to-peak invasive AV gradient (mmHg)"])},
    "createNewProcedure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new procedure"])},
    "currentProcedureList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current procedure list"])},
    "procedureList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procedure list"])},
    "procedures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procedures"])},
    "successfulDeletedProcedure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful deleted procedure"])},
    "successfulCreatedProcedure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful created procedure"])},
    "createDraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create draft"])},
    "tavi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TAVI"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully save the changes"])},
    "postImplantationPVL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post-implantation PVL (degree)"])},
    "anaesthesiaType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anaesthesia, type"])},
    "proceduralCirculatorySupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procedural circulatory support"])},
    "proceduralCirculatorySupportType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procedural circulatory support, type"])},
    "proceduralEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procedural events"])},
    "proceduralEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procedural events"])},
    "conversionToSternotomyDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversion to sternotomy, date,"])},
    "conversionToSternotomyCardiopulmonaryBypass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversion to sternotomy, cardiopulmonary bypass"])},
    "conversionToSternotomyReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversion to sternotomy, reason"])},
    "vascularComplications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vascular complications"])},
    "addDevices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new devices"])},
    "devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devices"])},
    "device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device"])},
    "deviceModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model"])},
    "diameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diameter (mm)"])},
    "successfullyImplanted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully implanted"])},
    "unsuccessfulImplantationReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsuccessful implantation, reason"])},
    "valveSerialNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valve serial number"])},
    "procedureComplication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procedure complication"])},
    "proceduralDiagnostics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procedural diagnostics"])},
    "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])}
  },
  "selectButton": {
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown"])}
  },
  "patientSearch": {
    "personIdentityNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person identity number"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "middleName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Middle name"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "patientInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient info"])}
  },
  "dataTable": {
    "noItemsFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No items found"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
  },
  "registeredUsers": {
    "waitingUsersToApprove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registered users waiting approve"])},
    "allRegisteredUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All registered users"])},
    "successfulConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful confirmed user"])},
    "successfulRejection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful rejected user"])},
    "rolesEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit roles"])},
    "successfulRolesEditing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful role editing"])},
    "confirmationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The \"ApprovedUser\" role has been removed. Are you sure you want to proceed?"])},
    "allRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All roles"])},
    "currentRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current roles"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of registration"])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected"])}
  },
  "notifications": {
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "somethingGoneWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something gone wrong"])},
    "registrationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration error"])},
    "loginError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error login"])},
    "successfulSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful save"])},
    "successfulEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful edit"])},
    "errorCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not successful save"])},
    "validationErrors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validation errors"])},
    "validationErrorsDetail": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["There are ", _interpolate(_named("count")), " validation errors"])},
    "errorEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not successful edit"])},
    "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation"])}
  },
  "patientCharacteristics": {
    "generalPatientClinicalData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General clinical data"])},
    "patientCharacteristics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient characteristics"])},
    "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height (cm)"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight (kg)"])},
    "frailty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frailty"])},
    "diabetesMellitus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diabetes mellitus"])},
    "hypertension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hypertension"])},
    "chronicObstructivePulmonaryDisease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chronic obstructive pulmonary disease"])},
    "activeMalignancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active malignancy"])},
    "currentRenalDialysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current renal dialysis"])},
    "atrialFibrillationFlutter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atrial fibrillation/flutter"])},
    "peripheralArterialDisease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peripheral arterial disease"])},
    "porcelainAorta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porcelain aorta"])},
    "priorStroke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prior stroke"])},
    "coronaryHeartDisease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coronary heart disease"])},
    "priorMyocardialInfarction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prior myocardial infarction"])}
  },
  "waitingForApprove": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for approve"])},
    "detailsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have to wait administrator to approve your account."])}
  },
  "dischargeData": {
    "dischargeDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discharge details"])},
    "dischargedateDeathDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discharge date / Death date"])},
    "oralAnticoagulants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oral anticoagulants"])},
    "aspirin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aspirin"])},
    "myocardialInfarction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myocardial infarction"])},
    "p2Y12Inhibitors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P2Y12 inhibitors"])},
    "laboratory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laboratory"])},
    "creatinineAtDischarge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creatinine at discharge (µmol/L)"])},
    "haemoglobinAtDischarge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haemoglobin at discharge (g/L)"])},
    "atrialRhythm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atrial rhythm"])},
    "ventricularConduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventricular conduction"])},
    "atrioventricularBlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atrioventricular block"])},
    "ecg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECG"])},
    "preDischargeEchocardiogram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-discharge echocardiogram"])},
    "postDeploymentAorticValveMeanGradient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post deployment aortic valve MEAN GRADIENT"])},
    "postDeploymentMitralRegurgitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post deployment mitral regurgitation"])},
    "dischargeData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discharge details"])},
    "pvl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PVL"])}
  },
  "procedureComplication": {
    "cardiacInterventions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cardiac interventions (during same admission)"])},
    "redoTaviReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redo TAVI reason"])},
    "otherHeartValveSurgery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other heart valve surgery (during same admission), valve"])},
    "myocardialInfarction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myocardial infarction"])},
    "brainStroke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stroke"])},
    "inHospitalCardiogenicShock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In-hospital cardiogenic shock"])},
    "ifCardiogenicShockStage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If cardiogenic shock, stage"])},
    "cardiacArrest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cardiac arrest"])},
    "vARC3MajorBleeding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VARC 3 major bleeding"])},
    "newRequirementForRenalReplacementTherapy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New requirement for renal replacement therapy"])},
    "implantableCardiacDevice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Implantable cardiac device"])},
    "inHospitalDeath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In-hospital death"])},
    "deathReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Death reason"])}
  },
  "passwordLabels": {
    "promptLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter password"])},
    "weakLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weak"])},
    "mediumLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medium"])},
    "strongLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strong"])}
  },
  "menu": {
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "hospitals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hospitals"])},
    "patients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patients"])},
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
    "administration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration"])},
    "registeredUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registered users"])},
    "userAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User access"])},
    "procedures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procedures"])},
    "createNewProcedure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new procedure"])},
    "procedureDraftList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procedures draft list"])},
    "procedureList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procedures list"])},
    "nomenclaturesManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomenclatures management"])},
    "usersWaitingApprove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for approve"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])}
  },
  "followUp": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracking"])},
    "generalData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General data"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "nyhaClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NYHA class"])},
    "ccsAngina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CCS angina"])},
    "echocardiography": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Echocardiography"])},
    "leftVentricularEjectionFraction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Left ventricular ejection fraction"])},
    "avMeanGradient(mmHg)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AV mean gradient (mmHg)"])},
    "avPeakGradient(mmHg)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AV peak gradient (mmHg)"])},
    "aorticRegurgitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aortic regurgitation"])},
    "mitralRegurgitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitral regurgitation"])},
    "tricuspidRegurgitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tricuspid regurgitation"])},
    "newValveRelatedPathological": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New valve related pathological"])},
    "interventions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interventions"])},
    "subsequentInterventions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow up intervention"])},
    "subsequentInterventionsPrimaryIndication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow up reintervention, primary indication"])},
    "addFollowUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add tracking"])}
  },
  "proceduralDiagnostics": {
    "aVMeanGradient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AV mean gradient (mmHg)"])},
    "aVPeakVelocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AV peak velocity (m/s)"])},
    "aVArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AV area (cm2)"])},
    "aorticValveMorphology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aortic valve morphology"])},
    "bicuspidAorticValveType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bicuspid aortic valve, type"])},
    "bicuspidAorticValveCuspFusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bicuspid aortic valve, cusp fusion"])},
    "aorticAnnulusArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aortic annulus, area (cm2)"])},
    "aorticAnnulusPerimeter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aortic annulus, perimeter (mm)"])},
    "aorticAnnulusCalcification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aortic annulus calcification"])},
    "aorticLeafletsCuspsCalcification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aortic leaflets/cusps calcification"])},
    "lVOTCalcification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LVOT calcification"])},
    "rapheCalcification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raphe calcification"])},
    "aorticValveDiseaseAetiology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aortic valve disease aetiology"])},
    "mitralRegurgitationType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitral regurgitation, type"])},
    "mitralStenosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitral stenosis"])},
    "tricuspidRegurgitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tricuspid regurgitation"])},
    "pASystolicPressure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PA systolic pressure (mmHg)"])},
    "laboratory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laboratory"])},
    "haemoglobin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haemoglobin (g/L)"])},
    "bNP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BNP (ng/L)"])},
    "nTProBNP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NT-pro BNP (ng/L)"])},
    "creatinine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creatinine (µmol/L)"])},
    "coronaryAngiographyOrCTCoronaryAngiography": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coronary angiography or CT coronary angiography"])},
    "sinotubularJunctionMeanDiameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinotubular junction mean diameter (mm)"])},
    "coronaryArteryStenosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coronary artery stenosis"])},
    "lVOTDiameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LVOT diameter (mm)"])},
    "sinusesOfValsalvaMeanDiameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinuses of valsalva mean diameter (mm)"])},
    "sinotubularJunctionHeightFromAnnulus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinotubular junction height from annulus (mm)"])},
    "rcaOstiumHeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RCA ostium, height (mm)"])},
    "lmsOstiumHeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LMS ostium, height (mm)"])},
    "aorticAnnulusDiameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aortic annulus, diameter(mm)"])}
  },
  "council": {
    "councilTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Council"])},
    "symptoms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symptoms"])},
    "nyha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NYHA"])},
    "preProceduralCirculatorySupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-procedural circulatory support"])},
    "activeEndocarditis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active endocarditis"])},
    "conclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conclusion"])},
    "sTSRiskScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STS risk score"])},
    "euroScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EuroScore"])},
    "euroScoreII": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EuroScoreII"])}
  },
  "previousProcedures": {
    "previousProceduresTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous procedures"])},
    "cardiacInterventions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cardiac interventions"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "surgicalAorticValveReplacement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surgical aortic valve replacement, valve type"])},
    "valve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valve"])},
    "TAVI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TAVI"])},
    "TAVIDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "otherTranscatheterValve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other transcatheter valve intervention"])},
    "priorImplantableCardiacDevice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prior implantable cardiac device"])}
  },
  "nomenclature": {
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
    "nameBg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgarian name"])},
    "nameEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English name"])},
    "validFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid from"])},
    "validTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid to"])}
  },
  "users": {
    "editRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit roles"])},
    "newRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New registration"])},
    "createUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create user"])}
  }
}