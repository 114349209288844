<template>
    <div>
        <div class="grid formgrid">
            <div class="col-12 md:col-4 field">
                <WSelectButton
                    :items="selectButtonItems"
                    v-model="model.cardiacInterventionsId"
                    :label="t('previousProcedures.cardiacInterventions')"
                    :disabled="disabled"
                />
            </div>
            <div
                class="col-12 md:col-4 field"
                v-if="model.cardiacInterventionsId === SelectButtonValues.Yes"
            >
                <WMultiSelect
                    :items="cardiacInterventions"
                    v-model="model.cardiacInterventions"
                    :is-required="true"
                    optionLabel="nameBg"
                    :label="t('previousProcedures.cardiacInterventions')"
                    :disabled="disabled"
                />
            </div>
            <div
                class="col-12 md:col-4 field"
                v-if="containsSurgicalAorticValveReplacement || containsPci"
            >
                <WDatePicker
                    v-model="model.dateForSurgicalReplacement"
                    :label="t('previousProcedures.date')"
                    :disabled="disabled"
                />
            </div>
        </div>
        <div class="grid">
            <div class="col-12 md:col-6 field" v-if="containsSurgicalAorticValveReplacement">
                <WDropdown
                    :items="valveProsthesisTypes"
                    v-model="model.valveProsthesisTypeId"
                    optionLabel="nameBg"
                    :label="t('previousProcedures.surgicalAorticValveReplacement')"
                    :disabled="disabled"
                ></WDropdown>
            </div>
            <div class="col-12 md:col-6 field" v-if="containsOtherHeartValveSurgery">
                <WMultiSelect
                    :items="heartValves"
                    v-model="model.valves"
                    optionLabel="nameBg"
                    :label="t('previousProcedures.valve')"
                    :disabled="disabled"
                />
            </div>
        </div>
        <div class="grid">
            <div class="col-12 md:col-6 field">
                <WSelectButton
                    :items="selectButtonItems"
                    v-model="model.hasTaviId"
                    :label="t('previousProcedures.TAVI')"
                    :disabled="disabled"
                />
            </div>
            <div class="col-12 md:col-6 field" v-if="model.hasTaviId === SelectButtonValues.Yes">
                <WDatePicker
                    v-model="model.date"
                    :label="t('previousProcedures.date')"
                    :is-required="true"
                    :disabled="disabled"
                />
            </div>
        </div>
        <div class="grid">
            <div class="col-12 md:col-6 field">
                <WSelectButton
                    :items="selectButtonItems"
                    v-model="model.otherTranscatheterValveInterventionId"
                    :label="t('previousProcedures.otherTranscatheterValve')"
                    :disabled="disabled"
                />
            </div>
            <div
                class="col-12 md:col-6 field"
                v-if="model.otherTranscatheterValveInterventionId === SelectButtonValues.Yes"
            >
                <WMultiSelect
                    :items="heartValves"
                    v-model="model.otherTranscatheterValves"
                    :is-required="true"
                    optionLabel="nameBg"
                    :label="t('previousProcedures.otherTranscatheterValve')"
                    :disabled="disabled"
                />
            </div>
        </div>
        <div class="grid">
            <div class="col-12 md:col-6 field">
                <WSelectButton
                    :items="selectButtonItems"
                    v-model="model.priorImplantableCardiacDeviceId"
                    :label="t('previousProcedures.priorImplantableCardiacDevice')"
                    :disabled="disabled"
                />
            </div>
            <div
                class="col-12 md:col-6 field"
                v-if="model.priorImplantableCardiacDeviceId === SelectButtonValues.Yes"
            >
                <WMultiSelect
                    :items="priorImplantableCardiacDevice"
                    v-model="model.implantableCardiacDevices"
                    :is-required="true"
                    optionLabel="nameBg"
                    :label="t('previousProcedures.priorImplantableCardiacDevice')"
                    :disabled="disabled"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import WSelectButton from '@/components/wrappers/button/WSelectButton.vue';
import WMultiSelect from '@/components/wrappers/form/WMultiSelect.vue';
import WDatePicker from '@/components/wrappers/dateTime/WDateTimePicker.vue';
import WDropdown from '@/components/wrappers/form/WDropdown.vue';
import { dropdownService } from '@/services/dropdown/dropdown.service';
import { SelectButtonValues } from '@/enums/selectButtonValues';
import { ref, onMounted, watch, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { PreviousProcedureDto } from '@/dto/previousProcedure/previousProcedureDto';
import { CardiacInterventions } from '@/enums/cardiacInterventions';
import { useVuelidate } from '@vuelidate/core';

/* const emit = defineEmits(['update:modelValue']); */

const props = defineProps({
    modelValue: PreviousProcedureDto,
    disabled: {
        type: Boolean,
        default: false,
    },
    selectButtonItems: Array,
    cardiacInterventions: Array,
    heartValves: Array,
});

const { t } = useI18n();
const v$ = useVuelidate();
const model = ref(props.modelValue);
const valveProsthesisTypes = ref([]);
const priorImplantableCardiacDevice = ref([]);

const getImplantableCardiacDeviceForPriviousProcedures = async () => {
    priorImplantableCardiacDevice.value = (
        await dropdownService.getImplantableCardiacDeviceForPriviousProcedures()
    ).data;
};

const getValveProsthesisTypes = async () => {
    valveProsthesisTypes.value = (await dropdownService.getValveProsthesisTypes()).data;
};

const containsSurgicalAorticValveReplacement = computed({
    get() {
        return props.modelValue.cardiacInterventions?.some(
            (x) => x === CardiacInterventions.SurgicalAorticValveReplacement
        );
    },
    set(val) {
        return val;
    },
});

const containsPci = computed({
    get() {
        return props.modelValue.cardiacInterventions?.some((x) => x === CardiacInterventions.Pci);
    },
    set(val) {
        return val;
    },
});

const containsOtherHeartValveSurgery = computed({
    get() {
        return props.modelValue.cardiacInterventions?.some(
            (x) => x === CardiacInterventions.OtherHeartValveSurgery
        );
    },
    set(val) {
        return val;
    },
});

/* watch(
    () => model.value,
    (val) => {
        emit('update:modelValue', val);
    }
);

watch(
    () => props.modelValue,
    (val) => {
        model.value = val;
    }
); */

watch(
    () => model.value.hasTaviId,
    (val) => {
        if (val !== SelectButtonValues.Yes) model.value.date = null;
    }
);

watch(
    () => model.value.otherTranscatheterValveInterventionId,
    (val) => {
        if (val !== SelectButtonValues.Yes) model.value.otherTranscatheterValves = null;
    }
);

watch(
    () => model.value.priorImplantableCardiacDeviceId,
    (val) => {
        if (val !== SelectButtonValues.Yes) model.value.implantableCardiacDevices = null;
    }
);

watch(
    [() => model.value.cardiacInterventionsId, () => model.value.cardiacInterventions],
    ([valInterventionsId]) => {
        if (valInterventionsId === SelectButtonValues.Yes) {
            if (!containsSurgicalAorticValveReplacement.value) {
                model.value.valveProsthesisTypeId = null;
            }
            if (!containsOtherHeartValveSurgery.value) {
                model.value.valves = null;
            }
            if (!containsSurgicalAorticValveReplacement.value && !containsPci.value) {
                model.value.dateForSurgicalReplacement = null;
            }
        } else {
            model.value.cardiacInterventions = null;
            model.value.dateForSurgicalReplacement = null;
            model.value.valveProsthesisTypeId = null;
            containsSurgicalAorticValveReplacement.value = false;
            model.value.valves = null;
        }
    }
);

onMounted(async () => {
    getImplantableCardiacDeviceForPriviousProcedures();
    getValveProsthesisTypes();
});

defineExpose({ v$ });
</script>
