import { AddressDto } from "@/components/wrappers/address/dto/addressDto";
import { ContactPersonDto } from "@/dto/person/contactPersonDto";

export class HospitalDto {
    constructor(obj) {
        this.id = obj.id || '';
        this.name = obj.name || '';
        this.rhiNumber = obj.rhiNumber || '';
        this.address = new AddressDto(obj.address || {});
        this.contactPerson = new ContactPersonDto(obj.contactPerson || {});
    }
}
