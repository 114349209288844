<template>
    <label
        class="block font-medium mb-2"
        :class="{ 'p-error': v$.selectedValue.$invalid && v$.$dirty, required: isRequired }"
    >
        {{ label }}
    </label>
    <div class="p-inputgroup">
        <InputText
            v-if="!checkboxValue"
            :disabled="disabled"
            v-model.number="selectedValue"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 46"
            inputmode="numeric"
        />
        <span
            v-if="hasModelValue && showCheckBox"
            class="p-inputgroup-addon"
            @click="toggleCheckbox"
        >
            <WCheckbox
                v-model="checkboxValue"
                :disabled="disabled"
                :label="` ${$t('selectButton.unknown')}`"
            />
        </span>
    </div>
    <small v-if="v$.selectedValue.$errors.length > 0" class="p-error">
        {{ v$.selectedValue.$errors[0].$message }}
    </small>
</template>

<script setup>
import InputText from 'primevue/inputtext';
import { computed, onMounted, ref, watch } from 'vue';
import { required } from '@/validation/validators';
import WCheckbox from '@/components/wrappers/button/WCheckboxButton.vue';
import { useVuelidate } from '@vuelidate/core';

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
    label: String,
    modelValue: Number,
    isRequired: {
        type: Boolean,
        default: false,
    },
    showCheckBox: {
        type: Boolean,
        default: true,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});

const model = ref(props.modelValue);
const checkboxValue = ref(false);
const selectedValue = ref(props.modelValue);

const rules = {
    selectedValue: {},
};

if (props.isRequired) {
    Object.assign(rules.selectedValue, { required });
}

const v$ = useVuelidate(rules, { selectedValue });

const checkBoxSelected = () => {
    props.modelValue === -999 ? (checkboxValue.value = true) : (checkboxValue.value = false);
};

const hasModelValue = computed(() => !model.value || model.value === -999);

const toggleCheckbox = () => (!props.disabled ? (checkboxValue.value = !checkboxValue.value) : null);

watch(
    () => selectedValue.value,
    (val) => {
        emit('update:modelValue', val);
        model.value = val;
    }
);

watch(
    () => checkboxValue.value,
    (val) => {
        if (val === true) {
            selectedValue.value = -999;
        }
        if (val === false) {
            selectedValue.value = null;
        }
    }
);

watch(
    () => props.modelValue,
    (val) => {
        if (val === -999) {
            checkboxValue.value = true;
        } else if (val !== -999) {
            selectedValue.value = val;
        }
    }
);

onMounted(() => {
    checkBoxSelected();
});
</script>
