<template>
    <div class="my-2">
        <label
            :for="`inputText${_uid}`"
            class="font-medium mb-2"
            :class="{'p-error': v$.inputValue.$invalid && v$.$dirty, required: isRequired, 'p-error': invalidPersonalCivilNumber,}"
        >
            {{ label }}
        </label>
        <p-input-text
            v-model="inputValue"
            class="block text-900 font-medium w-full"
            :disabled="disabled"
            :class="v$.inputValue.$invalid && v$.$dirty ? `p-invalid ${cssClass}` : cssClass"
            :id="`inputText${_uid}`"
            :type="type"
            :inputmode="inputmode"
            @update:modelValue="$emit('update:modelValue', inputValue)"
            :onkeypress="onKeyPress"
        ></p-input-text>
        <small v-if="v$.inputValue.$errors.length > 0" class="p-error">
            {{ v$.inputValue.$errors[0].$message }}
        </small>
    </div>
</template>

<script setup>
import PInputText from 'primevue/inputtext';
import { getCurrentInstance, ref, watch } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { email, required, exactLength, minLength, sameAs } from '@/validation/validators';

const props = defineProps({
    modelValue: String,
    type: String,
    label: String,
    cssClass: String,
    inputmode: String,
    onKeyPress: Function,
    invalidPersonalCivilNumber: {
        type: Boolean,
        default: false,
    },
    isRequired: {
        type: Boolean,
        default: false,
    },
    isSameAs: {
        type: String,
        default: null,
    },
    exactLength: Number,
    disabled: {
        type: Boolean,
        default: false,
    },
    validations: {
        type: Array,
        default: null,
    },
    minLength: {
        type: Number,
    },
});

const inputValue = ref(props.modelValue);
const _uid = getCurrentInstance().uid;

const rules = {
    inputValue: {},
};
if (props.isRequired) {
    Object.assign(rules.inputValue, { required });
}
if (props.type === 'email') {
    Object.assign(rules.inputValue, { email });
}
if (props.exactLength) {
    Object.assign(rules.inputValue, {
        exactLength: exactLength(props.exactLength),
    });
}
if (props.minLength) {
    Object.assign(rules.inputValue, { minLength: minLength(props.minLength) });
}
/*if (props.isSameAs) {
    debugger
    Object.assign(rules.inputValue, {
        sameAs: sameAs(props.isSameAs),
    });
}*/

/*Object.assign(rules.inputValue, {
    sameAs: sameAs(props.isSameAs),
});*/

if (props.validations) {
    for (const validation of props.validations) {
        Object.assign(rules.inputValue, {
            [validation.name]: validation.validation,
        });
    }
}

const v$ = useVuelidate(rules, { inputValue });

watch(
    () => props.modelValue,
    (val) => {
        inputValue.value = val;
    }
);
</script>
