<template>
    <div>
        <Grid
            v-if="items.length"
            :columns="columns"
            :items="items"
            class="mt-5"
            :loading="isLoading"
            :actionButtons="actionButtons"
        />
        <w-loader :is-loading="loading"></w-loader>
    </div>
</template>
<script setup>
import { computed, ref, onMounted } from 'vue';
import Grid from '@/components/wrappers/data/data-table/WItemsDataTable.vue';
import WLoader from '@/components/wrappers/loaderSpinner/WLoader.vue';
import { DataTableColumnType } from '@/components/wrappers/data/data-table/models/dataTableColumnType';
import { useI18n } from 'vue-i18n';
import { taviService } from '@/services/tavi/tavi.service';
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';

const { t } = useI18n();
const items = ref([]);
const loading = ref(false);
const route = useRoute();
const router = useRouter();
const columns = computed(() => [
    {
        field: 'choosenDate',
        header: t('taviProcedure.date'),
        sortable: true,
        dataType: DataTableColumnType.date,
        filterable: true,
    },
    {
        field: 'patientFirstName',
        header: t('patient.name'),
        sortable: true,
        dataType: DataTableColumnType.text,
        filterable: true,
    },
    {
        field: 'patientLastName',
        header: t('patient.lastName'),
        sortable: true,
        dataType: DataTableColumnType.text,
        filterable: true,
    },
    {
        field: 'patientPersonIdentityNumber',
        header: t('patientSearch.personIdentityNumber'),
        sortable: true,
        dataType: DataTableColumnType.text,
        filterable: true,
    },
    {
        field: 'isDraft',
        header: t('taviProcedure.draft'),
        sortable: true,
        dataType: DataTableColumnType.boolean,
        filterable: true,
    },
]);
const getAllByPatientId = async (id) => {
    loading.value = true;
    try {
        items.value = (await taviService.getAllByPatientId(id)).data.items;
    } catch (err) {
        toast.add({
            severity: 'error',
            summary: t('notifications.error'),
            detail: t('notifications.somethingGoneWrong'),
            life: 2000,
        });
    } finally {
        loading.value = false;
    }
};
const actionButtons = computed(() => []);
onMounted(async () => {
    const patientId = route.params.id;
    if (patientId) {
        getAllByPatientId(patientId);
    }
});
</script>
